import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";

export default class UploadNewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            Batchs: [],
            selectedBatch: "",
            newUpload: {
                name: "",
                base64string: "",
                filename: "",
            },
            colList: [],
            totalData: 0,
            delimiter: ",",
            dataCsv: [],

            ColCategory: {},
            UniqueKey: "",
        }

    }

    /*

     */
    async componentDidMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
        console.log("XXXXXX-> ", user);
        await this.fetchBatchs();
    }

    fetchBatchs = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            }
        ];
        const dbName = "cclms";
        await this.fetchingAll(dbName, "Batchs", conds, (data) => {
            console.log("process taken fetchBatchs :> ", data, " > ", conds);
            _this.setState({
                Batchs: data
            });
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        //console.log(":)--> HandleInput > ",key," > ",e.target.value)
    }
    getValue = (key) => {
        if (typeof this.state.newUpload[key] === "undefined") {
            return "";
        }
        return this.state.newUpload[key];
    }
    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
            _this.convertBase64CsvIntoString();
            /*console.log("handleInputUpload ------>> ",myfile.name)
            console.log("handleInputUpload 2------>> ",reader.result)*/
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    convertBase64CsvIntoString = async () => {
        let data = this.state.newUpload.base64string;
        const arr =data.split(";base64,");
        data =arr[1];
        //data = data.replace("data:text/csv;base64,", "");

        let _this = this;
        let hub = {};
        hub.Base64String = data;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/csv/json";

        console.log("convertBase64CsvIntoString send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("convertBase64CsvIntoString response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const arrDataString =  dataIn.RESULT;
                let header = {};
                let arrHeader = [];
                let arrDataJson = [];
                let ColCategory = {};
                let tot = 0;
                if (arrDataString.length > 0) {
                    const row1 = arrDataString[0];
                    let x=0;
                    for (let i in row1) {
                        if(i !==""){
                            const item =i;
                            arrHeader.push(item);
                            header[i] = item;
                            ColCategory[item] = "";
                            x++;
                        }

                    }

                    for (let x in arrDataString) {
                        if (x > 0) {
                            const row = arrDataString[x];
                            arrDataJson.push(row);
                            tot++;
                        }
                    }

                    //todo let create data row
                }

                _this.setState({
                    colList: arrHeader,
                    totalData: tot,
                    dataCsv: arrDataJson,
                    ColCategory: ColCategory
                })

            }
        });




        /*//var s = atob(data);

        var arrDataString = s.split(/\r\n/);
        let header = {};
        let arrHeader = [];
        let arrDataJson = [];
        let ColCategory = {};
        let tot = 0;
        if (arrDataString.length > 0) {
            const row1 = arrDataString[0].split(this.state.delimiter);
            for (let i in row1) {
                const item = row1[i];
                arrHeader.push(item);
                header[i] = item;
                ColCategory[item] = "";
            }

            for (let x in arrDataString) {
                if (x > 0) {
                    const row1 = arrDataString[x].split(this.state.delimiter);
                    let row = {};
                    for (let i in row1) {
                        const item = row1[i];
                        let field = header[i];
                        row[field] = item;
                    }
                    arrDataJson.push(row);
                    tot++;
                }

            }

            //todo let create data row
        }

        this.setState({
            colList: arrHeader,
            totalData: tot,
            dataCsv: arrDataJson,
            ColCategory: ColCategory
        })
        //console.log("convertBase64CsvIntoString 2 ==> ",header);
        console.log("convertBase64CsvIntoString 3 ==> ", tot, arrDataJson);

         var decodedString = atob(data);
        console.log("decodedString fileUpload > "  ,decodedString); // Outputs: "Hello World!"*/

    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp);
            if (handleFunc !== null) {
                handleFunc();
            }

        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    submitUpload = async (e) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const user = GetUserToken();

        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.BatchName = this.state.selectedBatch; // "LEAD-CREDITCURE--2019-05-25"
        hub.Author = user.Username; //"biangacila@gmail.com"
        hub.AuthorName = user.Fullname;// "MErveilleux Biangacila"

        const arr =this.state.newUpload.base64string.split(";base64,");

        hub.Base64String =arr[1] ; // this.state.newUpload.base64string;
        hub.Delimiter = ",";
        hub.ColCategory = this.state.ColCategory;
        hub.ColUnique = this.state.UniqueKey;
        hub.FileName = this.state.newUpload.filename;// "lead-2019-05-25.csv"

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/upload/new";

        console.log("submitUpload send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUpload response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                alert("Thank you , upload submitted!");
                let tmp = _this.state;
                tmp.selectedBatch = "";
                tmp.newUpload = {
                    name: "",
                    base64string: "",
                    filename: "",
                };
                tmp.colList = [];
                tmp.totalData = 0;
                tmp.delimiter = ",";
                tmp.dataCsv = [];

                tmp.ColCategory = {};
                tmp.UniqueKey = "";
                _this.setState(tmp);

            }
        });

    }
    renderUniqueColomn = () => {
        const ls = [];
        for (let i in this.state.ColCategory) {
            const item = this.state.ColCategory[i];
            ls.push(i);
        }
        return (
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Unique Key</label>
                <div className="col-sm-10">
                    <select
                        className="form-control"
                        onChange={(e) => this.handleInput(e, "UniqueKey")}
                        value={this.state.UniqueKey}
                        required={true}
                    >
                        <option value={""}>--select--</option>
                        {ls.map((item) => {
                            return (
                                <option value={item}>{item.toUpperCase()}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        )
    }
    renderColomnCsv = () => {
        const ls = [];
        for (let i in this.state.ColCategory) {
            const item = this.state.ColCategory[i];
            ls.push(i);
        }

        console.log("renderColomnCsv --> ", this.state.ColCategory);

        const _this = this;
        const getColValue = (key) => {
            if (typeof _this.state.ColCategory[key] === "undefined") {
                return "";
            }
            return _this.state.ColCategory[key];
        }
        const setColValue = (e, col) => {
            let tmp = _this.state;
            tmp.ColCategory[col] = e.target.value;
            _this.setState(tmp);
        }

        return ls.map((y) => {
            return (
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">{y}</label>
                    <div className="col-sm-10">
                        <select
                            className="form-control"
                            onChange={(e) => setColValue(e, y)}
                            value={getColValue(y)}
                            required={true}
                        >
                            <option value={""}>--select--</option>
                            <option value={"contact"}>Contact</option>
                            <option value={"identity"}>Identity</option>
                            <option value={"name"}>Name</option>
                            <option value={"email"}>Email</option>
                            <option value={"other"}>Other</option>
                        </select>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Upload New Batch</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.submitUpload}>
                            {this.selectionBox("Batch", "Batchs", "name", "name", "selectedBatch", null, [], true)}
                            <div className="form-group">
                                <label>Choose your file</label>
                                <input
                                    id={"myFile"}
                                    type="file"
                                    className="form-control"
                                    required={true}
                                    onChange={(e) => this.handleInputFile(e, "myFile")}
                                />
                            </div>

                            <div className="alert alert-default">
                                <hr/>
                                <h3 style={{color: "red"}}>Setup column group</h3>
                                {this.renderColomnCsv()}
                            </div>
                            <div className="alert alert-default">
                                <hr/>
                                <h3 style={{color: "red"}}>Setup unique key column</h3>
                                {this.renderUniqueColomn()}
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn-primary form-control"
                                >Submit
                                </button>
                            </div>

                            <div className="container alert alert-info">
                                <hr/>
                                <h3 style={{color: "black"}}>Upload Info</h3>
                                <table className={"table"}>
                                    <tbody>
                                    <tr>
                                        <th>Batch Name:</th>
                                        <td>{this.state.selectedBatch}</td>
                                    </tr>
                                    <tr>
                                        <th>File Name:</th>
                                        <td>{this.state.newUpload.filename}</td>
                                    </tr>
                                    <tr>
                                        <th>Colonm setup:</th>
                                        <td>{JSON.stringify(this.state.ColCategory)}</td>
                                    </tr>
                                    <tr>
                                        <th>Unique Key:</th>
                                        <td>{this.state.UniqueKey}</td>
                                    </tr>
                                    <tr>
                                        <th>Total record:</th>
                                        <td>{this.state.totalData}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                        </form>
                    </div>


                </div>


            </PageContentWallpaper>


        );
    }
}