import React, {Fragment} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import GridContainer from "../common/dashboard/Grid/GridContainer";
import GridItem from "../common/dashboard/Grid/GridItem";
import CallEndIcon from '@material-ui/icons/CallEnd';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import dialPad from "./dialPad";
import Button from "@material-ui/core/Button";

//   https://material-ui.com/components/material-icons/

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: "100%",
        maxWidth: "100%",
        overflow: "scroll"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        minWidth: "100%",
        backgroundColor: "#ffebee",
        justifyContent: "center",
        borderRadius: 5
    },
}));

export default (props) => {
    let {extension, pbxStatus, callStatus, dial, stop, handleInput, sendDtmf} = props;
    let pbxExtension = props.pbx.Extension.username||"00000";
    let pbxOutCaller = props.pbx. OutCall.phone;
    let pbxRegistry = props.pbx.Registry;
    let pbxCalling =props.pbx.Calling;
    /*
    newState.Extension.username = data.username;
            newState.Extension.secret = data.secret;
     */
    if (typeof extension === "undefined") {
        extension = "00000"
    }
    if (typeof pbxStatus === "undefined") {
        pbxStatus = "not registered"
    }
    if (typeof callStatus === "undefined") {
        callStatus = "idle"
    }

    let registerStatus = pbxRegistry.status;
    let registryColor = "#f44336";

    if(registerStatus==="registered"){
        registryColor="green"
    }


    const theme = useTheme();
    const classes = useStyles();

    let digits = [1, 2, 3, 4, 5, 6, , 7, 8, 9 ,0, "#", "*",];

    return (
        <Card className={classes.card}>
            <CardContent style={{minWidth: "100%"}}>
                <GridContainer style={{marginTop: 5, minHeight: "100%", paddingTop: 15}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center" style={{minHeight: "100%",maxWidth:"100%"}}>
                        <Typography component="h4" variant="h4">
                            Out Call &nbsp;|&nbsp;
                            <small>Extention:</small>
                            <span style={{color: "#e91e63"}}>{pbxExtension}</span>
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Pbx: <span style={{color: registryColor }}>{registerStatus}</span>&nbsp;|&nbsp;
                            Call Status: <span style={{color: "#9c27b0"}}>{pbxCalling.status}</span>
                            {pbxOutCaller!==""?<Fragment>&nbsp;|&nbsp; <span style={{color: "green"}}>Caller: {pbxOutCaller}</span></Fragment>:null}

                        </Typography>



                        <TextField
                            id="outlined-basic"
                            label="Phone Number"
                            variant="outlined"
                            style={{minWidth: "90%"}}
                            name={"dialNumber"}
                            onChange={(e) => handleInput(e)}
                        />

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center" style={{
                        minHeight: "100%",
                        maxWidth:"100%",
                        paddingTop: 10,
                    }}>
                        Dtmf:
                        {digits.map((digit, index) => {
                            return (
                                <Button
                                    onClick={()=>sendDtmf({dtmfTone:digit})}
                                    variant="contained"
                                    style={{
                                    maxWidth: 20,
                                    maxHeight: 20,
                                    minWidth: 20,
                                    minHeight: 20,
                                    margin:3,
                                    fontWeight:"bold"
                                }}>{digit}</Button>
                            )
                        })
                        }
                    </GridItem>

                </GridContainer>


            </CardContent>
            <CardActions>

                <div className={classes.controls}>
                    <IconButton aria-label="previous" onClick={() => stop()}>
                        <CallEndIcon style={{color: "red", fontWeight: "bold", fontSize: 34}}/>
                    </IconButton>
                    <IconButton aria-label="play/pause">
                        <CloseIcon style={{color: "blue", fontWeight: "bold", fontSize: 34}}/>
                    </IconButton>
                    <IconButton aria-label="next" onClick={() => dial()}>
                        <DialerSipIcon style={{color: "green", fontWeight: "bold", fontSize: 34}}/>
                    </IconButton>
                </div>
            </CardActions>
        </Card>
    );
}

/*

0875759408

 */
