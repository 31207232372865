import React from "react";
import {makeStyles, TextField, withStyles} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);
const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor:"gray",
        borderRadius:3,
        borderWidth:1
    },
}));


export default ({row, styleIn,onChangeText,sectionName}) => {
    const classes = innerStyles();
    // const [ setAge] = React.useState('');
    const handleChange = event => {
        // setAge(event.target.value);
        onChangeText(row,event)
    };
    return (
        <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin}>
            <FormLabel component="legend" className={"legend"}>{sectionName}</FormLabel>
        <CssTextField
            className={classes.margin}
            label={row.label}
            variant="outlined"
            id="custom-css-outlined-input"
            name={row.name}
            required={row.required}
            onChange={handleChange}
            style={styleIn}
        />
        </FormControl>
    )
}
