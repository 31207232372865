import React, {Component} from "react";
import Wallpaper from "../common/ef-wallper";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import CallCard from "./CallCard";
//import CloudCall, {doCall, doHangup, answer,registerExtension} from "./JanusSipCall";
import AlertBox from "./AlertBox"
import {Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Connect from "../../reducers/connect/reducer-connector";
import {WrappedSocketManager} from "../websocket/SocketManager";


/**
 * Implement nicely with the following link
 * https://github.com/meetecho/janus-gateway
 *  Demo: https://janus.conf.meetecho.com
 *  Community :  https://groups.google.com/forum/#!forum/meetecho-janus
 *
 */
class ClickToCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host: "voip2.easipath.com",
            port: 5060,
            extension: "88889",
            secret: "BIA!88889",
            callStatus: "",
            dialNumber: "",
            sipStatus: "",
            isDialing: false,
            alertOpen: false,
            alertType: "",
            alertMessage: "",
            sipExtension:""/*"20007"*/,
            sipSecret:""/*"C2lS!20007"*/,
            sipServer:""/*"voip2.easipath.com"*/,
            sipHost:"",
            sipPort:"",
            sipAgent:"",

        }
        this.audioElement = null;
        this.SipProvider = null;

    }

    componentDidMount = async () => {

       // await this.registerCloudCalls();


    }

    registerCloudCalls=async ()=>{
        /**
         * Let now register to our pbx to make and receive call
         * */

        /*await CloudCall({
            host: this.state.host,
            extension: this.state.extension,
            secret: this.state.secret,
            AlertBox: this.AlertBox,
            port: this.state.port,
            displayname: "Agent " + this.state.extension
        });*/
    }


    AlertBox = (typeOfMessage, message) => {
        console.log("AlertBox :> ", typeOfMessage, message);
        this.setState({
            alertOpen: true,
            alertType: typeOfMessage ? "success" : "error",
            alertMessage: message,

        })
    }
    handleAlertClose = () => {
        this.setState({
            alertOpen: false,
            alertType: "",
            alertMessage: "",
        })
    }


    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    isValidNumber = (num) => {

        return true
    }
    /*dial = () => {
        if (this.state.isDialing) {
            return alert("Please wait while another call in progress!");
        }
        if (this.state.dialNumber === "") {
            return alert("Please provide number to call!");
        }
        if (!this.isValidNumber(this.state.dialNumber)) {
            return alert("Error your number is not valid! 10 digit required")
        }

        console.log("Congrats! you can now make call > ", this.state);

        let destination = this.state.dialNumber;
        destination = "0" + destination.substring(destination.length - 9);

        doCall({
            to: destination,
            host: this.state.host,
            AlertBox: this.AlertBox,
            isVideoCall: false
        })
    }
    stop = () => {
        doHangup();
    }
*/

    renderSipControllerCard = () => {

        return (
            <CallCard
                callStatus={this.state.callStatus}
                makeCall={this.dial}
                stopCall={this.stop}
                sipStatus={this.state.sipStatus}
            />
        )
    }

    setSipInfo=()=>{
        const username=this.state.sipExtension;
        const secret=this.state.sipSecret;
        const server=this.state.sipServer;
        if(username===""||secret===""||server===""){
            return alert("Please provide correct sip info, not blank")
        }
        this.props.PbxChangeExtension({
            username:username,
            secret:secret,
            server:server,
        })
    }
    requestCallForm=()=>{
        this.props.PbxOutCallShowForm({isSet:true})
    }


    render() {

        let registerStatus = this.props.pbx.Registry.status;
        let registerTime = this.props.pbx.Registry.time;
        let registryColor ="#f44336";
        if(registerStatus==="registered"){
            registryColor="green"
        }

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
            <div className={"container"}>
                {/*<CloudCall {...this.state} />*/}
                <h1>Set Sip Info</h1>


                <div className="col-xs-12 ">

                    <video
                        className="rounded centered"
                        id="video-device"
                        width={320}
                        height={240}
                        autoplay
                        playsinline
                        muted="muted"
                    />
                    <audio id={"audio-device"} />
                    <audio className="rounded centered" id="roomaudio" width="100%" height="100%" autoPlay/>


                </div>

                <div className="col-xs-12 ">
                    <p>Extension: {this.props.pbx.Extension.username}</p>
                    <p>Secret: {this.props.pbx.Extension.secret}</p>
                    <p>Host: {this.props.pbx.Server.host}</p>
                    <p>PBX Registry Status: <span style={{color:registryColor}}>{registerStatus}</span></p>
                    <p>PBX Registry Time: <span style={{color:registryColor}}>{registerTime}</span></p>
                </div>

                <div className="col-lg-12">
                    {/*<input
                        type="text"
                        className="form-control"
                        name={"dialNumber"}
                        onChange={(e) => this.handleInput(e)}
                    />
                    <br/><br/>*/}

                    <Paper style={{borderColor:"gray"}}>

                        <select
                            name={"sipServer"}
                            style={{minWidth:"90%"}}
                            onChange={(e) => this.handleInput(e)}
                            >
                            <option value={""}>---Select Server host---</option>
                            <option value={"voip2.easipath.com"}>Voip 2 Easipath</option>
                            <option value={"pmis-api.easipath.com"}>Voip 3  Pmis</option>
                        </select>
                        <br/><br/>

                        <TextField
                            id="outlined-basic"
                            label="Sip Extension"
                            variant="outlined"
                            style={{minWidth:"90%"}}
                            name={"sipExtension"}
                            onChange={(e) => this.handleInput(e)}
                            value={this.state.sipExtension}
                        />
                        <br/><br/>
                        <TextField
                            id="outlined-basic"
                            label="Sip Secret"
                            variant="outlined"
                            style={{minWidth:"90%"}}
                            name={"sipSecret"}
                            onChange={(e) => this.handleInput(e)}
                            value={this.state.sipSecret}
                        />
                        <br/><br/>

                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={()=>this.setSipInfo()}
                        >
                            Set Sip Info
                        </Button>
                        &nbsp;&nbsp;
                        {registerStatus === "registered" ?
                            <Button
                                onClick={() => this.requestCallForm()}
                                variant="contained"
                            >Request Call Form</Button>:null
                        }

                    </Paper>

                    <br/><br/>

                    <br/>
                    <br/>
                </div>

                <AlertBox
                    message={this.state.alertMessage}
                    typeMessage={this.state.alertType}
                    open={this.state.alertOpen}
                    handleClose={this.handleAlertClose}
                />

            </div>
            </Wallpaper>

        )
            ;
    }
}

export default Connect(ClickToCall);
/*

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConClickToCall extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ClickToCall {...this.props}/>

            </Wallpaper>
        );
    }
})
*/

