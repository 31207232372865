import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AccessAlarm, ThreeDRotation } from '@material-ui/icons';
import TableDataView from "./table";

const useStyles = makeStyles({
    card: {
        mixWidth: "100%",
    },
});

export default function QueueView({data,color,title}) {

    const classes = useStyles();
    let ls=[];
    let columns=[];
    if(data.length>0){
        const first  = data[0];
        for(let key in first){
            columns.push({
            id: key,
                label: key
            })
        }
    }

    if(data.length===0){
        return null
    }


    return (
        <Card className={classes.card}>
            <CardActionArea>
                <h4 style={{color:color,textAlign:"left"}}>
                    <AccessAlarm />&nbsp;| &nbsp; {title}
                </h4>
                <CardContent>
                    <TableDataView
                        data={data}
                        columns={columns}
                        tableTitle={title}
                        color={color}
                    />
                </CardContent>
            </CardActionArea>

        </Card>
    );
}
