import { combineReducers } from 'redux';

import menus from './reducer/reducer-menus';
import login from './reducer/reducer-login';
import navigations from './reducer/reducer-navigations';
import addingModel from "./reducer/reducer-adding-model";
import info from "./reducer/reducer-selection-info";
import enquiry from "./reducer/reducer-tickets";
import pbx from "./reducer/reducer-pbx";

export default combineReducers({
    menus,
    login,
    navigations,
    addingModel,
    info,
    enquiry,
    pbx
})
