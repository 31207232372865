import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderListPrivate,
    renderForm, removeEntry
} from "../generic/databases-function";
import {GetUserToken} from "../../api/services";
import eConfig from "../../config";
import Calendar from "../generic/Calendar";
import moment from "moment";

const getCurrentDate=()=>{
    return moment().format("YYYY-MM-DD");
}
export default class ComFieldLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Subscribers: [],
            Locations: [],
            Campaigns: [],
            Allocations:[],

            newAgent: "",
            newCampaign: "",
            newDate: getCurrentDate(),
            newTimeStart:"",
            newTimeEnd:"",
            newLocations: {},
            showList: true,
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, "otp", "Subscription", "Subscribers");
        await fetchUtilData(this, "otp", "Locations", "Locations");
        await fetchUtilData(this, "otp", "Campaign", "Campaigns");
        await fetchUtilData(this, "otp", "SubscriberAssignLocation", "Allocations");
    }

    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    handleInputCheckBoxLocation = (e) => {
        const key = e.target.name;
        let tmp = this.state;
        if(typeof tmp.newLocations[key] ==="undefined"){
            tmp.newLocations[key]=false;
        }
        tmp.newLocations[key] = !tmp.newLocations[key];
        this.setState(tmp);
    }

    fetchApi = async (url, hub, method, callback) => {
        console.log("$$$$$----> ", url);
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((response) => {
                callback(response, null);
            }).catch((e) => {
            callback(null, e, null);
        });
    }
    findSelectedLocations=()=>{
        let mapLoc={};
        for(let  i in this.state.Locations){
            const row=this.state.Locations[i];
            mapLoc[row.name]=row;
        }
        let ls=[];
        for(let i in this.state.newLocations){
            const boo = this.state.newLocations[i];
            if(boo){
                ls.push(mapLoc[i]);
            }
        }
        return ls
    }
    submitEntry=async (e)=>{
        const _this=this;
        e.preventDefault();
        if(!window.confirm("Are you sure to submit this entry?")){
            return;
        }
        const state=this.state;
        let locations=this.findSelectedLocations();

        let hub={
            Org:GetUserToken().OrgCode,
            Ref :state.newAgent,
            Campaign : state.newCampaign,
            Product :state.newCampaign,
            TargetDate :state.newDate,
            Locations :locations,
            StartTime :state.newTimeStart,
            EndTime :state.newTimeEnd
        }

        let backend = eConfig.otpBackend;
        let endpoint = "/api/agent/assign/new";
        let url = eConfig.serverBackendOtp + "/" + backend + endpoint;

        await this.fetchApi(url, hub, "POST", async (dataIn, err) => {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showList = true;
                    _this.setState(tmp);
                    await _this.loadInitialData();
                }
            }

        });
    }
    setDate = (dateIn,key) => {
        this.setState({
            [key]: dateIn
        })
    }
    renderForm = () => {

        let ls=[
            {name:"newTimeStart",label:"Work Start time"},
            {name:"newTimeEnd",label:"Work End time"},
        ];
        const generateInput=(name,label)=>{
            return(
                <div className="form-group row">
                    <label style={{color: "gray"}}>{label}</label>
                    <input
                        name={name}
                        className="form-control"
                        required={true}
                        onChange={(e) => this.handleInput(e)}
                        type={"time"}
                    />
                </div>
            )
        }
        return (
            <div className={"container"}>
                <form onSubmit={this.submitEntry}>
                    <div className={"row "}>
                        <div className={"col-lg-6"}>

                            <div className="form-group row">
                                <label style={{color: "gray"}}>Target Date</label>
                            <Calendar
                                className="form-control"

                                setDate={(inDate)=>this.setDate(inDate,"newDate")}
                            />
                            </div>

                            {ls.map((row)=>{
                                return generateInput(row.name,row.label)

                            })}

                            <div className="form-group row">
                                <label style={{color: "gray"}}>Select Campaign</label>
                                <select
                                    name="newCampaign"
                                    className="form-control"
                                    required={true}
                                    onChange={(e) => this.handleInput(e)}
                                >
                                    <option value={""}>Choose..</option>
                                    {this.state.Campaigns.map((row, index) => {
                                        return (
                                            <option value={row.name}>{row.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="form-group row">
                                <label style={{color: "gray"}}>Select Agent</label>
                                <select
                                    name="newAgent"
                                    className="form-control"
                                    required={true}
                                    onChange={(e) => this.handleInput(e)}
                                    size={10}
                                >
                                    <option value={""}>Choose..</option>
                                    {this.state.Subscribers.map((row, index) => {
                                        return (
                                            <option value={row.ref}>{row.names}</option>
                                        )
                                    })}
                                </select>
                            </div>

                        </div>

                        <div className={"col-lg-6"}>
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title" style={{color:"red"}}>Choose locations</h5>
                            {this.state.Locations.map((row, index) => {
                                return (
                                    <div className="form-check">
                                        <input
                                            name={row.name}
                                            type="checkbox"
                                            className="form-check-input"
                                            id={"Check1" + index}
                                            onChange={(e)=>this.handleInputCheckBoxLocation(e)}
                                        />
                                        <label className="form-check-label" htmlFor={"Check1" + index}>
                                            &nbsp;&nbsp;{row.name}
                                        </label>
                                    </div>
                                )
                            })}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"row "}>
                        <div className={"col-lg-12"}>
                            <button type={"submit"} className={"btn btn-primary pull-right"}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderList = () => {
        if(this.state.Allocations.length===0){
            return(
                <div className={"container alert alert-info"}>
                    Empty list, not implement
                </div>
            )
        }
        return(
            <div className={"container"}>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Agent</th>
                        <th>Campaign</th>
                        <th>StartTime</th>
                        <th>EndTime</th>
                        <th>Locations</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.Allocations.map((row,index)=>{

                        if(row.targetdate !==getCurrentDate()){
                            return null
                        }
                        return(
                            <tr>
                                <td>{row.targetdate}</td>
                                <td>{row.ref}</td>
                                <td>{row.campaign}</td>
                                <td>{row.starttime}</td>
                                <td>{row.endtime}</td>
                                <td>
                                    <a className={"btn btn-default"}>view</a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    loadDefaultLocation=()=>{
        let tmp = this.state;
        for(let i in this.state.Locations){

        }
    }

    render() {

        let _this = this;
        let extraClass=this.state.showList?"btn-primary":"btn-danger";
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Agent Allocation
                                <a
                                    className={"btn pull-right "+extraClass}
                                    onClick={()=>this.setState({showList:!this.state.showList})}
                                >
                                    {this.state.showList?<span>New Assign</span>:<span>Cancel</span>}
                                </a>
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }


}
