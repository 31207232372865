import {LOAD_TICKET_DATA, TICKET_DATA_RECEIVED} from "../constants";
import {GetUserToken} from "../../api/fetching-data";
import {FetchData} from "../../api/fn";

const ticketDataMiddleware = store => next =>  async action => {

    let orgCode ="none";
    if(GetUserToken()!==null){
        orgCode= GetUserToken().OrgCode;
    }

    if (action.type === LOAD_TICKET_DATA) {
        console.log("dataService!@!@@!@!-----> ", action);
        if (action.type === LOAD_TICKET_DATA) {
            let hub = {
                Org: orgCode
            }
            let endpoint = "/ticket/loader";
            await FetchData(hub, endpoint, async (data) => {
                console.log("!@!@@!@!-----> ", data);
                let out = {};
                if (typeof data !== "undefined") {
                    out = data.List;
                }
                console.log("FFF2: LOAD_TICKET_DATA:", out, data);
                next({type: TICKET_DATA_RECEIVED, data: out});
            });
        }
    }else{
        next(action);
    }

}





export { ticketDataMiddleware};

// cp -r /etc/letsencrypt/live/voip2.easipath.com/fullchain.pem /etc/asterisk/keys/asterisk.pem

