import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingClientComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.MyModel = [
            {
                field: "name",
                desc: "Name",
                placeholder: "Name",
                required: true,
                type: "text",
                readonly: false,
                staticValue: ""
            }, {
                field: "ProcessName",
                desc: "Process Name",
                placeholder: "Process Name",
                required: false,
                type: "text",
                readonly: false,
                filter: true,
                filterTable: "FlowProcess",
                filterKey: "name",
                filterValue: "name",
                filterConditionOrgCode:"org"
            }, {
                field: "MeasureType",
                desc: "Unit of Measure",
                placeholder: "Unit of Measure",
                required: true,
                type: "option",
                readonly: false,
                options: [
                    {val: "day", desc: "Day"},
                    {val: "week", desc: "Week"},
                    {val: "month",desc:"Month"},
                ]
            }, {
                field: "MeasureValue",
                desc: "MeasureValue",
                placeholder: "MeasureValue",
                required: false,
                type: "float",
                readonly: false,
                staticValue: ""
            },{
                field: "WarningLevel",
                desc: "WarningLevel",
                placeholder: "WarningLevel",
                required: false,
                type: "float",
                readonly: false,
                staticValue: ""
            },{
                field: "Position",
                desc: "Position",
                placeholder: "Position",
                required: false,
                type: "float",
                readonly: false,
                staticValue: ""
            },{
                field: "Requirements",
                desc: "Requirements",
                placeholder: "Requirements",
                required: false,
                type: "map",
                readonly: false,
                staticValue: ""
            },


        ];
        this.ModelName = "FlowStep";
        this.Crum = "/crum";
        this.PrimaryKey = "org"
    }
    /*
    type FlowStep struct {
	Id string
	Org string
	ProcessName string
	Name string
	MeasureType string
	MeasureValue float64
	WarningLevel float64
	AlertLevel float64
	Requirements map[string]interface{} // [requirement-name] requirement-type -> [Sign From16]file
	Position float64

	Profile     map[string]interface{}
	OrgDateTime string
	Date        string
	Time        string
	Status      string
}// key: org,ProcessName,name
     */

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Step Of Process Flow</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name", "ProcessName", "MeasureType", "MeasureValue","Position"]}
                            ListDeleteKey={[
                                {field:"ProcessName",type:"string"},
                                {field: "name", type: "string"},


                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}