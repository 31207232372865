import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default (props)=> {
    const classes = useStyles();

    let digits=[1,2,3,4,5,6,,7,8,9,"*",0,"#"];

    return digits.map((digit,index)=>{
        return(
            <Button variant="contained" color="primary" style={{maxWidth:20,maxHeight:20,minWidth:20,minHeight:20}}>{digit}</Button>
        )
    });
}
