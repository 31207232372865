import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingBatchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.MyModel = [
            {
                field:"Worker",
                desc:"Worker",
                placeholder:"Worker",
                required:true,
                type:"text",
                readonly:false,
                filter:true,
                filterTable:"Worker",
                filterKey:"username",
                filterValue:"name",
                filterConditionOrgCode:"org"
            },
            {
                field:"poolname",
                desc:"Pool Name",
                placeholder:"Pool Name",
                required:true,
                type:"text",
                readonly:false,
                filter:true,
                filterTable:"Pools",
                filterKey:"name",
                filterValue:"name",
                filterConditionOrgCode:"org"
            },


        ];
        /*
        Worker text,
	PoolName text,
         */
        this.ModelName = "WorkerPool";
        this.Crum = "/crum";
        this.PrimaryKey = "org"
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Agent Allocation</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Worker","PoolName"]}
                            ListDeleteKey={[
                                {field: "worker", type: "string"},

                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}