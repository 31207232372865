import React, {Fragment,Component} from 'react';
import {Header, MenuModule,MenuInfoBar} from "../Layouts";
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import LeftSide from "../common/page/LeftSide";
import PageHeading from './header';
import PageFooter from './footer';
import "../../asset/css/wallpaper.css"
import  SocketManager from "../websocket/SocketManager";
import Connector from "../../reducers/connect/reducer-connector";

const styles={
    Paper:{
        padding:20,
        marginTop: 10,
        marginBottom:10,
        marginLeft:10,
        minHeight:"100%",
        maxWidth:"100%",
    }
}

 class Wallpaper extends Component {



    render() {

        if(this.props.hasHeader){
            return (
                <SocketManager >
                    <Header/>
                    <MenuModule/>
                    <Grid container sm={12} style={{marginTop:35,minHeight:"100%",maxWidth:"100%"}} >
                        <Grid item sm={2}>
                            <Paper style={styles.Paper}>
                                <LeftSide props={this.props} />
                            </Paper>
                        </Grid>
                        <Grid item sm={10}>
                            <Paper style={styles.Paper}>
                                <MenuInfoBar props={this.props} pageTitle={this.props.pageTitle}/>
                                <Fragment>
                                    {this.props.children}
                                </Fragment>
                            </Paper>
                        </Grid>
                    </Grid>
                </SocketManager>
            );
        }


        return (
            <SocketManager>
                <Grid container sm={12} style={{minHeight:"100%",maxWidth:"100%"}} >
                    <Grid item sm={12}>
                        <Paper style={{minHeight:"100%",
                            maxWidth:"100%",}}>
                            <Fragment>
                                {this.props.children}
                            </Fragment>
                        </Paper>
                    </Grid>
                </Grid>
            </SocketManager>
        );
    }
    render2() {
        return (
            <div className="wallpaper">
                {this.props.hasHeader &&(
                    <PageHeading {...this.props}/>
                )}

                    {this.props.children}

                {this.props.hasFooter &&(
                    <PageFooter {...this.props}/>
                )}
            </div>

        );
    }
}

export default Connector(Wallpaper)

