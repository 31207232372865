import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core";

const styles   = theme => ({
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
});
const useTreeItemStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));


export default({makeCall,stopCall,callStatus,sipStatus})=>{
    const classes = useTreeItemStyles();
    return(
        <Card className={classes.card}>
            <div style={styles.details}>
                <CardContent style={styles.content}>
                    <Typography component="h5" variant="h5">
                        Live Call Web
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Click on the Call button below & hangup after
                    </Typography>
                </CardContent>
                <div style={styles.controls}>
                    <IconButton aria-label="Previous" onClick={()=>makeCall()}>
                        <i className={"glyphicon glyphicon-earphone"} style={{...styles.playIcon,color:"green"}} />
                    </IconButton>
                    <IconButton aria-label="Play/pause" onClick={()=>{stopCall()}}>
                        <i className={"glyphicon glyphicon-phone-alt"} style={{...styles.playIcon,color:"red"}}/>
                    </IconButton>
                    <IconButton aria-label="Next">
                        <i className={"glyphicon glyphicon-envelope"} style={styles.playIcon}/>
                    </IconButton>

                    <span >Status: {callStatus}</span>
                    &nbsp;|&nbsp;
                    <span >Sip: {sipStatus}</span>
                </div>
            </div>
            <CardMedia
                style={styles.cover}
                image="/static/images/cards/live-from-space.jpg"
                title="Live from space album cover"
            />
        </Card>
    )
}


