import {SAVE_ROLE, SAVE_UTIL} from "./enquiry";

const
    LOGGED_IN = "LOGGED_IN",
    SAVE_NAVIGATION_MODULE = "SAVE_NAVIGATION_MODULE",
    SAVE_NAVIGATION_MENU = "SAVE_NAVIGATION_MENU",
    SAVE_NAVIGATIONS = "SAVE_NAVIGATIONS",
    SAVE_ATTIBUTE_PEOPLE = "SAVE_ATTIBUTE_PEOPLE",
    SAVE_ATTIBUTE_SERVICE="SAVE_ATTIBUTE_SERVICE",
    SAVE_ATTIBUTE_PRODUCT="SAVE_ATTIBUTE_PRODUCT",
    LOAD_TICKET_DATA="LOAD_TICKET_DATA",
    TICKET_DATA_RECEIVED="TICKET_DATA_RECEIVED",


    PBX_CHANGE_EXTENSION="PBX_CHANGE_EXTENSION",
    PBX_CHANGE_AGENT="PBX_CHANGE_AGENT",
    PBX_CALL_OUT_REQUEST="PBX_CALL_OUT_REQUEST",
    PBX_CALL_OUT_END="PBX_CALL_OUT_END",
    PBX_CALL_OUT_DO="PBX_CALL_OUT_DO",
    PBX_CALL_OUT_CALLERID="PBX_CALL_OUT_CALLERID",
    PBX_CALL_OUT_FORM="PBX_CALL_OUT_FORM",
    PBX_CALL_INCOME_REQUEST="PBX_CALL_INCOME_REQUEST",
    PBX_CALL_INCOME_ANSWER="PBX_CALL_INCOME_ANSWER",
    PBX_CALL_INCOME_HANGUP="PBX_CALL_INCOME_HANGUP",
    PBX_CHANGE_REGISTRY="PBX_CHANGE_REGISTRY",
    PBX_CALLING_STATUS="PBX_CALLING_STATUS"
;

export {
    LOGGED_IN,
    SAVE_NAVIGATION_MODULE,
    SAVE_NAVIGATION_MENU,
    SAVE_NAVIGATIONS,
    SAVE_ROLE, SAVE_UTIL,
    SAVE_ATTIBUTE_PEOPLE,
    SAVE_ATTIBUTE_SERVICE,
    SAVE_ATTIBUTE_PRODUCT,
    LOAD_TICKET_DATA,
    TICKET_DATA_RECEIVED,
    PBX_CHANGE_EXTENSION,
    PBX_CHANGE_AGENT,
    PBX_CALL_OUT_REQUEST,
    PBX_CALL_OUT_END,
    PBX_CALL_OUT_FORM,
    PBX_CALL_OUT_DO,
    PBX_CALL_OUT_CALLERID,
    PBX_CALL_INCOME_REQUEST,
    PBX_CALL_INCOME_ANSWER,
    PBX_CALL_INCOME_HANGUP,
    PBX_CHANGE_REGISTRY,
    PBX_CALLING_STATUS
}
