import React, {Component} from 'react';
import '../asset/css/login.css';
import PageContentWallpaper from '../component/common/ef-pageContent';

export default class HomeComponent extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (


            <PageContentWallpaper {...this.props} >

                                <div className="row">

                                    <div className="col-xs-12">
                                        <p>Welcome to Cloud Calls System!</p>
                                        <p>How can we help?</p>
                                    </div>

                                </div>



            </PageContentWallpaper>


        );
    }
}

