import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';


class SettingWorkerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            List: [],
            Current: null,
            modeList: true,
            modeUpdate: false,
            newEntry: {
                Username: "",
                Role:"",
                Name: "",
                Surname: "",
                Email: "",
                Phone: "",
                Password: "",
                ConfirmPassword: "",
            }
        }

        /*






	hub.Department ="sale"
	hub.Password  ="admin"
         */

    }

    async componentDidMount() {
        await this.isSupperOrDealerUser()
        await this.fetchAgents();
    }

    isSupperOrDealerUser = async () => {
        let user = GetUserToken();
        const role = user.Role;
        if (role === "super") {
            return
        }
        if (role === "admin") {
            return
        }

        alert("Sorry you can't perform this action,Your role is: " + role + "");
        window.location.href = "#/home"
    }
    fetchAgents = async () => {
        let _this = this;
        let conds = [{
            key:"org",
            val:GetUserToken().OrgCode
        }];
        this.fetchingAll("worker", conds, (data) => {
            console.log("fetchAgents :> ", data);
            _this.setState({
                List: data,
            });
        });
    }

    fetchingAll = async (table, conds, callback) => {

        const user = GetUserToken();
        console.log("User :> ", user);

        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        //  /backend-ecare/api/global-service
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchingAll response > ", table, dataIn);

            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);

            }
        });
    }

    viewListDetail = (row) => {
        console.log("View List detail Dealer ", row);
        let o = {
            Ref: row.ref,
            Dealer:row.Dealer,
            Email: row.email,
            Phone: row.phone,
            Name: row.name,
            ContactPerson: row.contactperson,
            ContactPhone: row.contactphone,
            ContactAddress: row.contactaddress,
        }

        let tmp = this.state;
        tmp.newEntry = o;
        tmp.modeList = false;
        tmp.modeUpdate = true;
        this.setState(tmp);

    }
    closeView = () => {
        let tmp = this.state;
        tmp.newEntry = {
            Ref: "",
            Dealer:"",
            Email: "",
            Phone: "",
            Name: "",
            ContactPerson: "",
            ContactPhone: "",
            ContactAddress: "",
        }

        tmp.modeList = true;
        tmp.modeUpdate = false;
        this.setState(tmp);
    }

    submitEntry = async () => {
        let _this = this;
        if(this.state.newEntry.Password !==this.state.newEntry.ConfirmPassword){
            return alert("Error Password doesn't much!")
        }
        if (!window.confirm("Are you sure that you want to add this Dealer?")) {
            return
        }
        //TODO validation here

        let hub = {...this.state.newEntry};
        hub.Org = hub.Ref;

        const user = GetUserToken();
        hub.Org = user.OrgCode;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/worker/new";

        console.log("submitEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry response > ", dataIn);

            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                if (data !== "OK") {
                    alert(data)
                } else {
                    alert("Thank you Agent added!");
                    _this.closeView();
                    _this.fetchAgents();
                }


            }
        });

    }

    renderList = () => {

        if (!this.state.modeList) {
            return null
        }

        return this.state.List.map((row, index) => {
            return (<tr key={index}>
                <td>{row.username}</td>
                <td>{row.name+" "+row.surname}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{row.role}</td>
                <td>
                    <a
                        onClick={() => {
                            this.viewListDetail(row)
                        }}
                        style={{cursor: "pointer"}}
                    >View Detail</a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;

                </td>
            </tr>)
        })

    }
    getNewEntryValue = (key) => {
        return this.state.newEntry[key];
    }
    setNewEntryValue = (e, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = e.target.value;
        this.setState(tmp);
    }
    newEntryForm = () => {
        if (this.state.modeList) {
            return null
        }

        /*
     hub.Username    = "imbani16@gmail.com"
	hub.Role        = "agent"
	hub.Name        = "Nathan"
	hub.Surname    ="Biangacila"
	hub.Email      ="imbani16@gmail.com"
	hub.Phone      ="0723806555"
	hub.Department ="sale"
	hub.Password  ="admin"
         */

        let forms = [
            {name: "Username", desc: "Username", placeHolder: "e.g. john", type: "text", required: true},
            {name: "Email", desc: "Email", placeHolder: "e.g. info@home.com Or user2019", type: "text", required: true},
            {name: "Name", desc: "Name", placeHolder: "e.g. My name", type: "text", required: true},
            {name: "Surname", desc: "Surname", placeHolder: "e.g. My Surname", type: "text", required: true},
            {name: "Phone", desc: "Phone", placeHolder: "e.g. 0729139504", type: "text", required: true},
            {name:"Role",desc:"Role",placeHolder:"e.g. Admin or Agent",type:"option",required:true,options:["agent","admin"]},
            {name: "Password", desc: "Password", placeHolder: "e.g. 0nyWord!2019", type: "password", required: true},
            {name: "ConfirmPassword", desc: "ConfirmPassword", placeHolder: "e.g. 0nyWord!2019", type: "password", required: true},
        ];

        const innerRender = (inObject, index) => {
            let myReadOnly = false;
            if (this.state.modeUpdate) {
                myReadOnly = true
                /*if(inObject.name ==="Ref" || inObject.name==="Email"){
                    myReadOnly=true
                }*/
            }
            if(inObject.type ==="option"){
                return(
                    <div className="form-group" key={index}>
                        <label htmlFor="exampleInputEmail1">{inObject.desc}</label>
                        <select
                            className="form-control"
                            id={inObject.name}
                            placeholder={inObject.placeHolder}
                            required={inObject.required}
                            value={this.getNewEntryValue(inObject.name)}
                            onChange={(e)=>this.setNewEntryValue(e,inObject.name)}
                            disabled={myReadOnly}
                        >
                            <option value={""}>---Select---</option>
                            {inObject.options.map((item)=>{
                                return(
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                    </div>
                )
            }

            return (
                <div className="form-group" key={index}>
                    <label htmlFor="exampleInputEmail1">{inObject.desc}</label>
                    <input
                        type={inObject.type}
                        className="form-control"
                        id={inObject.name}
                        placeholder={inObject.placeHolder}
                        required={inObject.required}
                        value={this.getNewEntryValue(inObject.name)}
                        onChange={(e) => this.setNewEntryValue(e, inObject.name)}
                        disabled={myReadOnly}
                    />
                </div>
            )
        }

        let myReadOnly = false;
        if (this.state.modeUpdate) {
            myReadOnly = true
        }

        let _this = this;
        const optionsDealer=()=>{
            let ls=[];
            const role = GetUserToken().Role;
            const email = GetUserToken().Username;

            for(let i in this.state.Dealers){
                const row = this.state.Dealers[i];
                if(role ==="super"){
                    ls.push({
                        name:row.ref,
                        desc:row.name,
                    })
                }
                if(role ==="dealer"){
                    if(email ===row.email){
                        ls.push({
                            name:row.ref,
                            desc:row.name,
                        })
                    }

                }
            }

            return ls.map((row,index)=>{
                return(
                    <option value={row.name}>{row.name +" "+row.desc}</option>
                )
            })
        }


        return (
            <div className="row">

                <div className="col-xs-12 col-lg-6">

                    {!this.state.modeUpdate ?
                        <h3 className={"alert alert-info"}>New Dealer</h3>
                        :
                        <h3 className={"alert alert-danger"}>Update Dealer Info</h3>
                    }
                    <form onSubmit={this.submitEntry}>
                        {forms.map((row, index) => {
                            return innerRender(row, index)
                        })}

                        {!this.state.modeUpdate ?
                            <button
                                className={"btn btn-primary pull-right"}
                                type={"submit"}
                            >Submit</button>
                            :
                            <button
                                className={"btn btn-danger pull-right"}
                                type={"button"}
                                onClick={() => this.closeView()}
                            >Close</button>
                        }
                    </form>
                </div>
            </div>
        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Agent Management
                            {!this.state.modeList ?
                                <a className={"btn btn-danger pull-right"}
                                   onClick={() => this.setState({modeList: true, modeUpdate: false})}>Cancel</a>
                                :
                                <a className={"btn btn-primary pull-right"}
                                   onClick={() => this.setState({modeList: false})}>New Agent</a>
                            }
                        </h2>
                    </div>

                </div>

                {this.state.modeList ?
                    <div className="row">
                        <div className="col-xs-12">

                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderList()}
                                </tbody>
                            </table>

                        </div>
                    </div> : null}


                {this.newEntryForm()}

            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingWorkerComponent)