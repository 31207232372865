import React from "react";
import {LOAD_TICKET_DATA} from "../constants";

const LoadTicketData =  () => {
    console.log("REQ-1");
    return {
        type: LOAD_TICKET_DATA,
    }
}

export {
    LoadTicketData,

}

