import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    submitEntry
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import ComboboxOptions from "../../component/common/form-input-control/ComboxOption";



class DescribeProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            module:"",
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }
    onCancel=()=>{
        this.setState({
            showEdit: false
        })
    }
    handleInput = async (e) => {
        console.log("onChangeModule :)-> ", e.target.name, " > ", e.target.value);
        let tmp = this.state;
        tmp[e.target.name] = e.target.value;
        this.setState(tmp);
        // await this.fetchAttributes();
    }
    componentDidMount() {
        //todo

    }

    fetchRoles = async () => {
        //todo
    }
    submitEntry = async (e) => {

        //todo delete after live
        let data = this.props.enquiry.AttributeProduct;
        let entry = {module:this.state.module,...this.state.newEntry}
        data.push(entry);
        this.props.SaveAttributeProduct(data);
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        console.log("submitEntry--->", this.state.newEntry);
        console.log("submitEntry2 --->", JSON.stringify(entry));

        //todo uncomment after
        return
        let endpoint = "/ticket/insert/roles";
        let othersField = [
            {key: "Org", val: GetUserToken().OrgCode},
            {key: "Module", val: this.state.module},
        ];
        let reloadFunction = [this.fetchRoles];
        await submitEntry(e, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }


        let columns = [
            {id: 'FieldName', label: 'Field', minWidth: 170, dataType: "string"},
            /*{id: 'Section', label: 'Section', minWidth: 170, dataType: "string"},*/
            {id: 'Position', label: 'Position.', minWidth: 170, dataType: "float"},
            {id: 'Unique', label: 'Unique', minWidth: 170, dataType: "boolean"},
            {id: 'Mandatory', label: 'Mandatory', minWidth: 170, dataType: "boolean"},
            {id: 'Name', label: 'Display 1', minWidth: 170, dataType: "boolean"},
            {id: 'Surname', label: 'Display 2', minWidth: 170, dataType: "boolean"},
            {id: 'Phone', label: 'As Phone', minWidth: 170, dataType: "boolean"},
            {id: 'Email', label: 'AsEmail', minWidth: 170, dataType: "boolean"},
        ];

        let ls=[];
        ls = this.props.enquiry.AttributeProduct;


        let data = {columns: columns, data: ls};
        return (
            <TableDataView
                {...data}
                tableTitle={"Product Attribute"}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
            />
        )
    }

    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }

        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Util " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }

    renderModule=()=>{
        const roles=this.props.enquiry.Roles;
        let ls=[];
        for(let i in roles){
            const row=roles[i];
            ls.push({
                key:row.name,
                val:row.name.toLocaleUpperCase(),
            })
        }
        return(
            <ComboboxOptions
                name={"module"}
                label={"Module"}
                options={ls}
                handleInput={this.handleInput}
                onClickButton={this.onChangeModule}
                btnText={"Change Module"}
                includeBtn={false}
            />
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} >

                <GridContainer style={{marginTop:50}}>

                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderListUtil()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center" >
                        {this.renderEditForm()}
                    </GridItem>

                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(DescribeProductPage);

const options = ["yes", "no"];
const optionsDataType = ["string", "float", "boolean"];
const optionsBoolean = ["yes", "no"];
const listField = [
    {
        field: "FieldName",
        label: "Field Name",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    /*   {
           field: "Section",
           label: "Display Section",
           options: options,
           type: "text",
           required: true,
           dataType: "string"
       },*/
    {
        field: "Position",
        label: "Position",
        options: options,
        type: "float",
        required: true,
        dataType: "float"
    },
    {
        field: "DataType",
        label: "DataType",
        options: optionsDataType,
        type: "radio",
        required: true,
        dataType: "string"
    },
    /*    {
            field: "Username",
            label: "Username",
            options: optionsBoolean,
            type: "boolean",
            required: true,
            dataType: "boolean"
        },
        {
            field: "Password",
            label: "Password",
            options: optionsBoolean,
            type: "boolean",
            required: true,
            dataType: "boolean"
        },*/
    {
        field: "Unique",
        label: "Unique",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Mandatory",
        label: "Mandatory",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Name",
        label: "Display 1",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Surname",
        label: "Display 2",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Required",
        label: "Required",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Phone",
        label: "Phone",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Email",
        label: "Email",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },

];
