import React, {Component} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/agent-dashboard.css';
import PageContentWallpaper from '../common/ef-pageContent';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import FlatButton from "material-ui/FlatButton";
//import Dialog from "material-ui/Dialog";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from "material-ui/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import loader from '../../asset/img/ajax_loader.gif';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import moment from "moment";

import PopupNewActivity from "./popup/popup-new-activity";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}


export default class ComFlowCustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomersAll: [],
            Customers: [],
            Steps:[],
            CustomerSteps:[],
            showPopupNewActivity:false,
            selectedCustomer:null,
        }
    }

    async componentDidMount() {
        await this.fetchCustomersAll();
        await this.loadCustomers();
        await this.fetchSteps();
        await this.fetchCustomerSteps();

    }

    handleInputChild=(key,val)=>{
        let tmp =this.state;
        tmp[key]=val;
        this.setState(tmp);
    }
    /*
    CREATE TABLE IF NOT EXISTS cclms.FlowStep(
	Id text,
	Org text,
	ProcessName text,
	Name text,
	MeasureType text,
	MeasureValue float,
	WarningLevel float,
	AlertLevel float,
	Requirements map<text,text>,
	Position float,

);
     */



    fetchCustomerSteps= async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "FlowCustomerStep";
        const conds = [
            {key: "org", val: user.OrgCode, type: "string"}
        ];
        console.log("fetchCustomerSteps send > ", conds);
        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchCustomerSteps response > ", data);

            _this.setState({
                CustomerSteps: data
            })
        });
    }

    fetchSteps= async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "FlowStep";
        const conds = [
            {key: "org", val: user.OrgCode, type: "string"}
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchSteps response > ", data);
            const keyOne="position";
            data.sort((a, b) => (parseInt(a[keyOne]) > parseInt(b[keyOne])) ? 1 : -1)

            _this.setState({
                Steps: data
            })
        });
    }

    fetchCustomersAll = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "flowCustomer";
        const conds = [
            {key: "org", val: user.OrgCode, type: "string"}
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchCustomersAll response > ", data);
            _this.setState({
                CustomersAll: data
            })
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadCustomers = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Role = user.Role;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/flow/customer/list";
        console.log("loadCustomers send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                console.log("loadCustomers response > ", data);
                _this.setState({
                    Customers: data
                })
            }
        });
    }




    renderCustomerList=()=>{

        let ls=[];
        ls=this.state.Customers;
        /*
    CREATE TABLE IF NOT EXISTS cclms.FlowCustomerStep(
	Id text,
	Org text,
	CustomerRef text,
	ProcessName text,
	StepName text,

	StartDate text,
	EndDate text,
	ActualEndDate text,
	ActualStatus text,
	LastActivity map<text,text>,
	LastAlert map<text,text>,
	LastWarning map<text,text>,
	LastUsername text,
	CurrentPosition float,

);
     */
        const _this = this;

        const showStepPositionDetail=(row)=>{
            alert("Step Position:"+row.position+"\nStep name: "+row.name);
        }

        const checkStepIfExist=(customer,processName,stepName)=>{


                for(let i in _this.state.CustomerSteps){
                    const row = _this.state.CustomerSteps[i];
                    let customerref=row.customerref;
                    const arrCust=customerref.split("--");
                    if(arrCust.length===2){
                        customerref = arrCust[1];
                    }

                    console.log("^^^^^1>> ",customerref===customer," > ",customerref,"===",customer);
                    if(customerref===customer&& row.processname===processName&&row.stepname===stepName){
                        return(
                            <span className={""} style={{color:"blue"}}>!</span>
                        )
                    }
                }

            return(
                <span className={""} style={{color:"red"}}>X</span>
            )
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Ref</th>
                    {this.state.Steps.map((step,x)=>{
                        return(
                            <th style={{textAlign:"center"}}>

                                <span  className={"badge"}> {step.position}</span>
                                <p>{step.name}</p>
                            </th>

                        )
                    })}

                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{


                    return(
                        <tr>
                            <td style={{width:200}}>
                                <a className={"btn btn-default"} onClick={()=>this.setState({showPopupNewActivity:true,selectedCustomer:row})}>Act.</a> &nbsp;
                                <a className={"btn btn-default"}>Step</a> &nbsp;
                                <a className={"btn btn-default"}>Stat.</a> &nbsp;
                            </td>
                            <td>{row.Identify}</td>
                            {this.state.Steps.map((step,x)=>{
                                return(
                                    <th style={{textAlign:"center",cursor:"pointer"}}>{checkStepIfExist(row.Identify,step.processname,step.name)}</th>
                                )
                            })}

                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>
                    <div className={"container"}>
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className={"alert alert-info"}>Customers Step</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                {this.renderCustomerList()}
                            </div>
                        </div>

                        <PopupNewActivity
                            handleInput={this.handleInputChild}
                            popupController={this.state.showPopupNewActivity}
                            popupControllerKey={"showPopupNewActivity"}
                            popupTitle={"New Activity"}
                            popupSelectedKey={"selectedCustomer"}
                            selectedCustomer={this.state.selectedCustomer}
                            state={this.state}
                        />
                    </div>
                </MuiThemeProvider>
            </PageContentWallpaper>
        )
    }

}
