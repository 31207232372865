import React, {Component} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/agent-dashboard.css';
import PageContentWallpaper from '../common/ef-pageContent';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import FlatButton from "material-ui/FlatButton";
//import Dialog from "material-ui/Dialog";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from "material-ui/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import loader from '../../asset/img/ajax_loader.gif';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import moment from "moment";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}


export default class ComLeadDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Dispositions: [],
            ReportData: {},
            startDate:moment().format("YYYY-MM-DD"),
            endDate:moment().format("YYYY-MM-DD"),
            Workers:{},
        }
    }

    async componentDidMount() {
        await this.fetchWorkers();
        await this.fetchDisposition();
        await this.loadReportDateRange();

    }

    fetchWorkers = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "worker";
        const conds = [
            {key:"org",val:user.OrgCode,type:"string"}
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchWorkers response > ", data);
            let ls={};
            for(let i in data){
                const row = data[i];
                ls[row.email]=row;
            }
            _this.setState({
                Workers: ls
            })
        });
    }
    fetchDisposition = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "disposition";
        let conds = [
            {key: "org", type: "string", val: user.OrgCode},
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchDisposition response > ", data);
            _this.setState({
                Dispositions: data
            })
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadReportDateRange = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.startDate = this.state.startDate;
        hub.endDate = this.state.endDate;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/disposition/report/counter-date-range";
        console.log("loadReportDateRange send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                console.log("loadReportDateRange response > ", data);
                _this.setState({
                    ReportData: data
                })
            }
        });
    }

    renderReportResult=()=>{

        let lsHeader=[];
        for(let i in this.state.Dispositions){
            const row = this.state.Dispositions[i];
            lsHeader.push(row.name)
        }

        let workers = [];
        for(let i in this.state.Workers){
            workers.push(i);
        }

        console.log("ReportData > ",this.state.ReportData);

        return(
            <table className={"table"} style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>Agent</th>
                        {lsHeader.map((item,index)=>{
                            return(
                                <th key={index}>{item}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                {workers.map((email,x)=>{
                    let fullname = "none";
                    if(typeof this.state.Workers[email]!=="undefined"){
                        const user=this.state.Workers[email];
                        fullname=user.name+" "+user.surname;
                    }
                    const _this =this;
                    const findData=(disp)=>{
                        if(typeof _this.state.ReportData[email] !=="undefined" ){
                            if(typeof _this.state.ReportData[email][disp] !=="undefined" ){
                                return (<span className={"badge"} >{_this.state.ReportData[email][disp]}</span>)
                            }
                        }
                        return 0;
                    }
                    return(
                        <tr key={x}>
                            <td>{fullname}</td>
                            {lsHeader.map((disposition,y)=>{
                                return(
                                    <td key={y}>{findData(disposition) }</td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>
                    <div className={"container"}>
                    <div className="row">
                        <div className="col-xs-12">
                            <h3>Report Date Range</h3>
                        </div>
                    </div>
                    <div className="row alert alert-info">
                    <form  onSubmit={this.loadReportDateRange}>

                        <div className="col-xs-5">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Start Date</label>
                                <input
                                    type={"date"}
                                    className="form-control"
                                    placeholder={"YYYY-MM-DD"}
                                    required={true}
                                    value={getNewFieldEntryValue("startDate")}
                                    onChange={(e) => setNewFieldEntryValue(e, "startDate")}
                                />
                            </div>
                        </div>
                        <div className="col-xs-5">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">End Date</label>
                                <input
                                    type={"date"}
                                    className="form-control"
                                    placeholder={"YYYY-MM-DD"}
                                    required={true}
                                    value={getNewFieldEntryValue("endDate")}
                                    onChange={(e) => setNewFieldEntryValue(e, "endDate")}
                                />
                            </div>
                        </div>
                        <div className="col-xs-2">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">&nbsp;</label>
                                <button type={"submit"} className="form-control">Search</button>
                            </div>
                        </div>

                    </form>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            {this.renderReportResult()}
                        </div>
                    </div>

                    </div>

                </MuiThemeProvider>
            </PageContentWallpaper>
        )
    }
}