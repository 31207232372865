import React, {Fragment} from "react";
import {SocketContext, useWebsocket} from './index';
import Config from "../../configures";
import ReconnectingWebSocket from "reconnecting-websocket";
import store from '../../reducers/store';
import Connect from "../../reducers/connect/reducer-connector";
import InCall from "../webcall/inCall";
import OuCall from "../webcall/outCall";
import CloudCall, {doHangup, sendDtmf,answer} from "../webcall/JanusSipCall";

let serverUrl = Config.ServerGlobal + "/backend-reservation/api/websocket";

export class WrappedSocketManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriber: null,
            prices: {},
            org: "easipath",
            ref: "biangacila@gmail.com",
            role: "admin",
            names: "Merveilleux",
            service: "taxi",
            info: "none",
            mySize: 5,
            extension: "00000"
        };


    }

    socket = null;
    static contextType = SocketContext;

    componentDidMount = async () => {
        //this.connection();
        await this.fetchData();
        //await this.registerCloudCalls();
        const _this = this;
        store.subscribe(async () => {
            const data = store.getState();
            await _this.receivedProps(data);
        });
    }

    componentWillUnmount = async () => {

        try {
            this.socket !== null && this.socket.disconnect();
        } catch (e) {
            // socket not connected
        }
    }
    receivedProps = async (nextProps) => {
        if (nextProps.profile.Subscriber !== null) {
            const sub = nextProps.profile.Subscriber;
            if (this.state.subscriber === null) {
                const removeBlankSpace = (inData) => {
                    let str = inData.replace(" ", "_");
                    return str
                }
                this.setState({
                    subscriber: sub,
                    org: removeBlankSpace(sub.Org),
                    ref: removeBlankSpace(sub.Ref),
                    role: "agent",
                    names: removeBlankSpace(sub.Names),
                    service: removeBlankSpace(sub.Campaign),
                    info: "none",
                })
                this.connection();
            }
        }
        //todo pbx check
        if (nextProps.pbx.Extension.username !== this.state.extension) {
            console.log("@@--@@-< different extrn: ",
                nextProps.pbx.Extension.username, " > ", this.state.extension)
            this.setState({
                extension: nextProps.pbx.Extension.username
            });
            await this.registerCloudCalls()

        }
    }
    fetchData = async () => {
        this.props.LoadTicketData();

    }

    connection = () => {
        serverUrl = serverUrl + '/' + this.state.org + '/' + this.state.ref + '/' + this.state.role +
            '/' + this.state.names + '/' + this.state.service + '/' + this.state.info;
        const uri = serverUrl;
        const urls = [uri];
        let urlIndex = 0;
        const options = {
            WebSocket: undefined,
            maxReconnectionDelay: 10000,
            minReconnectionDelay: 1000 + Math.random() * 4000,
            reconnectionDelayGrowFactor: 1.3,
            minUptime: 5000,
            connectionTimeout: 4000,
            maxRetries: Infinity,
            debug: false,
        };
        // round robin url provider
        const urlProvider = () => urls[urlIndex++ % urls.length];
        this.socket = new ReconnectingWebSocket(urlProvider, [], options);
        this.socket.onopen = () => {
            console.log("####---> ", 'Hello Mr. Server! monitor socket HODER BY DRIVER' + uri);
            const title = "WS_CONNCABS_CONNECTED";
            const data = this.state.info;
            try {
                this.socket.send(JSON.stringify({type: title, Type: title, Payload: data}));
            } catch (e) {
                console.log("WS_CONNCABS_CONNECTED ERROR sendMessageSocket > ", e, title, data)
            }
        };
        this.socket.onmessage = ({data}) => {
            let message = JSON.parse(data);
            console.log("onMessage Socket ^^^> ", message.Type, " > ", data);

            /*if(message.Type==="WS_CONNCABS_KEEPLIVE"){
                this.props.updateTotalMarketCap( message.Payload.Data);
            }
            if(message.Type===WS_AGENT_CUSTOMER){
                this.props.updateCustomerList(message.Payload.Data)
            }
            if(message.Type===WS_CUSTOMER_SURVEY){
                this.props.updateCustomerSurvey(message.Payload.Data)
            }
            if(message.Type===WS_ASSIGN_LOCATION){
                this.props.updateAgentLocation(message.Payload.Data)
            }*/
        }
    }

    /**
     *
     * PBx function
     */
    AlertBox = (typeOfMessage, message) => {
        console.log("AlertBox :> ", typeOfMessage, message);
        this.setState({
            alertOpen: true,
            alertType: typeOfMessage ? "success" : "error",
            alertMessage: message,

        })
    }
    handleAlertClose = () => {
        this.setState({
            alertOpen: false,
            alertType: "",
            alertMessage: "",
        })
    }

    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    dial = () => {
        if (this.state.isDialing) {
            return alert("Please wait while another call in progress!");
        }
        if (this.state.dialNumber === "") {
            return alert("Please provide number to call!");
        }
        if (!this.isValidNumber(this.state.dialNumber)) {
            return alert("Error your number is not valid! 10 digit required")
        }

        console.log("Congrats! you can now make call > ", this.state);

        let destination = this.state.dialNumber;
        //destination = "0" + destination.substring(destination.length - 9);
        //destination = destination.substring(destination.length - 9);

        this.props.PbxSetOutCaller({
            phone: destination
        });
        this.props.PbxOutCallDo({
            is: true
        })


    }
    stop = () => {
        doHangup();
        this.props.PbxOutCallEnd({is: true})
    }
    registerCloudCalls = () => {
        /**
         * Let now register to our pbx to make and receive call
         * */

        CloudCall({
            host: this.props.pbx.Server.host,
            extension: this.props.pbx.Extension.username,
            secret: this.props.pbx.Extension.secret,
            AlertBox: this.AlertBox,
            port: this.props.pbx.Server.port,
            displayname: "Agent " + this.props.pbx.Extension.username,
            receivedIncomingCall: this.receivedIncomingCall,
            hangupIncomingCall: this.hangupIncomingCall,
            answerIncomingCall:this.answerIncomingCall,
            onRegistry:this.onRegistry,
            onUnregistered:this.onUnregistered,
            outboundCallStatus:this.outboundCallStatus
        });
        return null
    }
    outboundCallStatus=(status)=>{
        this.props.PbxCallingStatus({status:status});
    }
    onIncomingCall = (result) => {
        console.log("onIncomingCall ZXXXXX---> ", result)
        alert(" onIncomingCall > " + JSON.stringify(result))
    }
    isValidNumber = (num) => {

        return true
    }
    onRegistry=(username)=>{
        this.props.PbxSetRegistry({status:"registered",peer:username})
    }
    onUnregistered=(username)=>{
        this.props.PbxSetRegistry({status:"unregistered",peer:username})
    }
    renderOutCallBox = () => {
        if (!this.props.pbx.OutCall.dialing) {
            return null
        }
        //todo make call logic here

        return (
            <div style={styles.outCall}>
                <OuCall
                    {...this.props}
                    style={{float: "right"}}
                    duration={this.state.duration}
                    handleInput={this.handleInput}
                    dial={this.dial}
                    stop={this.stop}
                    sendDtmf={sendDtmf}
                />
            </div>
        )
    }
    renderInCallBox = () => {
        if (!this.props.pbx.InCall.dialing) {
            return null
        }
        //todo make call logic here

        return (
            <div style={styles.inCall}>
                <InCall
                    {...this.props}
                    style={{float: "right", borderWidth: this.state.mySize,}}
                    duration={this.state.duration}
                    answerIncomingCall={answer}
                    hangupIncomingCall={doHangup}
                    inCall={this.props.pbx.InCall}
                />
            </div>
        )
    }

    receivedIncomingCall = (event, msg, result, jsep) => {
        let caller = msg.result.username.split('@')[0].split(':')[1];
        let server = msg.result.username.split('@')[1];
        let callId = msg.call_id;
        let incomeCall = {
            caller: caller,
            server: server,
            callId: callId
        };
        this.props.PbxInCallRequest({...incomeCall});
        console.log("receivedIncomingCall (&&)==> ", incomeCall)
    }
    hangupIncomingCall = (event, msg, result, jsep) => {

        let callId = msg.call_id;
        let code = msg.result.code;
        let reason = msg.result.reason;
        let requestHangup = {
            callId: callId,
            code: code,
            reason: reason
        }
        this.props.PbxInCallHangup({...requestHangup})
        console.log("hangupIncomingCall (&&)==> ", requestHangup);
    }

    answerIncomingCall = (event, msg) => {
        console.log("answerIncomingCall (&&)==> ", msg);
        this.props.PbxInCallAnswer({callId:msg.call_id})
    }

    render() {
        return (
            <SocketContext.Provider value={{
                prices: this.state.prices
            }}>
                <Fragment>
                    {this.registerCloudCalls()}
                    {this.props.children}
                    {this.renderOutCallBox()}
                    {this.renderInCallBox()}

                    <video
                        className="rounded centered"
                        id="video-device"
                        width={320}
                        height={240}
                        autoPlay
                        playsinline
                        muted="muted"
                    />
                    <audio
                        id={"audio-device"}
                    />
                    <audio
                        className="rounded centered"
                        id="roomaudio"
                        width="100%"
                        height="100%"
                        autoPlay
                    />

                </Fragment>
            </SocketContext.Provider>
        );
    }

}


export default Connect(WrappedSocketManager);

const styles = {
    outCall: {
        position: "fixed",
        bottom: 0,
        left: 10,
        minWidth: "30%",
        minHeight: "25%",
        maxWidth: "40%",
        borderWidth: 5,
        borderColor: "#009688",
        borderStyle: "solid",
        shadowColor: "red",
        shadowOpacity: 0.8,
        shadowRadius: 2,
        zIndex: 110,
        boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
        transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
        shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
        textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
        backgroundColor: "white"
    },
    inCall: {
        position: "fixed",
        bottom: 0,
        right: 0,
        minWidth: "5%",
        maxHeight: "60%",
        maxWidth: "20%",

        borderColor: "red",
        borderStyle: "solid",
        shadowColor: "red",
        shadowOpacity: 0.8,
        shadowRadius: 2,
        zIndex: 110,
        boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
        transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
        shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
        textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
        backgroundColor: "white"
    }
}
