

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import CardIcon from "./Card/CardIcon.js";
import Icon from "@material-ui/core/Icon";
import CardHeader from "./Card/CardHeader";
import Button from '@material-ui/core/Button';
import HelpBox from "./HelpBox/MyBox"

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        maxWidth:"100%",
        minHeight: 250,

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        /*backgroundColor:"#eeeeee",*/
        marginTop:15,
        marginBottom:15,


    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    }
}));

export default ({data})=> {
    const classes = useStyles();
    const theme = useTheme();
    data=[
        {title:"I am facing an issue",btnText:"Report an Issue",icon:"bug_report",color:"red"},
        {title:"I need an new Service",btnText:"Request Service",icon:"add_box",color:"green"},
        {title:"I am looking for Solution",btnText:"View Solution",icon:"emoji_objects",color:"gray"},
    ]

    return(
        <div style={{
            paddingLeft:15,
            paddingRight:-10,
            minWidth:"99%",
            maxWidth:"99%",
            backgroundColor:"#eeeeee",
            flex:1,
            justifyContent:"center",
            alignItems:"center",
            marginRight:10,
        }}>
        <GridContainer style={{backgroundColor:"#eeeeee",maxWidth: "100%"}}>
            {data.map((row)=>{
                return(
                    <GridItem xs={12} sm={6} md={4} alignItems="center">
                    <HelpBox data={row}/>
                    </GridItem>
                )
            })}

        </GridContainer>
        </div>
    )

}
