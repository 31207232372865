import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";

class SettingDealerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Recordings: [],
            searchKey: "",
            searchOrg: "",
        }

    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});



    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchRecording = async () => {
        this.setState({
            Recordings: [],
        });
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }else{
            conds.push({
                type: "string",
                key: "org",
                val: this.state.searchOrg
            })
        }

        conds.push({
            type: "string",
            key: "Customer",
            val: this.state.searchKey
        });



        console.log("fetchRecording--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxRecording", conds, (data) => {
            console.log("fetchRecording :> ", data);
            _this.setState({
                Recordings: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitSearch = async (e) => {
        e.preventDefault();

        if(this.state.searchKey ===""){
            return alert("Please provide your search key!")
        }

        if (!this.isSupperUser()) {
            if(this.state.searchOrg ===""){
                return alert("Please provide your organization!")
            }
        }

        this.fetchRecording();

    }


    renderSearchForm= () => {
        const state = this.state;
        let _this = this;

        const getValue = (myKey) => {
            return _this.state[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp[myKey] = e.target.value;
            _this.setState(tmp);
        }

        const user=GetUserToken();

        return (
            <div className={"row"}>
                <form onSubmit={this.submitSearch}>
                {user.Role==="super"&&(
                    <div className={"col col-lg-5"}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Organization</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Enter Organization ..."}
                                onChange={(e) => setValue(e, "searchOrg")}
                                value={getValue("searchOrg")}
                                required={true}
                            />
                        </div>
                    </div>
                )}

                <div className={"col col-lg-5"}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Search key</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Enter search key ..."}
                            onChange={(e) => setValue(e, "searchKey")}
                            value={getValue("searchKey")}
                            required={true}
                        />
                    </div>
                </div>
                <div className={"col col-lg-2"}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">&nbsp;</label>
                        <br/>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Search
                        </button>
                    </div>
                </div>



                </form>
            </div>
        )
    }
    renderRangeList = () => {

        const state = this.state;
        if (state.Recordings.length ===0) {
            return null
        }

        let ls = this.state.Recordings;



        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Customer</th>
                    <th>Extension</th>
                    <th>RecDate</th>
                    <th>RecTime</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let dateString = moment.unix(row.channelid).format("MM/DD/YYYY");
                    let timeString = moment.unix(row.channelid).format("HH:MM:SS");
                    return (
                        <tr key={index}>
                            <th>{row.org}</th>
                            <th>{row.customer}</th>
                            <th>{row.extension}</th>
                            <th>{dateString}</th>
                            <th>{timeString}</th>
                            <th>

                                <audio controls>
                                    <source src={row.downloadlink} type="audio/wav" />
                                            Your browser does not support the audio element.
                                </audio>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Recording Search
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderSearchForm()}
                    </div>
                </div>
                <div className="row container">
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderRangeList()}
                    </div>
                </div>


            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingDealerComponent)