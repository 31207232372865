import React,{Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

export default function Chips({pageTitle}) {
    let selectedModule= localStorage.getItem("@cloudcalls-module")||"";
    let selectedMenu =localStorage.getItem("@cloudcalls-menu")||"";

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    return (
        <Fragment>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit"  onClick={handleClick} style={{fontSize:16,fontWeight:"bold"}}>
                    {selectedModule.toLocaleUpperCase()}
                </Link>

                <Link color="inherit"  onClick={handleClick}  style={{color:"blue",fontSize:18,fontWeight:"bold"}}>
                    {selectedMenu.toLocaleUpperCase()}
                </Link>
                <Typography color="textPrimary"  variant="h4">{pageTitle}</Typography>
            </Breadcrumbs>
            <Divider />
        </Fragment>
    );
}
