import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";
import React, {Component} from "react";
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import PageContentWallpaper from "../common/ef-pageContent";


export default class ComLeadNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Campaigns: [],
            fCampaign: "",
            fCategory:"",
            fName: "",
            fValue: "",
            newList: {}
        }
    }

    async componentDidMount() {
        await this.fetchCampaign();
    }

    fetchCampaign = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "campaign";
        const conds = [];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchCampaign response > ", data);
            _this.setState({
                Campaigns: data
            })
        });
    }

    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    submitNewLead = async () => {
        if(this.state.fCampaign ===""){
            return alert("Please select your target campaign!")
        }
        if(this.state.newList.length ===0){
            return alert("Please provide your fields!")
        }

        const user = GetUserToken()

        if (!window.confirm("Are you sure that you want to submit this new lead?")) {
            return
        }

        let hub={};
        hub.Org = user.OrgCode;
        hub.Author =user.Username;// "biangacila@gmail.com"
        hub.AuthorName =user.Fullname;// "MErveilleux Biangacila"
        hub.Campaign =this.state.fCampaign;// "Easicare"
        hub.Entries = [];
        for(let i in this.state.newList){
            const row = this.state.newList[i];
            hub.Entries.push({
                Author:user.Username,
                Category:row.category,
                FieldName:row.fieldName,
                FieldValue:row.fieldValue,
            })
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/referral/new";

        console.log("submitNewLead send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewLead response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you new status change  added!");
                window.location.href="#/lead-worker"
            }
        });



    }
    addNewLead = (e) => {
        e.preventDefault();
        const o = {
            category: this.state.fCategory,
            fieldName: this.state.fName,
            fieldValue: this.state.fValue,
        }
        let tmp = this.state;

        tmp.fCategory = "";
        tmp.fName = "";
        tmp.fValue = "";
        tmp.newList[o.fieldName] = o;

        this.setState(tmp);
    }
    removeField=(key)=>{
        let tmp = this.state;
        delete tmp.newList[key];
        this.setState(tmp);
    }

    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp);
            if (handleFunc !== null) {
                handleFunc();
            }

        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }

    renderList() {

        let ls=[];
        console.log("renderList > ",this.state.newList);
        for(let i in this.state.newList){
            const row =this.state.newList[i];
            ls.push(row)
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                    <th>Category</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr>
                            <td>{row.fieldName}</td>
                            <td>{row.fieldValue}</td>
                            <td>{row.category}</td>
                            <td><a className={"btn btn-default"} onClick={()=>this.removeField(row.fieldName)}>Remove</a> </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
        return

    }

    render() {

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }
        return (
            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>
                    <div className={"container"}>
                        <h2 className={"alert alert-danger"}>
                            New Lead &nbsp;&nbsp;&nbsp;
                            <select
                                required={true}
                                value={getNewFieldEntryValue("fCampaign")}
                                onChange={(e) => setNewFieldEntryValue(e, "fCampaign")}
                            >
                                <option value={""}>--select Campaign--</option>
                                {this.state.Campaigns.map((row,index)=>{
                                    return(
                                        <option key={index} value={row.name}>{row.name}</option>
                                    )
                                })}
                            </select>
                            <button onClick={()=>window.location.href="#"} className={"btn btn-danger pull-right"}>Cancel</button>
                        </h2>
                        <form onSubmit={this.addNewLead} className={"row"}>
                            <div className={"col col-xs-3"}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Category</label>
                                    <select
                                        className="form-control"
                                        required={true}
                                        value={getNewFieldEntryValue("fCategory")}
                                        onChange={(e) => setNewFieldEntryValue(e, "fCategory")}
                                    >
                                        <option value={""}>--select Category--</option>
                                        <option value={"contact"}>Contact(Phone)</option>
                                        <option value={"name"}>Name</option>
                                        <option value={"identity"}>Identity</option>
                                        <option value={"email"}>Email</option>
                                    </select>
                                </div>
                                                            </div>
                            <div className={"col col-xs-3"}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Field Name</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        placeholder={"Field name"}
                                        required={true}
                                        value={getNewFieldEntryValue("fName")}
                                        onChange={(e) => setNewFieldEntryValue(e, "fName")}
                                    />
                                </div>
                            </div>
                            <div className={"col col-xs-3"}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Field Value</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        placeholder={"Field Value"}
                                        required={true}
                                        value={getNewFieldEntryValue("fValue")}
                                        onChange={(e) => setNewFieldEntryValue(e, "fValue")}
                                    />
                                </div>
                            </div>
                            <div className={"col col-xs-3"}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">&nbsp;</label>
                                    <button
                                        type={"submit"}
                                        className="form-control btn-default"
                                    >ADD</button>
                                </div>
                            </div>
                        </form>
                        <div className={"row"}>
                            <div className={"col col-lg-12"}>
                                <hr/>
                                {this.renderList()}
                            </div>
                            <div className={"col col-xs-12"}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">&nbsp;</label>
                                    <button
                                        type={"button"}
                                        className="form-control btn-success pull-right"
                                        onClick={()=>this.submitNewLead()}
                                    >SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </MuiThemeProvider>
            </PageContentWallpaper>
        )

    }

}