import {SAVE_NAVIGATIONS} from '../constant';

const allRole = ["super", "admin", "capture", "enumerator"];
const adminRole = ["super", "admin"];


const initial = {
    currentModule: "lead",
    currentMenu: "Worker",
    currentSubmenu: "",
    menus: {
        call: [
            {name: "Home", hasMenu: false, link: "home", roles: adminRole, isDefault: false},
            {name: "Sip Setting", hasMenu: false, link: "webcall", roles: adminRole, isDefault: false},
            {name: "Queue Monitor", hasMenu: false, link: "queue", roles: adminRole, isDefault: true},
            /*{name: "Status", hasMenu: false, link: "call", roles: adminRole, isDefault: false},*/
        ],
        "lead": [
            {name: "Worker", hasMenu: false, link: "lead-worker", roles: adminRole, isDefault: true},
            {name: "Dashboard", hasMenu: false, link: "lead-dashboard", roles: adminRole, isDefault: false},
            {name: "Search", hasMenu: false, link: "lead-search", roles: adminRole, isDefault: false},
        ],
        pbx: [
            {name: "Range", hasMenu: false, link: "pbx-range", roles: adminRole, isDefault: true},
            {name: "Trunk", hasMenu: false, link: "pbx-trunk", roles: adminRole, isDefault: false},
            {name: "Agent", hasMenu: false, link: "pbx-agent", roles: adminRole, isDefault: false},
            {name: "Queue", hasMenu: false, link: "pbx-queue", roles: adminRole, isDefault: false},
            {name: "Inbound-Number", hasMenu: false, link: "pbx-register", roles: adminRole, isDefault: false},
            {name: "CDR", hasMenu: false, link: "pbx-cdr", roles: adminRole, isDefault: false},
        ],
        "field": [
            {name: "Campaign", hasMenu: false, link: "field-campaign", roles: adminRole, isDefault: true},
            {
                name: "Describe Customer",
                hasMenu: false,
                link: "field-customer-description",
                roles: adminRole,
                isDefault: false
            },
            {
                name: "Describe Agent",
                hasMenu: false,
                link: "field-agent-description",
                roles: adminRole,
                isDefault: false
            },
            {name: "Location", hasMenu: false, link: "field-location", roles: adminRole, isDefault: false},
            {name: "Database Agent", hasMenu: false, link: "field-subscriber", roles: adminRole, isDefault: false},
            {name: "Assign-Location", hasMenu: false, link: "field-assign-location", roles: adminRole, isDefault: false},
            {name: "Report", hasMenu: false, link: "field-customer-report", roles: adminRole, isDefault: false},
        ],
        crm: [
            {name: "Util", hasMenu: false, link: "enquiry-util", roles: adminRole, isDefault: true},
            {name: "Type of People", hasMenu: false, link: "enquiry-role", roles: adminRole, isDefault: false},
            {name: "Attribute People", hasMenu: false, link: "enquiry-attribute-people", roles: adminRole, isDefault: false},
            {name: "Attribute Service", hasMenu: false, link: "enquiry-attribute-offering", roles: adminRole, isDefault: false},
            {name: "Peoples", hasMenu: false, link: "enquiry-database-people", roles: adminRole, isDefault: false},
            {name: "Services", hasMenu: false, link: "enquiry-database-offering", roles: adminRole, isDefault: false},
            /*{name: "Requests", hasMenu: false, link: "enquiry-request", roles: adminRole, isDefault: false},
            {name: "Tickets", hasMenu: false, link: "enquiry-tickets", roles: adminRole, isDefault: false},*/
            {name: "New Ticket", hasMenu: false, link: "enquiry-ticket-new", roles: adminRole, isDefault: false},
            /*{name: "Dashboard", hasMenu: false, link: "enquiry-dashboard", roles: adminRole, isDefault: false},*/
            {name: "Solutions(F.A.Q)", hasMenu: false, link: "enquiry-grouping", roles: adminRole, isDefault: false},
        ],
        "setting-lead": [
            {name: "Campaign", hasMenu: false, link: "setting-campaign", roles: adminRole, isDefault: true},
            {name: "Product", hasMenu: false, link: "setting-product", roles: adminRole, isDefault: false},
            {name: "Disposition", hasMenu: false, link: "setting-disposition", roles: adminRole, isDefault: false},
            {name: "Batch", hasMenu: false, link: "setting-batch", roles: adminRole, isDefault: false},
            {name: "Pool", hasMenu: false, link: "setting-pool", roles: adminRole, isDefault: false},
            {name: "Agent", hasMenu: false, link: "setting-agent", roles: adminRole, isDefault: false},
            {name: "allocation", hasMenu: false, link: "setting-allocation", roles: adminRole, isDefault: false},
            {name: "file-type", hasMenu: false, link: "setting-file-type", roles: adminRole, isDefault: false},
            {name: "CSV-Upload-New", hasMenu: false, link: "upload-new", roles: adminRole, isDefault: false},
            {name: "CSV-Upload-History", hasMenu: false, link: "upload-history", roles: adminRole, isDefault: false},
        ]
    }
}

const initial2 = {
    home: {
        desc: "Home", data: [
            {menu: "home", display: "Home"},
        ]
    },
    lead: {
        desc: "Lead",
        data: [
            {menu: "lead-worker", display: "Worker"},
            {menu: "lead-dashboard", display: "Dashboard"},
            {menu: "lead-search", display: "Search"},
        ]
    },
    Flow: {
        desc: "Timeline",
        data: [
            {menu: "flow-customer-new", display: "Customer-New"},
            {menu: "flow-customer-list", display: "Customer-List"},
            {menu: "flow-customer-step", display: "Customer-Step"},
        ]
    },
    PBX: {
        desc: "PBX",
        data: [
            {menu: "pbx-range", display: "Range"},
            {menu: "pbx-trunk", display: "Trunk"},
            {menu: "pbx-agent", display: "Agent"},
            {menu: "pbx-queue", display: "Queue"},
            {menu: "pbx-register", display: "Inbound-No"},
            {menu: "pbx-recording", display: "Recording"},
            {menu: "pbx-cdr", display: "CDR"},
        ]
    },
    FIELD: {
        desc: "FIELD",
        data: [
            {menu: "field-campaign", display: "Campaign"},
            {menu: "field-customer-description", display: "Customer-Description"},
            {menu: "field-location", display: "Location"},
            {menu: "field-agent-description", display: "Agent-Description"},
            {menu: "field-subscriber", display: "Agent-Database"},
            {menu: "field-assign-location", display: "Assign-Location"},
            {menu: "field-customer-report", display: "Report"},

        ]
    },
    leadSetting: {
        desc: "l-Setting",
        data: [
            {menu: "setting-campaign", display: "Campaign"},
            {menu: "setting-product", display: "Product"},
            {menu: "setting-disposition", display: "Disposition"},
            {menu: "setting-batch", display: "Batch"},
            {menu: "setting-pool", display: "Pool"},
            {menu: "setting-agent", display: "Agent"},
            {menu: "setting-allocation", display: "Allocation"},
            {menu: "setting-file-type", display: "File-Type"},
            {menu: "upload-new", display: "CSV-Upload-New"},
            {menu: "upload-history", display: "CSV-Upload-History"},
        ]
    },
    flowSetting: {
        desc: "f-Setting",
        data: [
            {menu: "setting-flow-process", display: "Process"},
            {menu: "setting-flow-step", display: "Step"},
        ]
    },

}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SAVE_NAVIGATIONS:
            newState = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;
