import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Paper} from "@material-ui/core";
import Connector from "../../reducers/connect/reducer-connector";
import {GetUserToken} from "../../api/fetching-data";
import LogoImg from "../../asset/img/logo.png";

 class MenuModule extends Component{

    onPressTab=(e)=>{
        console.log("onPressTab > ",e)
        this.setModuleMenu(e,"","");
    }
     setModuleMenu = (module, menu, submenu) => {
         menu="";
         let link = "";
         for(let i in this.props.navigations.menus[module]){
             const row=this.props.navigations.menus[module][i];
             if(row.isDefault){
                 menu = row.name;
                 link = row.link;
             }
         }

         this.props.dispatch({
             type: "SAVE_MODULE_MENU",
             payload: {
                 module: module,
                 menu: menu,
                 submenu
             }
         });

         localStorage.setItem("@cloudcalls-module",module);
         localStorage.setItem("@cloudcalls-menu",menu);
         localStorage.setItem("@cloudcalls-submenu",submenu);

         window.location.href = "#/"+link;
     }
     getLoginName=()=>{
         let user = this.props.login.User;
         if(user ===null){
             user = GetUserToken();
         }
         return {
             name:user.Surname[0].toLocaleUpperCase()+ ". "+user.Name,
             company:user.OrgCode,
             role:user.Role,
         };
     }

    render(){
        let lsModules=[
            /*"booking2","trip","schedule","chauffeur","transfer","rental"*/
        ];
        for(let i in this.props.navigations.menus){
            const menu = i;
            lsModules.push(menu);
        }


        return(
            <Fragment>
               <FullWidthTabs
                   onPressTab={this.onPressTab}
                   props={this.props}
                   lsModules={lsModules}
               />
            </Fragment>
        )
    }

}

export default Connector(MenuModule);



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {

    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,

        justifyContent:"center",
        alignItems:"center",
        width: "100%"
    },
}));

function FullWidthTabs({lsModules,props,onPressTab}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab
                        label={(<img src={LogoImg}  alt={"Logo Company"} style={{maxHeight:35}}/>)}

                    />
                    {lsModules.map((item,index)=>{
                        return(
                            <Tab
                                onClick={()=>onPressTab(item)}
                                label={item}
                                style={{fontSize:16,fontWeight:"bold"}}
                                {...a11yProps(index)}
                            />
                        )
                    })}
                </Tabs>

        </Paper>
    );
}

