import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router';
import createHistory from 'history/createHashHistory';

import {Provider} from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import logger from 'redux-logger'
import rootReducer from './reducers';
import {ticketDataMiddleware} from "./reducers/services/data-ticket";

import loginScreen from './container/con-login';
import registerScreen from './container/con-register';
import pageNotFindScreen from './container/con-401';
import homeScreen from './container/con-home';

import settingCampaignScreen from './container/con-setting-campaign';
import settingProductScreen from './container/con-setting-product';
import settingDispositionScreen from './container/con-setting-disposition';
import settingBatchScreen from './container/con-setting-batch';
import settingPoolScreen from './container/con-setting-pool';
import settingWorkerScreen from './container/con-setting-worker';
import settingAllocationScreen from './container/con-setting-allocation';
import settingFileTypeScreen from './container/con-setting-file-type';

import uploadNewScreen from './container/con-upload-new';
import uploadHistoryScreen from './container/con-upload-history';

import ScreenLeadWorder from './container/con-lead-worker';
import ScreenLeadDashboard from './container/con-lead-dashboard';
import ScreenLeadSearch from './container/con-lead-search';
import ScreenLeadNew from './container/con-lead-new';

import ScreenSettingFlowProcess from './container/con-setting-flow-process';
import ScreenSettingFlowStep from './container/con-setting-flow-step';

import ScreenFlowCustomerList from './container/con-flow-customer-list';
import ScreenFlowCustomerStep from './container/con-flow-customer-step';

import ScreenPbxRange from './container/con-pbx-range';
import ScreenPbxTrunk from './container/con-pbx-trunk';
import ScreenPbxAgent from './container/con-pbx-agent';
import ScreenPbxQueue from './container/con-pbx-queue';
import ScreenPbxInbound from './container/con-pbx-inbound';
import ScreenPbxRecording from './container/con-pbx-recording';
import ScreenPbxCdr from './container/con-pbx-cdr';

import ScreenFieldLocations  from './container/con-field-locations';
import ScreenFieldAgentDescription from "./container/con-field-agent-description";
import ScreenFieldAgentAllocation from "./container/con-field-agent-allocation";
import ScreenFieldCampaign from "./container/con-field-campaign";
import ScreenFieldCustomerStructure from "./container/con-field-customer-description";
import ScreenFieldSubscriber  from './container/con-field-subscriber';
import ScreenFieldReport from './component/fields/com-field-report';

import TicketSetupUtil from "./pages/ticket/SetupUtilPage";
import TicketRequest from "./pages/ticket/RequestPage";
import TicketRole from "./pages/ticket/RolePage";
import AttributePeoplePage from "./pages/ticket/DescibePeoplePage";
import AttributeServicePage from "./pages/ticket/DescribeOfferingPage";
import DescribeProductPage from "./pages/ticket/DescribeProductPage";
import DatabasePeoplePage from "./pages/ticket/DatabasePeoplePage";
import DatabaseOfferingPage from "./pages/ticket/DatabaseOfferingPage";
import FilterPage from "./pages/ticket/FilterPage";
import UtilPage from "./pages/ticket/UtilPage";
import NewTicketPage from "./pages/ticket/NewTicketPage";

import WebCall from "./component/webcall/ClickToCall.js";
import QueueCall from "./component/webcall/queue";
import ControllerBox from "./component/webcall/ControllerBox";
import pdfBox from "./component/pdf/MyPdf";


const history = createHistory();
const store = createStore(
    rootReducer,
    applyMiddleware(logger,ticketDataMiddleware)
);

class App extends Component {
    render() {
        return (
            <Provider store={store}>
            <Router history={history}>
                <Switch>

                    <Route exact path="/" component={loginScreen}/>
                    <Route path="/login" component={loginScreen}/>
                    <Route path="/register" component={registerScreen}/>
                    <Route path="/home" component={homeScreen}/>

                    <Route path="/setting-campaign" component={settingCampaignScreen}/>
                    <Route path="/setting-product" component={settingProductScreen}/>
                    <Route path="/setting-disposition" component={settingDispositionScreen}/>
                    <Route path="/setting-batch" component={settingBatchScreen}/>
                    <Route path="/setting-pool" component={settingPoolScreen}/>
                    <Route path="/setting-agent" component={settingWorkerScreen}/>
                    <Route path="/setting-allocation" component={settingAllocationScreen}/>
                    <Route path="/setting-file-type" component={settingFileTypeScreen}/>
                    <Route path="/setting-flow-process" component={ScreenSettingFlowProcess}/>
                    <Route path="/setting-flow-step" component={ScreenSettingFlowStep}/>

                    <Route path="/upload-new" component={uploadNewScreen}/>
                    <Route path="/upload-history" component={uploadHistoryScreen}/>

                    <Route path="/lead-worker" component={ScreenLeadWorder}/>
                    <Route path="/lead-dashboard" component={ScreenLeadDashboard}/>
                    <Route path="/lead-search" component={ScreenLeadSearch}/>
                    <Route path="/lead-new" component={ScreenLeadNew}/>

                    <Route path="/flow-customer-list" component={ScreenFlowCustomerList}/>
                    <Route path="/flow-customer-step" component={ScreenFlowCustomerStep}/>

                    <Route path="/pbx-range" component={ScreenPbxRange}/>
                    <Route path="/pbx-trunk" component={ScreenPbxTrunk}/>
                    <Route path="/pbx-agent" component={ScreenPbxAgent}/>
                    <Route path="/pbx-queue" component={ScreenPbxQueue}/>
                    <Route path="/pbx-register" component={ScreenPbxInbound}/>
                    <Route path="/pbx-recording" component={ScreenPbxRecording}/>
                    <Route path="/pbx-cdr" component={ScreenPbxCdr}/>

                    <Route path="/field-location" component={ScreenFieldLocations}/>
                    <Route path="/field-agent-description" component={ScreenFieldAgentDescription}/>
                    <Route path="/field-assign-location" component={ScreenFieldAgentAllocation}/>
                    <Route path="/field-campaign" component={ScreenFieldCampaign}/>
                    <Route path="/field-customer-description" component={ScreenFieldCustomerStructure}/>
                    <Route path="/field-subscriber" component={ScreenFieldSubscriber}/>
                    <Route path="/field-customer-report" component={ScreenFieldReport}/>

                    <Route exact path="/enquiry-setup-util" component={TicketSetupUtil}/>
                    <Route exact path="/enquiry-request" component={TicketRequest}/>
                    <Route exact path="/enquiry-role" component={TicketRole}/>
                    <Route exact path="/enquiry-attribute-people" component={AttributePeoplePage}/>
                    <Route exact path="/enquiry-attribute-offering" component={AttributeServicePage}/>
                    <Route exact path="/enquiry-attribute-product" component={DescribeProductPage}/>
                    <Route exact path="/enquiry-database-people" component={DatabasePeoplePage}/>
                    <Route exact path="/enquiry-database-offering" component={DatabaseOfferingPage}/>
                    <Route exact path="/enquiry-grouping" component={FilterPage}/>
                    <Route exact path="/enquiry-util" component={UtilPage}/>
                    <Route exact path="/enquiry-ticket-new" component={NewTicketPage}/>

                    <Route exact path="/webcall" component={WebCall}/>
                    <Route exact path="/queue" component={QueueCall}/>
                    <Route exact path="/call" component={ControllerBox}/>
                    <Route exact path="/pdf" component={pdfBox}/>


                    <Route component={pageNotFindScreen}/>
                </Switch>
            </Router>
            </Provider>

        );
    }
}

export default App;

/*
<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
 */
