

import React, {Component} from 'react';
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";




export default class ComPopupNewActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStep: "",
            selectedActivity: "",
            activityName: "",
            activityValue: "",
        }

    }

    closePopup=()=>{
        const keyName = this.props.popupControllerKey;
        const selectedObjectKey = this.props.popupSelectedKey; // selectedCustomer
        this.props.handleInput(keyName,false);
        this.props.handleInput(selectedObjectKey,null);
    }

    renderActivityInput=()=>{

        if(this.state.selectedActivity ===""){
            return
        }

        const arr=this.state.selectedActivity.split("--");
        const inputName = arr[0];
        const inputType=arr[1];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }

        return(
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{inputName.toUpperCase()}</label>
                <input
                    type={inputType}
                    className="form-control"
                    placeholder={"YYYY-MM-DD"}
                    required={true}
                    value={getNewFieldEntryValue("activityValue")}
                    onChange={(e) => setNewFieldEntryValue(e, "activityValue")}
                />

            </div>
        )

    }
    renderActivity=()=>{
        let step =null;
        for(let  i in this.props.state.Steps){
            const row = this.props.state.Steps[i];
            if(this.state.selectedStep ===row.id){
                step = row
            }
        }

        if(step===null){
            return(
                <p className={"alert alert-info"}>Activity not available</p>
            )
        }

        let ls=[];
        for(let key in step.requirements){
            const type =step.requirements[key];
            const val=key+"--"+type;
            ls.push({
                key:key,
                val:val
            })
        }

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }


        return(
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Which Activity?</label>
                <select
                    type={"date"}
                    className="form-control"
                    placeholder={"YYYY-MM-DD"}
                    required={true}
                    value={getNewFieldEntryValue("selectedActivity")}
                    onChange={(e) => setNewFieldEntryValue(e, "selectedActivity")}
                >
                    <option value={"--Select--"}>--Select--</option>
                    {ls.map((row,index)=>{
                        return(
                            <option key={index} value={row.val}>{row.key}</option>
                        )
                    })}
                </select>
            </div>
        )


    }
    renderStep=()=>{

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }


        return(
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Which Step?</label>
                <select
                    type={"date"}
                    className="form-control"
                    placeholder={"YYYY-MM-DD"}
                    required={true}
                    value={getNewFieldEntryValue("selectedStep")}
                    onChange={(e) => setNewFieldEntryValue(e, "selectedStep")}
                >
                    <option value={"--Select--"}>--Select--</option>
                    {this.props.state.Steps.map((row,index)=>{
                        return(
                            <option value={row.id}>{row.position+". "+row.name}</option>
                        )
                    })}
                </select>
            </div>
        )
    }

    render() {

        if (!this.props.popupController) {
            return null
        }

        /*
        Steps:[],
            CustomerSteps:[],
         */

        console.log(":)-->Popup ",this.props.state.Steps);

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closePopup}
            />,
        ];


        const processName  = this.props.selectedCustomer.ProcessName;


        return (
            <MuiThemeProvider>
                <Dialog
                    title={this.props.popupTitle}
                    actions={actions}
                    modal={false}
                    open={this.props.popupController}
                    onRequestClose={this.closePopup}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                       <h2>Demo popup</h2>
                    </div>

                    <form>
                        {this.renderStep()}
                        {this.renderActivity()}
                        {this.renderActivityInput()}
                    </form>

                </Dialog>

            </MuiThemeProvider>


        )

    }

}