import FormEdit from "../../common/form-input-control/FormEdit";
import React,{Fragment} from "react";
import Typography from '@material-ui/core/Typography';
import ComboBox from "./Combobox";


export default ({
                    onHandleInputFormEditText,
                    onHandleInputFormEditRadio,
                    onHandleInputFormEditCheck,
                    submitEntry,
                    onCancel,
                    databases,
                    roles,
                    handelInput,
                    state
                }) => {

    const keyNameOriginSource="originSource";
    const keyNameOriginPerson="originPerson";

    let optionOrigin=[];
    for(let i in roles){
        let row=roles[i];
        optionOrigin.push({
            val:row.name,
            key:row.name
        })
    }
    let optionPeoples = [];

    for(let i in databases){
        const row =databases[i];
        if(row.module ===state.newEntry[keyNameOriginSource]){
            optionPeoples.push({
                val:row.names,
                key:row.ref
            })
        }
    }

    const handelInput2=(e,val)=>{
        handelInput(val,keyNameOriginSource)
    }
    const handelInput3=(e,val)=>{
        handelInput(val,keyNameOriginPerson)
    }

    return (
        <Fragment>
            <h4>Ticket Origin</h4>
            <Typography paragraph>
                Please select where your ticket is coming from to load your target  people on the next step
            </Typography>
            <div style={{padding:20,minWidth:"100%"}}>
                <ComboBox
                    data={optionOrigin}
                    handelInput={handelInput2}
                    label={"Choose Origin or source of ticket"}
                />
                <br/>
                <ComboBox
                    data={optionPeoples}
                    handelInput={handelInput3}
                    label={"Choose Person"}
                />
            {/*<FormEdit
                formType={"outline"}
                formTitle={"New Ticket "}
                data={listField}
                onChangeText={onHandleInputFormEditText}
                onChangeRadio={onHandleInputFormEditRadio}
                onChangeCheck={onHandleInputFormEditCheck}
                onSubmit={submitEntry}
                onCancel={onCancel}
                showTitle={false}
            />*/}
            </div>
        </Fragment>

    )
}
