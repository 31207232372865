import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import React, {Component} from "react";
import Wallpaper from "../common/ef-wallper";
import ReconnectingWebSocket from "reconnecting-websocket";
import QueueView from "./queueWaiting";
import GridItem from "../common/dashboard/Grid/GridItem";
import GridContainer from "../common/dashboard/Grid/GridContainer";
import DashBar from "../common/dashboard/DashBar";
import {ColumnChart, LineChart, PieChart} from 'react-chartkick';
import 'chart.js';

const serverUrl = "wss://cloudcalls.easipath.com/ws";
const websocketUrl="wss://cloudcalls.easipath.com/ws";

class QueueEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Events: null,
            /*totalWait:0,
            totalTalk:0,
            totalComplete:0,
            totalAbandon:0,*/
            selectedRecord: null,
            selectedData: [],
        }
        this.socket = null;
        this.ws = null;
    }

    componentDidMount = async () => {
        await this.conn();
    }
    conn = async () => {
        let self = this;
        this.ws = new WebSocket(websocketUrl);
        this.ws.addEventListener('message', function (e) {
            let msg = JSON.parse(e.data);
            self.setState({
                Events: msg
            })
            console.log("onMessage Socket ^^^> ", msg);
        });
    }
    componentWillUnmount = async () => {

        try {
            this.socket !== null && this.socket.disconnect();
        } catch (e) {
            // socket not connected
        }
    }
    handleInputSelectData = (record, data) => {
        this.setState({
            selectedRecord: null,
            selectedData: data,
        })
    }
    closeSelectData = () => {
        this.setState({
            selectedRecord: null,
            selectedData: null,
        })
    }
    connection = async () => {
        const uri = serverUrl;
        const urls = [uri];
        let urlIndex = 0;
        const options = {
            WebSocket: undefined,
            maxReconnectionDelay: 10000,
            minReconnectionDelay: 1000 + Math.random() * 4000,
            reconnectionDelayGrowFactor: 1.3,
            minUptime: 5000,
            connectionTimeout: 4000,
            maxRetries: Infinity,
            debug: false,
        };
        // round robin url provider
        const urlProvider = () => urls[urlIndex++ % urls.length];
        console.log("urlProvider @@@@@@--->?-> ",urlProvider)
        this.socket = new ReconnectingWebSocket(urlProvider, [], options);
        this.socket.onopen = () => {
            console.log("####---> ", 'Hello Mr. Server! monitor socket HODER BY DRIVER' + uri);
            const title = "WS_CONNCABS_CONNECTED";
            const data = this.state.info;
            try {
                this.socket.send(JSON.stringify({type: title, Type: title, Payload: data}));
            } catch (e) {
                console.log("WS_CONNCABS_CONNECTED ERROR sendMessageSocket > ", e, title, data)
            }
        };
        this.socket.onmessage = ({data}) => {
            let message = JSON.parse(data);
            console.log("onMessage Socket ^^^> ", data);

        }
    }
    queueData = () => {
        const state = this.state.Events;
        let ls = [];
        if (state === null) {
            return ls
        }
        for (let i in state.Events) {
            const row = state.Events[i];
            ls.push(row)
        }
        return ls;
    }
    renderQueueWaiting = () => {
        if (this.queueData().length === 0) {
            return null
        }
        let action = "ENTERQUEUE";
        let ls = []
        const disc = this.state.Events.Events;
        for (let i in disc) {
            const row = disc[i];
            if (row.Action !== action) {
                continue
            }
            ls.push({
                From: row.Caller,
                Inbound: row.Inbound,
                WaitFor: row.AbandonPeriodText,
                Start: row.ActionEnterTime,
                End: row.ActionAbandonTime,
                Org: row.Org
            })
        }

        return (
            <QueueView
                data={ls}
                color={"red"}
                title={"Waiting"}
            />
        )
    }
    renderQueueConnect = () => {
        if (this.queueData().length === 0) {
            return null
        }
        let action = "CONNECT";
        let ls = []
        const disc = this.state.Events.Events;
        for (let i in disc) {
            const row = disc[i];
            if (row.Action !== action) {
                continue
            }
            ls.push({
                From: row.Caller,
                Inbound: row.Inbound,
                WaitFor: row.AbandonPeriodText,
                Start: row.ActionEnterTime,
                End: row.ActionAbandonTime,
                Org: row.Org
            })
        }

        return (
            <QueueView
                data={ls}
                color={"red"}
                title={"Talking"}
            />
        )

        //
    }
    renderQueueComplete = () => {
        if (this.queueData().length === 0) {
            return null
        }
        //todo

        let ls = []
        const disc = this.state.Events.Events;
        for (let i in disc) {
            const row = disc[i];
            if (row.ExitBy === "") {
                continue
            }
            ls.push({
                From: row.Caller,
                Inbound: row.Inbound,
                Agent: row.Extension,
                Duration: row.TalkingPeriodText,
                WaitFor: row.WaitingPeriodText,
                Start: row.ActionConnectTime,
                End: row.ActionCompleteTime,
                Org: row.Org
            })
        }

        return (
            <QueueView
                data={ls}
                color={"blue"}
                title={"Completed Calls"}
            />
        )

    }
    renderQueueAbandon = () => {
        if (this.queueData().length === 0) {
            return null
        }
        //todo
        let action = "ABANDON";
        let ls = []
        const disc = this.state.Events.Events;
        for (let i in disc) {
            const row = disc[i];
            if (row.Action !== action) {
                continue
            }
            ls.push({
                From: row.Caller,
                Inbound: row.Inbound,
                WaitFor: row.AbandonPeriodText,
                Start: row.ActionEnterTime,
                End: row.ActionAbandonTime,
                Org: row.Org
            })
        }

        return (
            <QueueView
                data={ls}
                color={"red"}
                title={"Abandon"}
            />
        )

    }
    getDashBarData = () => {

        let data = [
            {
                amount: "49",
                title: "GB",
                topTitle: "Used space",
                footerTitle: "Get more space",
                color: "warning",
                icon: "content_copy"
            },
            {
                amount: "$789,456",
                title: "Sale",
                topTitle: "Daily Sale",
                footerTitle: "Date 2020-01-04",
                color: "danger",
                icon: "info_outline"
            },
            {
                amount: "49",
                title: "GB",
                topTitle: "Used space",
                footerTitle: "Get more space",
                color: "warning",
                icon: "content_copy"
            },
            {
                amount: "$789,456",
                title: "Sale",
                topTitle: "Daily Sale",
                footerTitle: "Date 2020-01-04",
                color: "primary",
                icon: "info_outline"
            },
        ];
        let totalWait = 0;
        let totalTalk = 0;
        let totalComplete = 0;
        let totalAbandon = 0;
        let dataWait = [];
        let dataComplete = [];
        let dataAbandon = [];
        let dataTalk = [];
        const store = this.queueData();
        for (let i in store) {
            const row = store[i];
            if (row.Action === "ABANDON") {
                totalAbandon++;
                dataAbandon.push(row);
            }
            if (row.ExitBy !== "") {
                totalComplete++;
                dataComplete.push(row);
            }
            if (row.Action === "CONNECT") {
                totalTalk++;
                dataTalk.push(row);
            }
            if (row.Action === "ENTERQUEUE") {
                totalWait++;
                dataWait.push(row);
            }
        }

        return [
            {
                amount: totalWait,
                title: "On Queue",
                topTitle: "Waiting for agent",
                footerTitle: "",
                color: "warning",
                icon: "content_copy",
                data: dataWait
            },
            {
                amount: totalTalk,
                title: "Connect",
                topTitle: "Talking",
                footerTitle: "",
                color: "success",
                icon: "info_outline",
                data: dataTalk
            },
            {
                amount: totalComplete,
                title: "Success",
                topTitle: "Completed",
                footerTitle: "",
                color: "primary",
                icon: "content_copy",
                data: dataComplete
            },
            {
                amount: totalAbandon,
                title: "Abandon",
                topTitle: "left queue",
                footerTitle: "",
                color: "danger",
                icon: "info_outline",
                data: dataAbandon
            },
        ];
    }
    renderChart1 = () => {

        let totalRecord = 0;
        let totalComplete = 0;
        let totalCompleteAgent = 0;
        let totalCompleteCaller = 0;
        let totalAbandon = 0;

        const store = this.queueData();
        for (let i in store) {
            const row = store[i];
            if (row.Action === "ABANDON") {
                totalAbandon++;
                totalRecord++;
            }
            if (row.ExitBy !== "") {
                totalComplete++;
                totalRecord++;
            }
            if (row.Action === "COMPLETEAGENT") {
                totalCompleteAgent++;
            }
            if (row.Action === "COMPLETECALLER") {
                totalCompleteCaller++;
            }

        }


        let ls = [
            ["Total Call", totalRecord],
            ["Complete Call", totalComplete],
            ["Abandon", totalAbandon],
            ["Exit by Agent", totalCompleteAgent],
            ["Exit by Caller", totalCompleteCaller]
        ];


        return (
            <div className="col col-xs-12 col-lg-8">
                <fieldset className="scheduler-border">
                    <legend className="scheduler-border">Performance</legend>
                    <LineChart data={ls}/>
                </fieldset>

            </div>
        )

    }
    renderChart2 = () => {

        let totalRecord = 0;
        let totalComplete = 0;
        let totalCompleteAgent = 0;
        let totalCompleteCaller = 0;
        let totalAbandon = 0;

        const store = this.queueData();
        for (let i in store) {
            const row = store[i];
            if (row.Action === "ABANDON") {
                totalAbandon++;
                totalRecord++;
            }
            if (row.ExitBy !== "") {
                totalComplete++;
                totalRecord++;
            }
            if (row.Action === "COMPLETEAGENT") {
                totalCompleteAgent++;
            }
            if (row.Action === "COMPLETECALLER") {
                totalCompleteCaller++;
            }

        }


        let ls = [
            ["Total Call", totalRecord],
            ["Complete Call", totalComplete],
            ["Abandon", totalAbandon],
            ["Exit by Agent", totalCompleteAgent],
            ["Exit by Caller", totalCompleteCaller]
        ];


        return (
            <div className="col col-xs-12 col-lg-8">
                <fieldset className="scheduler-border">
                    <legend className="scheduler-border">Performance</legend>
                    <ColumnChart data={ls}/>
                </fieldset>

            </div>
        )

    }
    renderChart3 = () => {

        let totalRecord = 0;
        let totalComplete = 0;
        let totalCompleteAgent = 0;
        let totalCompleteCaller = 0;
        let totalAbandon = 0;

        const store = this.queueData();
        for (let i in store) {
            const row = store[i];
            if (row.Action === "ABANDON") {
                totalAbandon++;
                totalRecord++;
            }
            if (row.ExitBy !== "") {
                totalComplete++;
                totalRecord++;
            }
            if (row.Action === "COMPLETEAGENT") {
                totalCompleteAgent++;
            }
            if (row.Action === "COMPLETECALLER") {
                totalCompleteCaller++;
            }

        }


        let ls = [
            ["Total Call", totalRecord],
            ["Complete Call", totalComplete],
            ["Abandon", totalAbandon],
            ["Exit by Agent", totalCompleteAgent],
            ["Exit by Caller", totalCompleteCaller]
        ];


        return (
            <div className="col col-xs-12 col-lg-8">
                <fieldset className="scheduler-border">
                    <legend className="scheduler-border">Performance</legend>
                    <PieChart data={ls}/>
                </fieldset>

            </div>
        )

    }


    render() {
        return (
            <div className={"container"}>


                <GridContainer style={{marginTop: 50}}>

                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        <DashBar
                            data={this.getDashBarData()}
                            handleInput={this.handleInputSelectData}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} alignItems="center">
                        {this.renderChart1()}
                    </GridItem>
                    <GridItem  xs={4} sm={4} md={4} alignItems="center">
                        {this.renderChart2()}
                    </GridItem>
                    <GridItem  xs={4} sm={4} md={4} alignItems="center">
                        {this.renderChart3()}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderQueueWaiting()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderQueueConnect()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderQueueComplete()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderQueueAbandon()}
                    </GridItem>

                </GridContainer>

            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConClickToCall extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <QueueEvent {...this.props}/>

            </Wallpaper>
        );
    }
})
