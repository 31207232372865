import React, {Fragment} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import SkipNextIcon from "@material-ui/core/SvgIcon/SvgIcon";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GridContainer from "../common/dashboard/Grid/GridContainer";
import GridItem from "../common/dashboard/Grid/GridItem";
import ImgCallLive from '../../asset/img/in-call.gif';

import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DoneIcon from '@material-ui/icons/Done';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import CallEndIcon from '@material-ui/icons/CallEnd';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import DialpadIcon from '@material-ui/icons/Dialpad';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import DetailsIcon from '@material-ui/icons/Details';
import pbx from "../../reducers/reducer/reducer-pbx";


//   https://material-ui.com/components/material-icons/


const useStyles = makeStyles(theme => ({
    card: {
        minWidth:"100%",
        maxWidth: "100%"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        minWidth: "100%",
        backgroundColor:"#ffebee",
        justifyContent:"center",
        borderRadius:5
    },
}));

export default  (props)=> {

    let {duration,answerIncomingCall,hangupIncomingCall,inCall}=props;


    const theme = useTheme();
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    let colorStatus="orange";
    if(inCall.status==="answer"){
        colorStatus = "green"
    }else if(inCall.status==="hangup"){
        colorStatus = "red"
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <GridContainer style={{marginTop: 5, minHeight: "100%", paddingTop: 15}}>
                    <GridItem xs={12} sm={8} md={8} alignItems="center" style={{minHeight: "100%"}}>
                        <Typography component="h4" variant="h4">
                            Income Call
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Status: (<span style={{color:colorStatus}}> {inCall.status}</span>)
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Caller: {inCall.caller}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Cust: {inCall.customer||"---"}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Org: {inCall.org||"---"}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                            Since: <span style={{color:"red"}}>{parseFloat(duration).toFixed(2)}&nbsp;m</span>
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} alignItems="center" style={{minHeight: "100%"}}>
                        <img src={ImgCallLive} style={{maxWidth:"100%",maxHeight:"100%"}} />
                    </GridItem>
                </GridContainer>

            </CardContent>
            <CardActions>
                <div className={classes.controls}>
                    <IconButton aria-label="previous">
                        <CallEndIcon
                            style={{color:"red",fontWeight:"bold",fontSize:34}}
                            onClick={()=>hangupIncomingCall()}
                        />
                    </IconButton>
                    <IconButton aria-label="play/pause">
                        <DetailsIcon style={{color:"blue",fontWeight:"bold",fontSize:34}}/>
                    </IconButton>
                    <IconButton aria-label="next">
                        <DialerSipIcon
                            style={{color:"green",fontWeight:"bold",fontSize:34}}
                            onClick={()=>answerIncomingCall()}
                        />
                    </IconButton>
                </div>
            </CardActions>
        </Card>
    );
}
