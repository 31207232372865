import React from "react";
import {FormControl, FormLabel, makeStyles } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor:"gray",
        borderRadius:3,
        borderWidth:1
    },
}));


export default ({row,onChangeCheck}) => {
    const classes = innerStyles();
    let initialOptions = {};
    for (let i in row.options) {
        initialOptions[i] = false;
    }
    const [state, setState] = React.useState(initialOptions);
    const handleChange = name => event => {
        setState({...state, [name]: event.target.checked});
        onChangeCheck(row,{key:name,val: event.target.checked})
    };

    return (
        <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin}>
            <FormLabel component="legend" className={"legend"}>{row.label}</FormLabel>
            <FormGroup row >
                {row.options.map((item) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state[item]}
                                    onChange={handleChange(item)}
                                    value={item}
                                />
                            }
                            label={item}
                        />
                    )
                })}

            </FormGroup>
        </FormControl>
    )
}
