import React, {Fragment} from 'react';
import {Header, MenuModule,MenuInfoBar} from "../../Layouts";
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import LeftSide from "../../common/page/LeftSide";
import Connector from "../../../reducers/connect/reducer-connector";
import  SocketManager from "../../websocket/SocketManager";

const styles={
    Paper:{
        padding:20,
        marginTop: 10,
        marginBottom:10,
        marginLeft:10,
        minHeight:"90%",
    }
}
class Wallpaper extends React.Component {

    render() {

        return (
            <SocketManager>
                <Header/>
                <MenuModule/>
                <Grid container sm={12} style={{marginTop:35,minHeight:"100%"}} >
                    <Grid item sm={2}>
                        <Paper style={styles.Paper}>
                            <LeftSide props={this.props} />
                        </Paper>
                    </Grid>
                    <Grid item sm={10}>
                        <Paper style={styles.Paper}>
                            <MenuInfoBar props={this.props} pageTitle={this.props.pageTitle}/>
                            <Fragment>
                                {this.props.children}
                            </Fragment>
                        </Paper>
                    </Grid>
                </Grid>
            </SocketManager>
        );
    }
}

export default Wallpaper;

