import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
const styles={
    Active:{
        color:"white",
        fontWeight:"bold",
        fontSize:16
    }
}
export default  ({props})=> {
    let selectedModule= localStorage.getItem("@cloudcalls-module")||"";
    let selectedMenu =localStorage.getItem("@cloudcalls-menu")||"";


    /*let selectedModule=props.navigations.currentModule;
    let selectedMenu = props.navigations.currentMenu;*/
    let ls=[];
    for(let i in props.navigations.menus[selectedModule]){
        const row=props.navigations.menus[selectedModule][i];
        if(row.isDefault && selectedMenu===""){
            selectedMenu=row.name;
            localStorage.setItem("@cloudcalls-module",selectedModule);
            localStorage.setItem("@cloudcalls-menu",selectedMenu);
            localStorage.setItem("@cloudcalls-submenu","");
            props.dispatch({
                type: "SAVE_MODULE_MENU",
                payload: {
                    module: selectedModule,
                    menu: selectedMenu,
                    submenu:""
                }
            });
            window.location.href = "#/"+row.link;
        }
        ls.push({key:row.name,link:row.link});
    }

    const onChooseMenu=(menu)=>{
        props.dispatch({
            type: "SAVE_MODULE_MENU",
            payload: {
                module: selectedModule,
                menu: menu.key,
                submenu:""
            }
        });
        localStorage.setItem("@cloudcalls-module",selectedModule);
        localStorage.setItem("@cloudcalls-menu",menu.key);
        localStorage.setItem("@cloudcalls-submenu","");
        window.location.href = "#/"+menu.link;
    }

    return (
            <MenuList style={{marginTop:-25 ,width:"123%",marginLeft:-15}}>
                <MenuItem style={{backgroundColor:"#0d47a1",textAlign:"center",alignItems:"center"}}>
                    <Typography variant="inherit" style={styles.Active}>Menu</Typography>
                </MenuItem>
                {ls.map((row,index)=>{
                    let selected=row.key ===selectedMenu;
                    return(
                        <MenuItem key={index} selected={selected} onClick={()=>onChooseMenu(row)}>
                            <ListItemIcon>
                                <SendIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit" style={{
                                fontSize:14,
                                fontWeight:"bold",
                                color:"gray"
                            }}>{row.key}</Typography>
                        </MenuItem>
                    )
                })}
            </MenuList>

    );
}
