import React, {Component,Fragment} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/agent-dashboard.css';
import PageContentWallpaper from '../common/ef-pageContent';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import moment from "moment";
import Connect from "../../reducers/connect/reducer-connector";
import Wallpaper from "../common/ef-wallper";



class ComLeadWorker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReferralActive: [],
            FileCategory: [],
            Lead: null,
            ListDash: null,
            Dispositions: [],
            CounterDisposition: {},
            DataDisposition: [],
            SelectedDispostion: "",
            newField: {
                author: "",
                category: "",
                fName: "",
                fValue: ""
            },
            openViewAct: false,
            viewActData: null,
            showReferralList: false,
            showNewFieldForm: false,
            showNewActEmail: false,
            showNewActComment: false,
            showNewActCall: false,
            showNewActSms: false,
            showNewActSchedule: false,
            showNewProcess: false,
            showDispostionData: false,
            newActComment: {
                Author: "",
                Title: "",
                Body: "",
                Date: "",
                Time: "",
            },
            newActCall: {
                Caller: "",
                Measure: "",
                Duration: "",//float s.v.p
                Outcome: "",
                Comment: "",
                DateTime: "",
            },
            newActEmail: {
                FromEmail: "",
                ToEmail: "",
                Subject: "",
                Body: "",
                DateTime: "",
            },
            newActSms: {
                Phone: "",
                Message: "",
                DateTime: "",
                Route: "",
                Cost: "",
            },
            newActSchedule: {
                Author: "",
                Category: "",
                Person: "",
                Id: "",
                Date: "",
                Time: "",
                Comment: "",
                Purpose: "",
            },

            newProcess: {
                Org: "",
                LeadRef: "",
                BatchName: "",
                Campaign: "",
                PoolName: "",
                Disposition: "",
                Worker: "",
                DateWorked: "",
                Comment: "",
                Title: "",
            },
            newUpload: {
                category: "",
                base64string: "",
                filename: "",
            },
            newDateRangeDash: {
                startDate: "",
                endDate: "",
            },
        }
    }

    async componentDidMount() {
        await this.loadLeadRequest();
        await this.fetchDisposition();
        await this.loadDispositionDashboard();
        await this.fetchFileType();
        await this.loadReferral()
    }

    fetchFileType = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            }
        ];
        const dbName = "cclms";
        await this.fetchingAll(dbName, "LeadFileType", conds, (data) => {
            console.log("process taken fetchFileType :> ", data, " > ", conds);
            _this.setState({
                FileCategory: data
            });
        });
    }
    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    fetchDisposition = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "disposition";
        let conds = [
            {key: "org", type: "string", val: user.OrgCode},
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchDisposition response > ", data);
            _this.setState({
                Dispositions: data
            })
        });
    }
    loadReferral = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Status = "active";
        hub.Worker = user.Username;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/referral/list";

        console.log("loadReferral send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadReferral response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    ReferralActive: data
                })
            }
        });
    }
    loadDispositionDashboard = async () => {
        const keyStore = "cclms-request-lead";
        const leadRef = window.localStorage.getItem(keyStore);
        console.log("myStoreKey > ", leadRef);
        if (leadRef !== null) {
            window.localStorage.removeItem(keyStore);
            let leadPool = "", leadBatch = "", leadCampaign = "";
            return this.loadLeadRequestFilter(leadRef, leadPool, leadBatch, leadCampaign)
        }
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Worker = user.Username;
        let myDate = moment().format("YYYY-MM-DD");
        hub.DateWorked = myDate;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/disposition/change/daily-worker-counter";

        console.log("loadDispositionDashboard send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadDispositionDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    CounterDisposition: data
                })
            }
        });

    }
    loadDispositionDashboard_Filter = async (disposition) => {

        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Worker = user.Username;
        let myDate = moment().format("YYYY-MM-DD");
        hub.DateWorked = myDate;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/disposition/change/daily-worker-data";

        if (this.state.newDateRangeDash.startDate !== "") {
            hub.startDate = this.state.newDateRangeDash.startDate;
            hub.endDate = this.state.newDateRangeDash.endDate;
            endpoint = "/api/lead/disposition/change/date-range-data";
        }

        console.log("loadDispositionDashboard_Filter send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {

            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                let ls = [];
                for (let i in data) {
                    const row = data[i];
                    if (row.Disposition === disposition) {
                        ls.push(row)
                    }
                }
                console.log("loadDispositionDashboard_Filter response > ", ls);
                _this.setState({
                    DataDisposition: ls
                })
            }
        });

    }
    loadLeadRequestFilter = async (leadRef, leadPool, leadBatch, leadCampaign) => {
        const user = GetUserToken();

        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Worker = user.Username;

        hub.PoolName = leadPool;
        hub.LeadCategory = "old";
        hub.LeadRef = leadRef;
        hub.LeadBatch = leadBatch;
        hub.LeadCampaign = leadCampaign;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/request/new";

        console.log("loadLeadRequest send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadLeadRequest response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                if (data.Msg === "") {
                    _this.setState({
                        Lead: data,
                    });
                    _this.closeShowViewDispData();
                } else {
                    alert("Fetching lead issue: " + data.Msg);
                }


            }
        });
    }
    loadLeadRequest = async () => {
        const user = GetUserToken();

        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Worker = user.Username;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/request/new";

        console.log("loadLeadRequest send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadLeadRequest response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                if (data.Msg === "") {
                    _this.setState({
                        Lead: data,
                    })
                } else {
                    alert("Fetching lead issue: " + data.Msg);
                }


            }
        });
    }
    workerBoardReferral=async (row)=>{
        this.loadLeadRequestFilter(
            row.LeadRef,
            row.PoolName,
            row.BatchName,
            row.Campaign)
        this.closeShowReferralList();
    }

    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    requestShowReferral = () => {
        let tmp = this.state;
        tmp.showReferralList = true;
        this.setState(tmp);
    }
    requestAddNewActSchedule = () => {
        const user = GetUserToken();
        let tmp = this.state;
        tmp.showNewActSchedule = true;
        tmp.newActSchedule = {
            Author: user.Username,
            Category: "",
            Person: "",
            Id: "",
            Date: "",
            Time: "",
            Comment: "",
            Purpose: "",
        };
        this.setState(tmp);
    }
    requestAddNewActSms = () => {
        let tmp = this.state;
        tmp.showNewActSms = true;
        tmp.newActSms = {
            Phone: "",
            Message: "",
            DateTime: "",
            Route: "",
            Cost: "",
        };
        this.setState(tmp);
    }
    requestAddNewActEmail = () => {
        let tmp = this.state;
        tmp.showNewActEmail = true;
        tmp.newActEmail = {
            FromEmail: "",
            ToEmail: "",
            Subject: "",
            Body: "",
            DateTime: "",
        };
        this.setState(tmp);
    }
    requestAddNewActComment = () => {
        const user = GetUserToken();
        let tmp = this.state;
        tmp.showNewActComment = true;
        tmp.newActComment = {
            author: user.Username,
            Title: "",
            Body: "",
            Date: "",
            Time: "",
        };
        this.setState(tmp);
    }
    requestAddNewActCall = () => {
        let tmp = this.state;
        tmp.showNewActCall = true;
        tmp.newActCall = {
            Caller: "",
            Measure: "",
            Duration: "",//float s.v.p
            Outcome: "",
            Comment: "",
            DateTime: "",
        };
        this.setState(tmp);
    }
    requestAddNewField = (category) => {
        const user = GetUserToken();
        let tmp = this.state;
        tmp.showNewFieldForm = true;
        tmp.newField = {
            author: user.Username,
            category: category.toLowerCase(),
            fName: "",
            fValue: "",
            fComment: "",
        };
        this.setState(tmp);
    }
    requestAddNewProcess = () => {
        const user = GetUserToken();
        let myDate = moment().format("YYYY-MM-DD");
        const lead = this.state.Lead;
        let tmp = this.state;
        tmp.showNewProcess = true;
        tmp.newProcess = {
            Org: user.OrgCode,
            LeadRef: lead.LeadRef,
            BatchName: lead.LeadBatch,
            Campaign: lead.LeadCampaign,
            PoolName: lead.PoolName,
            Worker: user.Username,
            DateWorked: myDate,
            Disposition: "",
            Comment: "",
            Title: "",
        };
        this.setState(tmp);
    }
    requestViewDispData = (disp) => {
        this.setState({
            SelectedDispostion: disp,
            showDispostionData: true,
        })

    }


    closeShowReferralList = () => {
        let tmp = this.state;
        tmp.showReferralList = false;
        this.setState(tmp);
    }
    closeShowNewFieldForm = () => {
        let tmp = this.state;
        tmp.showNewFieldForm = false;
        tmp.newField = {
            author: "",
            category: "",
            fName: "",
            fValue: "",
            fComment: "",
        };
        this.setState(tmp);
    }
    closeShowNewActComment = () => {
        let tmp = this.state;
        tmp.showNewActComment = false;
        tmp.newActComment = {
            author: "",
            Title: "",
            Body: "",
            Date: "",
            Time: "",
        };
        this.setState(tmp);
    }
    closeShowNewActCall = () => {
        let tmp = this.state;
        tmp.showNewActCall = false;
        tmp.newActCall = {
            Caller: "",
            Measure: "",
            Duration: "",//float s.v.p
            Outcome: "",
            Comment: "",
            DateTime: "",
        };
        this.setState(tmp);
    }
    closeShowNewActEmail = () => {
        let tmp = this.state;
        tmp.showNewActEmail = false;
        tmp.newActCall = {
            FromEmail: "",
            ToEmail: "",
            Subject: "",
            Body: "",
            DateTime: "",
        };
        this.setState(tmp);
    }
    closeShowNewActSms = () => {
        let tmp = this.state;
        tmp.showNewActSms = false;
        tmp.newActSms = {
            Phone: "",
            Message: "",
            DateTime: "",
            Route: "",
            Cost: "",
        };
        this.setState(tmp);
    }
    closeShowNewActSchedule = () => {
        let tmp = this.state;
        tmp.showNewActSchedule = false;
        tmp.newActSchedule = {
            Author: "",
            Category: "",
            Person: "",
            Id: "",
            Date: "",
            Time: "",
            Comment: "",
            Purpose: "",
        };
        this.setState(tmp);
    }
    closeShowNewProcess = () => {
        let tmp = this.state;
        tmp.showNewProcess = false;
        tmp.newProcess = {
            Org: "",
            LeadRef: "",
            BatchName: "",
            Campaign: "",
            PoolName: "",
            Disposition: "",
            Worker: "",
            DateWorked: "",
            Comment: "",
            Title: "",
        };
        this.setState(tmp);
    }
    closeShowViewDispData = (disp) => {
        this.setState({
            SelectedDispostion: "",
            showDispostionData: false,
            DataDisposition: [],
        })

    }

    submitSearchDispositionDateRange = async (e) => {
        e.preventDefault();
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Worker = user.Username;
        hub.StartDate = this.state.newDateRangeDash.startDate;
        hub.EndDate = this.state.newDateRangeDash.endDate;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/disposition/change/date-range-counter";

        console.log("submitSearchDispositionDateRange send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitSearchDispositionDateRange response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    CounterDisposition: data
                })
            }
        });
    }
    submitNewField = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new field?")) {
            return
        }
        let _this = this;
        let state = this.state.newField;

        const user = GetUserToken();
        let hub = {};
        hub.Org = user.OrgCode;

        hub.Worker = user.Username;
        hub.Category = state.category;
        hub.LeadRef = this.state.Lead.LeadRef; //  "cclms--1562400853756--easipath--Golden-2019-jun-03--00478fa9-7c94-4f99-9f6f-9ff3e97a096a"

        let entry = {}
        entry.Category = state.category.toLowerCase();
        entry.Author = user.Fullname + "--" + user.Username;
        entry.FieldName = state.fName;
        entry.FieldValue = state.fValue;

        hub.Entry = entry;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/store/field/new";

        console.log("submitNewField send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewField response > ", dataIn);

            if (typeof dataIn.RESULT !== "undefined") {

                alert("Thank you Agent added!");
                _this.closeShowNewFieldForm();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewActComment = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new field?")) {
            return
        }
        let _this = this;
        let entry = this.state.newActComment;
        const user = GetUserToken();
        let hub = {};
        hub.Entry = entry;
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Category = "comment";
        hub.Ref = this.state.Lead.LeadRef;
        hub.BatchName = this.state.Lead.LeadBatch;
        hub.Campaign = this.state.Lead.LeadCampaign;
        hub.PoolName = this.state.Lead.PoolName;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/activity/field/new";

        console.log("submitNewActComment send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewActComment response > ", dataIn);

            if (typeof dataIn.RESULT !== "undefined") {

                alert("Thank you comment activity  added!");
                _this.closeShowNewActComment();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewActCall = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new call activity?")) {
            return
        }
        let _this = this;
        let entry = this.state.newActCall;
        entry.Duration = parseFloat(entry.Duration);

        const user = GetUserToken();
        let hub = {};
        hub.Entry = entry;
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Category = "call";
        hub.Ref = this.state.Lead.LeadRef;
        hub.BatchName = this.state.Lead.LeadBatch;
        hub.Campaign = this.state.Lead.LeadCampaign;
        hub.PoolName = this.state.Lead.PoolName;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/activity/field/new";

        console.log("submitNewActCall send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewActCall response > ", dataIn);

            if (typeof dataIn.RESULT !== "undefined") {

                alert("Thank you call activity  added!");
                _this.closeShowNewActCall();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewActEmail = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new email activity?")) {
            return
        }
        let _this = this;
        let entry = this.state.newActEmail;


        const user = GetUserToken();
        let hub = {};
        hub.Entry = entry;
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Category = "email";
        hub.Ref = this.state.Lead.LeadRef;
        hub.BatchName = this.state.Lead.LeadBatch;
        hub.Campaign = this.state.Lead.LeadCampaign;
        hub.PoolName = this.state.Lead.PoolName;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/activity/field/new";

        console.log("submitNewActEmail send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewActEmail response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you email activity  added!");
                _this.closeShowNewActEmail();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewActSms = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new sms activity?")) {
            return
        }
        let _this = this;
        let entry = this.state.newActSms;
        entry.Cost = parseFloat(entry.Cost);

        const user = GetUserToken();
        let hub = {};
        hub.Entry = entry;
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Category = "sms";
        hub.Ref = this.state.Lead.LeadRef;
        hub.BatchName = this.state.Lead.LeadBatch;
        hub.Campaign = this.state.Lead.LeadCampaign;
        hub.PoolName = this.state.Lead.PoolName;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/activity/field/new";

        console.log("submitNewActSms send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewActSms response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you sms activity  added!");
                _this.closeShowNewActSms();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewActSchedule = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new sms activity?")) {
            return
        }
        let _this = this;
        let entry = this.state.newActSchedule;

        const user = GetUserToken();
        let hub = {};
        hub.Entry = entry;
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Category = "schedule";
        hub.Ref = this.state.Lead.LeadRef;
        hub.BatchName = this.state.Lead.LeadBatch;
        hub.Campaign = this.state.Lead.LeadCampaign;
        hub.PoolName = this.state.Lead.PoolName;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/activity/field/new";

        console.log("submitNewActSchedule send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewActSchedule response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you schedule activity  added!");
                _this.closeShowNewActSchedule();
                _this.loadLeadRequest();

            }
        });
    }
    submitNewProcess = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you want that you want to submit this new lead status change?")) {
            return
        }
        let _this = this;
        let hub = this.state.newProcess;
        hub.Display = this.buildDisplayLeadField();

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/disposition/change/new";

        console.log("submitNewProcess send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitNewProcess response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you new status change  added!");
                _this.closeShowNewProcess();
                _this.loadLeadRequest();
                _this.loadDispositionDashboard();
            }
        });
    }
    submitUpload = async (e) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const user = GetUserToken();

        let _this = this;
        let hub = {...this.state.newUpload};
        hub.Org = user.OrgCode;
        hub.LeadRef = this.state.Lead.LeadRef; // "LEAD-CREDITCURE--2019-05-25"


        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/file/new";

        console.log("submitUpload send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUpload response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                if (data !== "") {
                    alert("Thank you , upload submitted! " + data);
                    let tmp = _this.state;
                    tmp.newUpload = {
                        category: "",
                        base64string: "",
                        filename: "",
                    };
                    _this.setState(tmp);
                    _this.loadLeadRequest();
                } else {
                    alert("Upload fail! please resubmit")
                }
            }
        });

    }
    buildDisplayLeadField = () => {

        const leadStore = this.state.Lead.LeadStore;
        const builders = (arrIn) => {
            let st = "";
            for (let i in arrIn) {
                const row = arrIn[i];
                if (row.FieldValue !== "") {
                    st = st + row.FieldValue + ",";
                }
            }
            st = st.trim();
            return st
        }
        let str = builders(leadStore.Name);
        str = str + builders(leadStore.Contact);
        str = str + builders(leadStore.Identity);
        str = str + builders(leadStore.Email);
        str = str + builders(leadStore.Other);
        return str;
    }

    fetchDataMainDashboard = (myTitle, num) => {

    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp);
            if (handleFunc !== null) {
                handleFunc();
            }

        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        //console.log(":)--> HandleInput > ",key," > ",e.target.value)
    }
    getValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return "";
        }
        return this.state[key];
    }
    openViewActivity = (dataIn) => {
        this.setState({
            openViewAct: true,
            viewActData: dataIn,
        })
    }
    closeViewActivity = (dataIn) => {
        this.setState({
            openViewAct: false,
            viewActData: null,
        })
    }

    openDocLink = (link) => {
        console.log("Opening link pdf link request > ", link);
        document.title = "eSign Document";
        window.open(link, '_blank');
    }
    refreshWorkerBoard = () => {
        let tmp = this.state;
        tmp.newDateRangeDash = {
            startDate: "",
            endDate: "",
        }
        this.setState(tmp);
        this.loadDispositionDashboard();
        this.loadLeadRequest();
    }

    getCurrentLeadStatus = () => {
        let status = "---";
        const lead = this.state.Lead;
        if (lead !== null) {
            status = lead.LeadStatus.StatusName;
        }
        return status;
    }
    getCurrentLeadRef = () => {
        let status = "---";
        const lead = this.state.Lead;
        if (lead !== null) {
            const arr = lead.LeadRef.split("--");
            status = arr[1];
        }
        return status;
    }
    renderBox = (myTitle, myNumber) => {

        let num = 0;
        if (this.state.ListDash !== null) {
            num = this.state.ListDash[myTitle];
        }
        return (
            <Badge
                color="primary"
                className="myDashBox"
                badgeContent={num}
                classes={{badge: styles.myBadge}}
                onClick={() => {
                    this.fetchDataMainDashboard(myTitle, num)
                }}
            >
                <Button variant="contained">
                    {myTitle.toLocaleUpperCase()}
                </Button>
            </Badge>
        )
    }
    renderLeadTabActivity = () => {

        let ls = [];
        if (this.state.Lead !== null) {
            if (this.state.Lead.LeadActivity !== null) {
                for (let i in this.state.Lead.LeadActivity) {
                    const row = this.state.Lead.LeadActivity[i];
                    ls.push({
                        Category: row.Category,
                        Date: row.OrgDateTime,
                        Batch: row.BatchName,
                        Campaign: row.Campaign,
                        Pool: row.PoolName,
                        Author: row.Profile.Username,
                        Data: row
                    })
                }
            }
        }
        return (
            <div>
                <h2 className={"alert alert-danger"}>Activity info</h2>
                <div className={"row"}>
                    <div className={"col col-xs-12 container"} style={{textAlign: "center"}}>
                        <span style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            color: "orange"
                        }}>New Activity > </span>&nbsp;&nbsp;
                        <button onClick={() => this.requestAddNewActCall()} type="button"
                                className="btn btn-secondary">Call
                        </button>
                        &nbsp;&nbsp;
                        <button onClick={() => this.requestAddNewActSchedule()} type="button"
                                className="btn btn-secondary">Schedule
                        </button>
                        &nbsp;&nbsp;
                        <button onClick={() => this.requestAddNewActEmail()} type="button"
                                className="btn btn-secondary">Email
                        </button>
                        &nbsp;&nbsp;
                        <button onClick={() => this.requestAddNewActSms()} type="button"
                                className="btn btn-secondary">SMS
                        </button>
                        &nbsp;&nbsp;
                        <button onClick={() => this.requestAddNewActComment()} type="button"
                                className="btn btn-secondary">Comment
                        </button>
                        &nbsp;&nbsp;
                        <hr/>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col col-xs-12"} style={{textAlign: "center"}}>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Category</th>
                                <th>Date</th>
                                <th>Campaign</th>
                                <th>Batch</th>
                                <th>Author</th>
                                <th>#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ls.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{row.Category}</td>
                                        <td>{row.Date}</td>
                                        <td>{row.Campaign}</td>
                                        <td>{row.Batch}</td>
                                        <td>{row.Author}</td>
                                        <td><a className={"btn btn-default"}
                                               onClick={() => this.openViewActivity(row.Data)}>View</a></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )

    }
    renderLeadTabStore = (myKey, label) => {
        const lead = this.state.Lead;
        let ls = [];
        if (lead !== null) {
            let arrName = lead.LeadStore.Name;// FieldName, FieldValue
            let arr = lead.LeadStore[myKey];//
            for (let i in arr) {
                const row = arr[i];
                const arrAuthor = row.Author.split("--");
                ls.push({
                    key: row.FieldName,
                    val: row.FieldValue,
                    update: row.Updated,
                    author: arrAuthor[0]
                });
            }
        } else {
            return (
                <p className={"alert alert-info"}>Empty list {label}</p>
            )
        }

        return (
            <div>
                <h2 className={"alert alert-danger"}>{label} info</h2>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                        <th>Updated</th>
                        <th>Author</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {
                        return (
                            <tr key={index}>
                                <th>{row.key}</th>
                                <td style={{color: "red", fontSize: 24}}>{row.val}</td>
                                <td>{row.update}</td>
                                <td>{row.author}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={4}>
                            <a
                                className={"btn btn-primary pull-right"}
                                onClick={() => this.requestAddNewField(myKey)}
                            >Add Field</a>
                        </td>
                    </tr>
                    </tfoot>
                </table>

            </div>
        )


    }

    doCall=(phone)=>{
        let destination = phone;
        destination = "0" + destination.substring(destination.length - 9);

        this.props.PbxSetOutCaller({
            phone:destination
        });
        this.props.PbxOutCallDo({
            is:true
        })
        this.props.PbxOutCallShowForm({isSet:true})
    }
    renderLeadTabStoreDetail = () => {
        const lead = this.state.Lead;
        const arrKey = ["Name", "Contact", "Email", "Identity", "Other"];
        let ls = [];
        if (lead !== null) {
            for (let x in arrKey) {
                const myKey = arrKey[x];

                let arr = lead.LeadStore[myKey];//
                for (let i in arr) {
                    const row = arr[i];
                    const arrAuthor = row.Author.split("--");
                    let boo=false;

                    if(myKey==="Contact"){
                        boo =true;
                        console.log("******>>>>>> ",myKey," > ",row.FieldName," > ",row.FieldValue);
                    }
                    ls.push({
                        key: row.FieldName,
                        val: row.FieldValue,
                        update: row.Updated,
                        author: arrAuthor[0],
                        isContact:boo,
                    });
                }
            }
        } else {
            return (
                <p className={"alert alert-info"}>Empty list </p>
            )
        }

        return (
            <div>
                <h2 className={"alert alert-danger"}>All Detail info</h2>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>

                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {
                        return (
                            <tr key={index}>
                                <th>{row.key}</th>
                                <td style={{color: "red", fontSize: 24}}>
                                    {row.val}
                                    {row.isContact?
                                        <Fragment>
                                            <a
                                                onClick={()=>this.doCall(row.val)}
                                                style={{cursor:"pointer",color:"blue"}}
                                            >
                                                &nbsp;&nbsp;&nbsp;<DialerSipIcon style={{color:"green",fontWeight:"bold",fontSize:34}}/>
                                            </a>
                                        </Fragment>:null}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )


    }
    renderLeadTabFiles = () => {

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newUpload[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newUpload[key] = e.target.value;
            _this.setState(tmp);
        }

        if (this.state.Lead === null) {
            return null
        }

        return (
            <div className={"container"}>
                <div className="row">
                    <div className="col-xs-12">
                        <h3>New File</h3>
                        <div className={"alert alert-danger"}>
                            <form onSubmit={this.submitUpload}>
                                <div className="form-group">
                                    <label>Choose your file category</label>

                                    <select
                                        className="form-control"
                                        required={true}
                                        value={getNewFieldEntryValue("category")}
                                        onChange={(e) => setNewFieldEntryValue(e, "category")}
                                    >
                                        <option value={""}>--select file category--</option>
                                        {this.state.FileCategory.map((row, index) => {
                                            return (
                                                <option value={row.name}>{row.name}</option>
                                            )
                                        })}
                                    </select>

                                </div>
                                <div className="form-group">
                                    <label>Choose your file</label>
                                    <input
                                        id={"myFile"}
                                        type="file"
                                        className="form-control"
                                        required={true}
                                        onChange={(e) => this.handleInputFile(e, "myFile")}
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type={"submit"}
                                        className="form-control btn btn-default"
                                    >Submit
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h3>List available files</h3>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Category</th>
                                <th>FileName</th>
                                <th>Uploaded</th>
                                <th>Author</th>
                                <th>#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.Lead.LeadFiles.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.Category}</td>
                                        <td>{row.FileName}</td>
                                        <td>{row.OrgDateTime}</td>
                                        <td>---</td>
                                        <td>
                                            <a
                                                onClick={() => this.openDocLink(row.Link)}
                                                className={"btn btn-default"}
                                            >Download</a>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
    renderLeadStatusCounter = () => {
        let ls = [];

        for (let i in this.state.Dispositions) {
            const row = this.state.Dispositions[i];
            const name = row.name;
            let counter = 0;
            if (typeof this.state.CounterDisposition[name] !== "undefined") {
                counter = this.state.CounterDisposition[name];
            }
            ls.push({
                name: name,
                number: counter,
            })
        }

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newDateRangeDash[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newDateRangeDash[key] = e.target.value;
            _this.setState(tmp);
        }


        return (
            <div>
                <ul className="list-group">
                    ReferralActive
                    <li
                        className="list-group-item"
                        style={{
                            cursor: "pointer", color: "red"
                        }}
                        onClick={() => {
                            this.requestShowReferral();

                        }}
                    >
                        Referral
                        <span className={"badge pull-right"}>
                                {this.state.ReferralActive.length}
                            </span>
                    </li>

                    {ls.map((row, index) => {
                        return (
                            <li
                                key={index}
                                className="list-group-item"
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.loadDispositionDashboard_Filter(row.name);
                                    this.requestViewDispData(row.name);
                                    //alert("Showing " + row.name + " : " + row.number);
                                }}
                            >
                                {row.name}
                                <span className={"badge pull-right"}>
                                {row.number}
                            </span>
                            </li>
                        )
                    })}
                </ul>
                <form className={"alert alert-info"} onSubmit={this.submitSearchDispositionDateRange}>
                    <h3>Search</h3>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Start Date</label>
                        <input
                            type={"date"}
                            className="form-control"
                            placeholder={"YYYY-MM-DD"}
                            required={true}
                            value={getNewFieldEntryValue("startDate")}
                            onChange={(e) => setNewFieldEntryValue(e, "startDate")}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">End Date</label>
                        <input
                            type={"date"}
                            className="form-control"
                            placeholder={"YYYY-MM-DD"}
                            required={true}
                            value={getNewFieldEntryValue("endDate")}
                            onChange={(e) => setNewFieldEntryValue(e, "endDate")}
                        />
                    </div>
                    <div className="form-group">
                        <button type={"submit"} className="form-control">Search</button>

                    </div>

                </form>
            </div>

        )
    }
    renderCurrentAllocation = () => {
        let campaign = "";
        let batch = "";
        let poolName = "";
        const lead = this.state.Lead;
        if (lead !== null) {
            campaign = lead.LeadCampaign;
            batch = lead.LeadBatch;
            poolName = lead.PoolName;
        }

        return (
            <p>
                <span style={{color: "red", fontWeight: "bold"}}>ALLOCATION > </span>
                <span>Campaign</span>:<b>{campaign}</b>;&nbsp;&nbsp;
                <span>Batch</span>:<b>{batch}</b>;&nbsp;&nbsp;
                <span>Pool</span>:<b>{poolName}</b>;&nbsp;&nbsp;
                <hr/>
            </p>
        )
    }

    renderShowReferralList=() =>{

        if (!this.state.showReferralList) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowReferralList}
            />,
        ];


        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Referral List"}
                    actions={actions}
                    modal={false}
                    open={this.state.showReferralList}
                    onRequestClose={this.closeShowReferralList}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Display</th>
                                <th>Campaign</th>
                                <th>Date</th>
                                <th>#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.ReferralActive.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{row.Display}</td>
                                        <td>{row.Campaign}</td>
                                        <td>{row.OrgDateTime}</td>
                                        <td><a className={"btn btn-default"} onClick={()=>this.workerBoardReferral(row)}>Load</a> </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewFieldForm() {

        if (!this.state.showNewFieldForm) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewFieldForm}
            />,
        ];

        let ls = this.state.DataTasks;
        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newField[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newField[key] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Field [" + this.state.newField.category + "]"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewFieldForm}
                    onRequestClose={this.closeShowNewFieldForm}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewField}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Field Name</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Field name"}
                                    required={true}
                                    value={getNewFieldEntryValue("fName")}
                                    onChange={(e) => setNewFieldEntryValue(e, "fName")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Field Value</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Field Value"}
                                    required={true}
                                    value={getNewFieldEntryValue("fValue")}
                                    onChange={(e) => setNewFieldEntryValue(e, "fValue")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Any Comment</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Comment"}
                                    value={getNewFieldEntryValue("fComment")}
                                    onChange={(e) => setNewFieldEntryValue(e, "fComment")}
                                />
                            </div>

                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewActCommentForm() {

        if (!this.state.showNewActComment) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewActComment}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newActComment[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newActComment[key] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Comment"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewActComment}
                    onRequestClose={this.closeShowNewActComment}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewActComment}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Title</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Title or Subject"}
                                    required={true}
                                    value={getNewFieldEntryValue("Title")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Title")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Body Comment</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Your comment content"}
                                    required={true}
                                    value={getNewFieldEntryValue("Body")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Body")}
                                />
                            </div>


                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewActCallForm() {

        if (!this.state.showNewActCall) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewActCall}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newActCall[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newActCall[key] = e.target.value;
            _this.setState(tmp);
        }
        /*
        Caller :"",
            Measure :"",
            Duration :"",//float s.v.p
            Outcome :"",
            Comment :"",
            DateTime :"",
         */

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Comment"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewActCall}
                    onRequestClose={this.closeShowNewActCall}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewActCall}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Caller</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Number Called"}
                                    required={true}
                                    value={getNewFieldEntryValue("Caller")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Caller")}
                                />
                            </div>


                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Duration Measure</label>
                                <select
                                    className="form-control"
                                    required={true}
                                    value={getNewFieldEntryValue("Measure")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Measure")}
                                >
                                    <option value={""}>--Select--</option>
                                    <option value={"second"}>Second</option>
                                    <option value={"minute"}>Minute</option>
                                    <option value={"hour"}>Hour</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Duration value</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Duration of the call value"}
                                    required={true}
                                    value={getNewFieldEntryValue("Duration")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Duration")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Outcome</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Your Outcome"}
                                    required={true}
                                    value={getNewFieldEntryValue("Outcome")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Outcome")}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Comment</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Your Comment"}
                                    required={false}
                                    value={getNewFieldEntryValue("Comment")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Comment")}
                                />
                            </div>


                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewActEmailForm() {

        if (!this.state.showNewActEmail) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewActEmail}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newActEmail[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newActEmail[key] = e.target.value;
            _this.setState(tmp);
        }
        /*
FromEmail :"",
            ToEmail :"",
            Subject :"",
            Body :"",
         */

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Email"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewActEmail}
                    onRequestClose={this.closeShowNewActEmail}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewActEmail}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">To</label>
                                <input
                                    type={"email"}
                                    className="form-control"
                                    placeholder={"Receiver email"}
                                    required={true}
                                    value={getNewFieldEntryValue("ToEmail")}
                                    onChange={(e) => setNewFieldEntryValue(e, "ToEmail")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">From</label>
                                <input
                                    type={"email"}
                                    className="form-control"
                                    placeholder={"Sender email"}
                                    required={false}
                                    value={getNewFieldEntryValue("FromEmail")}
                                    onChange={(e) => setNewFieldEntryValue(e, "FromEmail")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Subject</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Subject"}
                                    required={true}
                                    value={getNewFieldEntryValue("Subject")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Subject")}
                                />
                            </div>


                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Body</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Email Body"}
                                    required={true}
                                    value={getNewFieldEntryValue("Body")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Body")}
                                />
                            </div>


                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewActSmsForm() {

        if (!this.state.showNewActSms) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewActSms}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newActSms[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newActSms[key] = e.target.value;
            _this.setState(tmp);
        }
        /*
Phone    : "",
            Message  : "",
            DateTime : "",
            Route    : "",
            Cost     : "",
         */

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Email"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewActSms}
                    onRequestClose={this.closeShowNewActSms}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewActSms}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Recipient Number</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Enter the mobile number of a recipient"}
                                    required={true}
                                    value={getNewFieldEntryValue("Phone")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Phone")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Message</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Enter the Message"}
                                    required={false}
                                    value={getNewFieldEntryValue("Message")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Message")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Date & Time</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Date Time"}
                                    required={true}
                                    value={getNewFieldEntryValue("DateTime")}
                                    onChange={(e) => setNewFieldEntryValue(e, "DateTime")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Cost</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Cost without rand only digit"}
                                    required={true}
                                    value={getNewFieldEntryValue("Cost")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Cost")}
                                />
                            </div>


                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewActScheduleForm() {

        if (!this.state.showNewActSchedule) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewActSchedule}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newActSchedule[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newActSchedule[key] = e.target.value;
            _this.setState(tmp);
        }
        /*
            Category : "",
            Person   : "",
            Id       : "",
            Date     : "",
            Time     : "",
            Comment  : "",
            Purpose  : "",
         */

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Email"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewActSchedule}
                    onRequestClose={this.closeShowNewActSchedule}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewActSchedule}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Category</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Category"}
                                    required={false}
                                    value={getNewFieldEntryValue("Category")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Category")}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Person</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Enter the Person name"}
                                    required={true}
                                    value={getNewFieldEntryValue("Person")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Person")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Contact ID (email Or Phone)</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Contact ID (email Or Phone)"}
                                    required={true}
                                    value={getNewFieldEntryValue("Id")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Id")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Date</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Date"}
                                    required={true}
                                    value={getNewFieldEntryValue("Date")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Date")}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Time</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Time"}
                                    required={true}
                                    value={getNewFieldEntryValue("Time")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Time")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Comment</label>
                                <textarea
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Time"}
                                    required={true}
                                    value={getNewFieldEntryValue("Comment")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Comment")}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Purpose</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Purpose"}
                                    required={true}
                                    value={getNewFieldEntryValue("Purpose")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Purpose")}
                                />
                            </div>


                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowNewProcessForm() {

        if (!this.state.showNewProcess) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewProcess}
            />,
        ];

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state.newProcess[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp.newProcess[key] = e.target.value;
            _this.setState(tmp);
        }
        /*

         */

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Email"}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewProcess}
                    onRequestClose={this.closeShowNewProcess}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewProcess}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Disposition</label>
                                <select
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Disposition"}
                                    required={false}
                                    value={getNewFieldEntryValue("Disposition")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Disposition")}
                                >
                                    <option value={""}>--select--</option>
                                    {this.state.Dispositions.map((row, index) => {
                                        return (
                                            <option key={index} value={row.name}>{row.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Comment</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Comment"}
                                    required={false}
                                    value={getNewFieldEntryValue("Comment")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Comment")}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Title</label>
                                <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder={"Title"}
                                    required={false}
                                    value={getNewFieldEntryValue("Title")}
                                    onChange={(e) => setNewFieldEntryValue(e, "Title")}
                                />
                            </div>

                            <div className="form-group">

                                <button
                                    className="form-control pull-right btn-primary"
                                    type={"submit"}
                                >Submit
                                </button>
                            </div>

                        </form>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }
    renderShowViewDispositionData() {

        if (!this.state.showDispostionData) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowViewDispData}
            />,
        ];


        return (
            <MuiThemeProvider>
                <Dialog
                    title={"View Disposition data [" + this.state.SelectedDispostion + "]"}
                    actions={actions}
                    modal={false}
                    open={this.state.showDispostionData}
                    onRequestClose={this.closeShowViewDispData}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Display Info</th>
                                <th>Date&Time</th>

                                <th>#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.DataDisposition.map((row, index) => {
                                /*
                                LeadRef     string
                                BatchName string
                                Campaign string
                                PoolName string

                                 */
                                return (
                                    <tr key={index}>
                                        <td>{row.Display}</td>
                                        <td>{row.OrgDateTime}</td>
                                        <td><a
                                            onClick={() => this.loadLeadRequestFilter(
                                                row.LeadRef,
                                                row.PoolName,
                                                row.BatchName,
                                                row.Campaign)}
                                            className={"btn btn-default"}>Process</a></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }


    renderOpenViewAct() {

        if (!this.state.openViewAct) {
            return null
        }

        let ls = [];
        let o = {};
        let state = this.state.viewActData;
        if (state.Category === "call") {
            o = state.ActCall;
        }
        if (state.Category === "email") {
            o = state.ActEmail;
        }
        if (state.Category === "comment") {
            o = state.ActComment;
        }
        if (state.Category === "sms") {
            o = state.ActSms;
        }
        if (state.Category === "schedule") {
            o = state.ActSchedule;
        }

        for (let i in o) {
            ls.push({
                key: i,
                val: o[i]
            })
        }


        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeViewActivity}
            />,
        ];


        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add New Activity Email"}
                    actions={actions}
                    modal={false}
                    open={this.state.openViewAct}
                    onRequestClose={this.closeViewActivity}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ls.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{row.key}</td>
                                        <td>{row.val}</td>
                                    </tr>
                                )

                            })}

                            </tbody>
                        </table>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }


    render() {
        return (

            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>

                    {/*<div className="row">
                        <div className="col-xs-12">

                            <div style={styles.row}>
                                {this.renderBox("Lead", 5)}
                                {this.renderBox("Followup", 8)}
                                {this.renderBox("Callback", 6)}
                                {this.renderBox("Prospect", 6)}
                            </div>
                            <hr/>

                        </div>
                    </div>*/}

                    <div className="row">
                        <div className="col-xs-2">
                            <h3>Disposition</h3>
                            {this.renderLeadStatusCounter()}
                        </div>
                        <div className="col-xs-10 alert alert-default">
                            <div className={"row"}>
                                <div className={"col col-xs-12 container"} style={{textAlign: "center"}}>
                                    {this.renderCurrentAllocation()}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col col-xs-12 container"} style={{textAlign: "center"}}>
                                    <button onClick={() => this.refreshWorkerBoard()} type="button"
                                            className="btn btn-secondary">Refresh
                                    </button>
                                    &nbsp;&nbsp;
                                    <button onClick={() => window.location.href = "#lead-new"} type="button"
                                            className="btn btn-secondary">New Lead
                                    </button>
                                    &nbsp;&nbsp;
                                    <button onClick={() => this.requestAddNewProcess()} type="button"
                                            className="btn btn-secondary">Process
                                    </button>
                                    &nbsp;&nbsp;
                                    <hr/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col col-xs-12 container alert alert-primary"}
                                     style={{textAlign: "center"}}>
                                    <span className={"pull-left"}><b>Lead Status:</b>&nbsp;{this.getCurrentLeadStatus()}</span>
                                    <span
                                        className={"pull-right"}><b>Lead Ref:</b>&nbsp;{this.getCurrentLeadRef()}</span>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col col-xs-12 container"} style={{textAlign: "center"}}>
                                    <Tabs>


                                        <Tab label="Detail">
                                            {this.renderLeadTabStoreDetail()}
                                        </Tab>
                                        <Tab label="Names">
                                            {this.renderLeadTabStore("Name", "Name")}
                                        </Tab>
                                        <Tab label="Phone">
                                            {this.renderLeadTabStore("Contact", "Contact Phone number")}
                                        </Tab>
                                        <Tab label="Email">
                                            {this.renderLeadTabStore("Email", "Email address")}
                                        </Tab>
                                        <Tab label="Identity">
                                            {this.renderLeadTabStore("Identity", "Identity")}
                                        </Tab>
                                        <Tab label="Other">
                                            {this.renderLeadTabStore("Other", "Other field")}
                                        </Tab>
                                        <Tab label="Activity">
                                            {this.renderLeadTabActivity()}
                                        </Tab>
                                        <Tab label="Files">
                                            {this.renderLeadTabFiles()}
                                        </Tab>
                                        <Tab label="Status">

                                        </Tab>


                                    </Tabs>
                                </div>
                            </div>

                            {this.renderShowNewFieldForm()}
                            {this.renderShowNewActCommentForm()}
                            {this.renderShowNewActCallForm()}
                            {this.renderShowNewActEmailForm()}
                            {this.renderOpenViewAct()}
                            {this.renderShowNewActSmsForm()}
                            {this.renderShowNewActScheduleForm()}
                            {this.renderShowNewProcessForm()}
                            {this.renderShowViewDispositionData()}
                            {this.renderShowReferralList()}

                        </div>
                    </div>


                </MuiThemeProvider>
            </PageContentWallpaper>


        );
    }
}


export default Connect(ComLeadWorker);

const styles = {
    avatar: {
        margin: 10,
        fontSize: 48,
        width: "150px",
        height: "150px",
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
        fontSize: 48,
        width: "150px",
        height: "150px",
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
        fontSize: 48,
        width: "150px",
        height: "150px",
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    myBadge: {

        fontSize: 24,
        height: "68px",
        width: "150px"
    },
    leadStatusBadge: {

        fontSize: 24,
        height: "68px",
        width: "150px"
    },
    card: {
        maxWidth: "100%",
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },

};
