import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import Wallpaper from "../common/ef-wallper";
import GridContainer from "../common/dashboard/Grid/GridContainer";
import GridItem from "../common/dashboard/Grid/GridItem";
import InCall from "./inCall";
import OuCall from "./outCall";
import './innerStyle.css';
import moment from "moment";


class ControllerBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mySize: 5,
            intervalId:"",
            startAt: "2020-01-25 11:50:10",
            duration:""
        }

        this.animateSize()

    }
    componentWillUnmount=()=>{
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }
    componentDidMount=()=> {
        let self=this;
        let intervalId = setInterval(()=>{
            self.animateSize()
            self.getDuration();
        }, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({intervalId: intervalId});
    }

    animateSize = () => {
        const val = parseInt( this.state.mySize);
        let newValue = 0;
        if (val === 5) {
            newValue = 10
        }else if (val === 10) {
            newValue = 5
        }
        let tmp = this.state;
        tmp.mySize = newValue;
        this.setState(tmp);
    }
    getDuration=()=>{
        const myFormat="YYYY-MM-DD HH:mm:ss";
        // Convert `startsAt` and `endsAt` strings to moment date objects
        const start = moment(this.state.startAt).format(myFormat); // moment(myFormat,this.state.startsAt)
        const end = moment();

        // Calculate the duration
        // Keep in mind you can get the duration in seconds, days, etc.
        const duration = moment.duration(end.diff(start)).asMinutes()

        this.setState({
            duration:duration
        })

    }

    render() {
        return (
            <Fragment>
                <GridContainer style={{marginTop: 150, minHeight: "70%", paddingTop: 35}}>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" style={{minHeight: "100%"}}>
                        <h2>Main content area</h2>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center">

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center"
                              style={{minHeight: 150, backgroundColor: "", marginTop: 15}}>

                    </GridItem>
                </GridContainer>
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    minWidth: "5%",
                    maxHeight: "60%",
                    maxWidth:"20%",
                    borderWidth: this.state.mySize,
                    borderColor: "red",
                    borderStyle: "solid",
                    shadowColor: "red",
                    shadowOpacity: 0.8,
                    shadowRadius: 2,
                    zIndex: 110,
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
                    transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
                    shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
                    textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
                    backgroundColor:"white"

                }}
                >

                    <InCall {...this.props} style={{float: "right"}} duration={this.state.duration}/>

                </div>

                <div style={{
                    position: "fixed",
                    bottom: 0,
                    left: 10,
                    minWidth: "30%",
                    minHeight: "25%",
                    maxWidth:"40%",
                    borderWidth: 5,
                    borderColor: "#009688",
                    borderStyle: "solid",
                    shadowColor: "red",
                    shadowOpacity: 0.8,
                    shadowRadius: 2,
                    zIndex: 110,
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.4)',
                    transform: 'translate3d(0px,0,0) scale(1) rotateX(0deg)',
                    shape: 'M20,20 L20,380 L380,380 L380,20 L20,20 Z',
                    textShadow: '0px 5px 15px rgba(255,255,255,0.5)',
                    backgroundColor:"white"

                }}
                >

                    <OuCall {...this.props} style={{float: "right"}} duration={this.state.duration}/>

                </div>

            </Fragment>
        )
    }

}

const styles = {
    inCall: {
        position: 'static',
        background: 'orange',// 'rgba(0,0,0,0.5)',
        color: '#fff',
        padding: 8,
        zIndex: 100,
        marginBottom: 0,
        marginRight: 0,
        maxWidth: "20%",
        borderRadius: 5
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConClickToCall extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ControllerBox {...this.props}/>
            </Wallpaper>
        );
    }
})

