import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "material-ui/Dialog";

class ComPbxQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Queues: [],
            newQueue: {org: "", name: "", extension: {}},
            newExtension:{name:""},
            currentQueue: null,
            isListQueue: true,
            isNewQueue: false,
            isNewExtension:false,
        }


    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});

        await this.fetchQueue();

    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchQueue = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }
        console.log("fetchQueue--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxQueue", conds, (data) => {
            console.log("fetchQueue :> ", data);
            _this.setState({
                Queues: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitQueue = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this range!" + this.state.newQueue.name)) {
            return
        }
        let _this = this;
        const state = this.state.newQueue;


        let hub = {
            org: state.org,
            name: state.name,
            extension:state.extension,
        };


        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/queue/new";

        console.log("submitQueue send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitQueue response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new range success!");
                let tmp = _this.state;
                tmp.newRange = {org: "", name: "", start: "", end: "", secret: ""}
                tmp.isListQueue = true;
                tmp.isNewQueue = false;
                tmp.isNewSecretRange = false;
                _this.setState(tmp);
                _this.fetchQueue();
            } else {
                alert("Error something went wrong!!")
            }

        });

    }
    submitNewExtension = async (e)=>{
        let _this = this;
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to add  this extension: "+this.state.newExtension.name+" to the queue: " + this.state.newQueue.name+"?")) {
            return
        }

        const state = this.state.currentQueue;
        let extensions = state.extension;
        if(extensions ===null){
            extensions={}
        }
        extensions[this.state.newExtension.name]="";

        let hub = {
            org: state.org,
            name: state.name,
            extension:extensions,
        };

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/queue/new";

        console.log("submitNewExtension send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitQueue response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new range success!");
                let tmp = _this.state;
                tmp.newExtension = {name: ""}
                tmp.isListQueue = true;
                tmp.isNewQueue = false;
                tmp.isNewExtension = false;
                tmp.currentQueue =null;
                _this.setState(tmp);
                _this.fetchQueue();
                _this.closeNewExtension();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    submitRemoveExtension = async (row,extrn)=>{
        let _this = this;

        if (!window.confirm("Are you sure that you want to remove  this extension: "+extrn+" to the queue: " + row.name+"?")) {
            return
        }

        const state = row;
        let extensions = state.extension;
        if(extensions ===null){
            extensions={}
        }
        extensions[this.state.newExtension.name]="";

        delete extensions[extrn];

        let hub = {
            org: state.org,
            name: state.name,
            extension:extensions,
        };

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/queue/new";

        console.log("submitNewExtension send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitQueue response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new range success!");
                let tmp = _this.state;
                tmp.newExtension = {name: ""}
                tmp.isListQueue = true;
                tmp.isNewQueue = false;
                tmp.isNewExtension = false;
                tmp.currentQueue =null;
                _this.setState(tmp);
                _this.fetchQueue();
                _this.closeNewExtension();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getStateValue = (key) => {
        return this.state[key];
    }
    deleteEntry = async (row) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxTrunk"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("deleteEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("deleteEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, trunk deleted success!");

                _this.fetchQueue();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    addExtensionQueue=(row)=>{
        this.setState({
            currentQueue:row,
            isNewExtension:true,
            newExtension:{name:""},
        })
    }
    deleteExtensionQueue = async (row, extrn) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxTrunk"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("deleteEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("deleteEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, trunk deleted success!");

                _this.fetchQueue();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    requestNewQueue = () => {
        let newTrunk = this.state.newQueue;
        const user = GetUserToken();
        newTrunk.org = user.OrgCode;
        this.setState({
            isListQueue: false,
            isNewQueue: true,
            isNewSecretRange: false,
            newQueue:newTrunk
        })
    }
    closeNewExtension=()=>{
        this.setState({
            newExtension:{name:""},
            isNewExtension:false,
        })
    }

    renderNewQueue = () => {
        const state = this.state;
        if (!state.isNewQueue) {
            return null
        }
        let _this = this;

        const listField = ["org", "name"];
        const getValue = (myKey) => {
            return _this.state.newQueue[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp.newQueue[myKey] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <div className={""}>
                <p className={"alert alert-danger"}>New Queue</p>
                <form onSubmit={this.submitQueue}>
                    {listField.map((item) => {
                        if(item==="org"){
                            const user=GetUserToken();
                            if(user.Role==="super"){
                                return (
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={"Enter " + item.toUpperCase() + " ..."}
                                            onChange={(e) => setValue(e, item)}
                                            value={this.getStateValue(item)}
                                            required={true}
                                        />
                                    </div>
                                )
                            }
                            return (
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={"Enter " + item.toUpperCase() + " ..."}
                                        onChange={(e) => setValue(e, item)}
                                        value={user.OrgCode}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter " + item.toUpperCase() + " ..."}
                                    onChange={(e) => setValue(e, item)}
                                    value={this.getStateValue(item)}
                                    required={true}
                                />
                            </div>
                        )
                    })}
                    <div className="form-group">
                        <button className={"btn btn-danger pull-left"} type={"button"}
                                onClick={() => {
                                    this.setState({
                                        isListQueue: true,
                                        isNewQueue: false,
                                        isNewSecretRange: false,
                                    })
                                }}
                        >
                            Cancel
                        </button>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    renderQueueList = () => {

        const state = this.state;
        if (!state.isListQueue) {
            return null
        }

        let ls = this.state.Queues;

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Name</th>
                    <th>Extensions</th>
                    <th>Updated</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let extList=[];
                    for(let i in row.extension){
                        extList.push(i);
                    }
                    return (
                        <tr key={index}>
                            <td>{row.org}</td>
                            <td>{row.name}</td>
                            <td>
                                {extList.map((extrn)=>{
                                    return(
                                        <p>
                                            {extrn} &nbsp;&nbsp;
                                            <a style={{color:"red",cursor:"pointer"}} onClick={() => this.submitRemoveExtension(row,extrn)}>Remove</a>
                                        </p>
                                    )
                                })}
                                <p>

                                    <a className={"btn btn-default"} onClick={() => this.addExtensionQueue(row)}>Add Extension</a>
                                </p>
                            </td>
                            <td>{row.date + " " + row.time}</td>

                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    renderPopupNewExtension = () => {

        if (!this.state.isNewExtension) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeNewExtension}
            />,
        ];

        let _this = this;
        const getNewAgentValue=(key)=>{
            return _this.state.newExtension[key];
        }
        const setNewValue=(e,key)=>{
            const val = e.target.value;
            let tmp=this.state;
            tmp.newExtension[key] = val;
            _this.setState(tmp);
        }

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Add new extension to queue: "+this.state.currentQueue.name}
                    actions={actions}
                    modal={false}
                    open={this.state.isNewExtension}
                    onRequestClose={this.closeNewExtension}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className={"row"}>
                        <div className="col-lg-12">
                            <form onSubmit={this.submitNewExtension}>

                                <div className="form-group" >
                                    <label>Outbound Number</label>
                                    <input
                                        className="form-control"
                                        required={true}
                                        value={getNewAgentValue("name")}
                                        onChange={(e)=>setNewValue(e,"name")}
                                        placeholder={"Type extension e.g. 100010 ..."}
                                    />

                                </div>
                                <div className="form-group" >
                                    <button type={"submit"} className={"btn btn-primary pull-right"}>Submit</button>
                                </div>
                            </form>



                        </div>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Inbound Queue

                                <a onClick={() => this.requestNewQueue()} className={"btn btn-primary pull-right"}>New
                                    Queue</a>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderQueueList()}
                        {this.renderNewQueue()}
                        {this.renderPopupNewExtension()}
                    </div>
                </div>


            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComPbxQueue)