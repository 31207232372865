import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    submitEntry
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import ChartPie from "../../component/Ticket/ChartPie";
import ChartWatch from "../../component/Ticket/ChartWatch";
import ChartCircle from "../../component/Ticket/ChartCircle";
import ReactStoreIndicator from "../../component/Ticket/ChartPercentage";
import PopupMe from "../../component/Ticket/PopupMe";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";

const listField = [
    {
        field: "name",
        label: "Name",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "description",
        label: "Description",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
];


class SetupUtilPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }

    componentDidMount() {
        //todo

    }

    fetchRoles = async () => {
        //todo
    }
    submitEntry = async (e) => {

        //todo delete after live
        let data = this.props.enquiry.Roles;
        data.push(this.state.newEntry);
        this.props.SaveRole(data);
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        console.log("submitEntry--->", this.state.newEntry);

        //todo uncomment after
        return
        let endpoint = "/ticket/insert/roles";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}];
        let reloadFunction = [this.fetchRoles];
        await submitEntry(e, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }
        let columns = [
            {id: 'id', label: 'ID', minWidth: 170, dataType: "string"},
            {id: 'subject', label: 'Subject', minWidth: 170, dataType: "string"},
            {id: 'author', label: 'Requester Name', minWidth: 170, dataType: "string"},
            {id: 'assignto', label: 'Assign To', minWidth: 170, dataType: "string"},
            {id: 'duedy', label: 'DueBy', minWidth: 170, dataType: "string"},
            {id: 'status', label: 'Status', minWidth: 170, dataType: "string"},
            {id: 'created', label: 'Created Date', minWidth: 170, dataType: "string"},
            {id: 'service', label: 'Service', minWidth: 170, dataType: "string"},
            {id: 'site', label: 'Site', minWidth: 170, dataType: "string"},
            {id: 'behalf', label: 'On-Behalf-Of', minWidth: 170, dataType: "string"},
        ];

        let data = {columns: columns, data: this.props.enquiry.Requests};
        return (
            <TableDataView
                {...data}
                tableTitle={"Util (Database)"}
                onAddRecord={this.onAddRecord}
            />
        )
    }

    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }
        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Util " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} >
                {this.renderListUtil()}
                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" style={{marginTop:35}}>
                        <ChartPie />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" style={{marginTop:35}}>
                        <ChartWatch/>
                    </GridItem>
                </GridContainer>

                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" style={{marginTop:35}}>
                        <ReactStoreIndicator />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" style={{marginTop:35}}>
                        <ChartCircle />
                    </GridItem>
                </GridContainer>

                <PopupMe />


                {this.renderEditForm()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(SetupUtilPage);
