import {connect} from 'react-redux';
import {SaveLoginInfo, SetNavigateMenu, SetNavigateModule} from "../action/action-global";
import {
    PbxChangeExtension,
    PbxChangeAgent,
    PbxOutCallRequest,
    PbxOutCallEnd,
    PbxOutCallForm,
    PbxOutCallDo,
    PbxSetOutCaller,
    PbxInCallRequest,
    PbxInCallAnswer,
    PbxInCallHangup,
    PbxSetRegistry,
    PbxCallingStatus
} from "../action/action-pbx";
import {LoadTicketData} from "../action/action-ticket-fetch-data";

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch,
    SaveLoginInfo: SaveLoginInfo,
    SetNavigateMenu: SetNavigateMenu,
    SetNavigateModule: SetNavigateModule,
    PbxChangeExtension: (data) => dispatch(PbxChangeExtension(data)),
    PbxOutCallShowForm: (data) => dispatch(PbxOutCallForm(data)),
    PbxOutCallDo: (data) => dispatch(PbxOutCallDo(data)),
    PbxSetOutCaller: (data) => dispatch(PbxSetOutCaller(data)),
    PbxChangeAgent: (data) => dispatch(PbxChangeAgent(data)),
    PbxOutCallRequest: (data) => dispatch(PbxOutCallRequest(data)),
    PbxOutCallEnd: (data) => dispatch(PbxOutCallEnd(data)),

    PbxInCallRequest: (data) => dispatch(PbxInCallRequest(data)),
    PbxInCallAnswer: (data) => dispatch(PbxInCallAnswer(data)),
    PbxInCallHangup: (data) => dispatch(PbxInCallHangup(data)),

    PbxSetRegistry: (data) => dispatch(PbxSetRegistry(data)),
    PbxCallingStatus: (data) => dispatch(PbxCallingStatus(data)),

    LoadTicketData: () => dispatch(LoadTicketData()),
})

const mapStateToProps = state => ({
    menus: state.menus,
    login: state.login,
    navigations: state.navigations,
    addingModel: state.addingModel,
    info: state.info,
    enquiry: state.enquiry,
    dataService: state.dataService,
    pbx: state.pbx
});

const Connector = (requestedClass) => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(requestedClass)
}

export default Connector;
