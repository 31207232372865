import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    submitEntryCRM
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";


const listField = [
    {
        field: "name",
        label: "Name",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "description",
        label: "Description",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
];


class RolePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }

    componentDidMount() {
        //todo

    }

    fetchRoles = async () => {
        //todo
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        this.props.LoadTicketData();
    }
    submitEntry = async (e) => {


        console.log("submitEntry--->", this.state.newEntry);

        //todo uncomment after

        let endpoint = "/reservation/insert/role";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}];
        let reloadFunction = [this.fetchRoles];
        await submitEntryCRM(e, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }


        let columns = [
            {id: 'name', label: 'Name', minWidth: 170, dataType: "string"},
            {id: 'description', label: 'Description', minWidth: 170, dataType: "string"},
        ];

        console.log("ENQUIRY XXXX: ", this.props.enquiry);

        let data = {columns: columns, data: this.props.enquiry.Roles};
        return (
            <TableDataView
                {...data}
                tableTitle={"People Role"}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
            />
        )
    }

    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }
        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Util " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} >

                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderListUtil()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} alignItems="center" >
                        {this.renderEditForm()}
                    </GridItem>
                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(RolePage);
