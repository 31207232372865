import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderListPrivate,
    renderListPrivateWithData,
    renderForm, removeEntry
} from "../generic/databases-function";
import {GetUserToken} from "../../api/services";
import eConfig from "../../config";

const options = ["yes", "no"];
const optionsDataType = ["string", "float", "boolean"];
const optionsCountry = ["usa", "sa", "congo", "DRC"];
const optionsBoolean = [true, false];

export default class ComFieldAgentDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Structures: [],
            Campaigns: [],
            selectedCampaign: "",
            newEntry: {},
            showList: true,
            cqlTable: "CustomerStructure",
            listField: [
                {
                    field: "FieldName",
                    label: "Field Name",
                    options: options,
                    type: "text",
                    required: true
                },
                {
                    field: "Section",
                    label: "Display Section",
                    options: options,
                    type: "text",
                    required: true
                },
                {
                    field: "Position",
                    label: "Position",
                    options: options,
                    type: "float",
                    required: true
                },
                {
                    field: "DataType",
                    label: "DataType",
                    options: optionsDataType,
                    type: "option",
                    required: true
                },
                {
                    field: "Username",
                    label: "Username",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Password",
                    label: "Password",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Unique",
                    label: "Unique",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Mandatory",
                    label: "Mandatory",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Name",
                    label: "Name",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Surname",
                    label: "Surname",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Required",
                    label: "Required",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Phone",
                    label: "Phone",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },
                {
                    field: "Email",
                    label: "Email",
                    options: optionsBoolean,
                    type: "boolean",
                    required: true
                },

            ]

        }


    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this,
            "otp",
            "Campaign",
            "Campaigns",
        );
        await fetchUtilData(this,
            "otp",
            "CustomerStructure",
            "Structures",
            /*undefined,
            [{key: "campaign", val: this.state.selectedCampaign, type: "string"}]*/
        );
    }

    fetchApi = async (url, hub, method, callback) => {
        console.log("$$$$$----> ", url);
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((response) => {
                callback(response, null);
            }).catch((e) => {
            callback(null, e, null);
        });
    }

    submitEntry = async (e, table, addData, _this) => {
        if(this.state.selectedCampaign===""){
            return alert("Please select your target campaign before processed")
        }
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
                if (row.type === "boolean") {
                    entry[row.field] = entry[row.field] === "true" ? true : false
                }
            }
        }
        //todo let add our default variable
        entry.Campaign = this.state.selectedCampaign;

        let hub = {...entry};

        let backend = eConfig.otpBackend;
        let endpoint = "/api/customer/structure/new";
        let url = eConfig.serverBackendOtp + "/" + backend + endpoint;

        await this.fetchApi(url, hub, "POST", async (dataIn, err) => {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showList = true;
                    _this.setState(tmp);
                    await _this.loadInitialData();
                }
            }

        });

    }

    renderList = () => {
        const keyList = [];
        for (let i in this.state.listField) {
            const row = this.state.listField[i];
            keyList.push({
                field: row.field.toLocaleLowerCase(),
                label: row.field.toLocaleUpperCase(),
                type: row.type,
            });
        }
        let ls=[];
        for(let i in this.state.Structures){
            const row=this.state.Structures[i];
            console.log("listField %%%%%%--->", row.name,"==",this.state.selectedCampaign);
            if(row.campaign===this.state.selectedCampaign){
                ls.push(row)
            }
        }

        const deleteKey = ["org", "name"];
        const deleteTable = this.state.cqlTable;
        const sortKey = "position";
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <select
                            className={"form-control"}
                            onChange={(e)=>this.setState({selectedCampaign:e.target.value})}
                        >
                            <option value={""}>Choose campaign....</option>
                            {this.state.Campaigns.map((row,index)=>{
                                return(
                                    <option value={row.name}>{row.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        {renderListPrivateWithData(this,ls, "Structures", keyList, deleteKey, deleteTable, "showList", removeEntry, sortKey)}
                    </div>
                </div>

            </div>
        )
    }
    renderForm = () => {
        return renderForm(this.state.listField, "showList", this.state.cqlTable, this, this.submitEntry)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Customer Description

                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Record</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }


}
