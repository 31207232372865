'use strict';
import React from "react";
/*import  Janus  from 'janus-gateway';*/
import * as WebRTCAdapter from "webrtc-adapter";
let Janus = window.Janus;
let janus = null;
let sipcall = null;
let registered = false;
let AlertBox2 = null;
let started=false;
let supportedDevices;
let adapter = window.adapter;
let currentJsep;
let counterpartNum;
let incoming;
let streamIn = null;
let oldExtension = "00000";
let PropsIn = null;


const $=window.$;

export default (props)=>{
    PropsIn = props;
    let {extension, AlertBox} = props;
    AlertBox2 = AlertBox;


    if(oldExtension ===extension){
        //sorry dont process same sip info
        return false
    }else{
        oldExtension = extension
    }
    if(extension ==="00000"){
        return false
    }


    /**
     * check for browser support
     */
    if (!Janus.isWebrtcSupported()) {
        AlertBox2(false,"No WebRTC support... ",Janus.isWebrtcSupported());
        return;
    }

    /**
     * Our server url
     */
    const janusUrl = ["https://cloudcalls.easipath.com/janus"]; // ws://voip.easipath.com:8188

    /**
     * Let initialise our Janus object
     */
    Janus.init({
        debug: 'all',
        callback: function () {
            if (started) {
                return;
            }
            started = true;

            // create session
            janus = new Janus({
                server: janusUrl,
                success: function () {
                    // attach the sip plugin
                    janus.attach({
                        plugin: 'janus.plugin.sip',
                        success: function (pluginHandle) {
                            sipcall = pluginHandle;
                            Janus.log("(:)---> Plugin attached! (" + sipcall.getPlugin() + ", id=" + sipcall.getId() + ")",props);
                            registerExtension(props);
                        },
                        error: function (error) {
                            Janus.error("  -- Error attaching plugin...", error);
                        },
                        onmessage: function (msg, jsep) {
                            Janus.debug(" ::: Got a message ::: ");
                            Janus.debug(JSON.stringify(msg));
                            // Any error?
                            let error = msg["error"];
                            if (error !== null && error !== undefined) {
                                if (!registered) {
                                    Janus.log("User is not registered");
                                } else {
                                    // Reset status
                                    sipcall.hangup();
                                }
                                return;
                            }
                            const result = msg["result"];
                            onMessageEvent(result,msg,jsep,props);
                        },
                        onlocalstream: function (stream) {
                            Janus.debug(" ::: Got a local stream :::");
                            Janus.debug(JSON.stringify(stream));
                            //Janus.attachMediaStream(localVideo, stream);
                            Janus.attachMediaStream($('#video-device').get(0), stream);
                            Janus.attachMediaStream($('#roomaudio').get(0), stream);
                            streamIn = stream;

                        },
                        onremotestream: function (stream) {
                            Janus.debug(" ::: Got a remote stream :::");
                            Janus.debug(JSON.stringify(stream));
                            Janus.attachMediaStream($('#roomaudio').get(0), stream);
                            streamIn = stream;
                        },
                        oncleanup: function () {
                            Janus.log(" ::: Got a cleanup notification :::");
                        }
                    });
                },
                error: function (error) {
                    started = false;
                    registered = false;
                    Janus.error(error);
                    console.error("Janus error: " + error);

                },
                destroyed: function () {
                    started = false;
                    registered = false;
                }
            })

        },
    });

    return null;
}

/**
 * Additional function
 */

const doHangup = () => {
    const hangup = {"request": "hangup"};
    sipcall.send({"message": hangup});
    sipcall.hangup();
}
/**
 * sipcall.dtmf({dtmf: { tones: $(this).text()}}
 * @param props
 * @returns {boolean}
 */
const sendDtmf=(props)=>{
    let {dtmfTone}=props;
    sipcall.dtmf({
        dtmf: {
            tones: dtmfTone
        }
    })
}
const doCall = (props) => {
    let {to, host, AlertBox, isVideoCall}=props;
    console.log("DO CALL (:)---> ",props)
    if(sipcall===null){
         alert("Please register first before to make a call");
         return false
    }
    if (to === "") {
        AlertBox(false, "Please provide the correct number to call!");
        return false
    }
    let uri = "sip:" + to + "@" + host;
    let videoEnabled =false; // isVideoCall ? "video" : "audio";

    let media = {
        audioSend: true, audioRecv: true,
        videoSend: videoEnabled, videoRecv: videoEnabled
    };


    sipcall.createOffer({
            media: media,
            success: function (jsep) {
                Janus.debug("Got SDP!");
                Janus.debug(jsep);
                let body = {request: "call", uri: uri};
                sipcall.send({"message": body, "jsep": jsep});
                AlertBox(true, "Call request  " + JSON.stringify(body));
            },
            error: function (error) {
                Janus.error("WebRTC error...", error);
                AlertBox("WebRTC error... " + JSON.stringify(error));
            }
        });


    return true;
}
const answer = () => {
    incoming = null;
    getSupportedDevices(function () {
        sipcall.createAnswer({
            jsep: currentJsep,
            media: {
                audio: true,
                video: false
            },
            success: function (jsep) {
                Janus.debug("Got SDP! audio=" + true + ", video=" + true);
                Janus.debug(jsep);
                var body = {
                    request: "accept"
                };
                sipcall.send({
                    "message": body,
                    "jsep": jsep
                });
            },
            error: function (error) {
                Janus.error("WebRTC error:", error);
                var body = {
                    "request": "decline",
                    "code": 480
                };
                sipcall.send({
                    "message": body
                });
            }
        });
    });
};
const onMessageEvent=(result,msg,jsep,props)=>{
    const {extension,receivedIncomingCall,hangupIncomingCall,answerIncomingCall,onRegistry,onUnregistered,outboundCallStatus}=props;

    /**
     * let make sure to finish
     */
    if (result === null || result === undefined || result["event"] === undefined || result["event"] === null) {
        return
    }
    const event = result["event"];
    switch (event) {
        case 'registration_failed':
            Janus.error("Registration failed: " + result["code"] + " " + result["reason"]);
            return;
        case 'registered':
            Janus.log("Successfully registered as " + result["username"] + "!");
            if (!registered) {
                registered = true;
                documentTrigger('registered', extension);
            }
            onRegistry(result["username"]);
            break;

        case 'unregistered':
            Janus.log("Successfully unregistered as " + result["username"] + "!");
            if (registered) {
                registered = false;
                documentTrigger('unregistered');
            }
            onUnregistered(result["username"])
            break;

        case 'calling':
            Janus.log("Waiting for the peer to answer...");
            documentTrigger('calling', counterpartNum);
            outboundCallStatus("calling");
            break;

        case 'incomingcall':
            counterpartNum = msg.result.username.split('@')[0].split(':')[1];
            incoming = true;
            Janus.log("Incoming call from " + result["username"] + "!");


            receivedIncomingCall(event,msg,result,jsep);


            let doAudio = true, doVideo = true;
            let offerlessInvite = false;
            if(jsep !== null && jsep !== undefined) {
                // What has been negotiated?
                doAudio = (jsep.sdp.indexOf("m=audio ") > -1);
                doVideo = (jsep.sdp.indexOf("m=video ") > -1);
                Janus.debug("Audio " + (doAudio ? "has" : "has NOT") + " been negotiated");
                Janus.debug("Video " + (doVideo ? "has" : "has NOT") + " been negotiated");
            } else {
                Janus.log("This call doesn't contain an offer... we'll need to provide one ourselves");
                offerlessInvite = true;
                // In case you want to offer video when reacting to an offerless call, set this to true
                doVideo = false;
            }


            Janus.attachMediaStream($('#roomaudio').get(0), streamIn);
            currentJsep = jsep;
            documentTrigger('incomingcall', counterpartNum);
            break;

        case 'progress':
            Janus.log("There's early media from " + result["username"] + ", waiting for the call!");
            if (jsep !== null && jsep !== undefined) {
                handleRemote(jsep);
            }
            outboundCallStatus("progress");
            break;

        case 'accepted':
            Janus.log(result["username"] + " accepted the call!");
            if (jsep !== null && jsep !== undefined) {
                handleRemote(jsep);
            }
            documentTrigger('callaccepted', counterpartNum);
            answerIncomingCall(event,msg);
            outboundCallStatus("accepted");
            break;

        case 'hangup':
            incoming = null;
            Janus.log("Call hung up (" + result["code"] + " " + result["reason"] + ")!");
            sipcall.hangup();
            documentTrigger('hangup');
            hangupIncomingCall(event,msg,result,jsep);
            outboundCallStatus("hangup");
            break;

        default:
            break;
    }
}
const registerExtension=(props)=>{
    let {host, extension, secret, AlertBox, port, displayname} = props;
    if (sipcall === null) {
        console.log("SORRY we can continue with registration of sip due to empty SipCall");
        return;
    } else {
        console.log("OK SIP CALL Ready for registration");
    }
    let register = {
        "request": "register",
        "type": "guest"
    };
    let username = "sip:" + extension + "@" + host + ":" + port;
    if (!username === "" || username.indexOf("sip:") != 0 || username.indexOf("@") < 0) {
        AlertBox(false, "Please insert a valid SIP address (e.g., sip:goofy@example.com): this doesn't need to exist for guests, but is required");
        return true;
    }


    console.log("RECCCCA> ",props);
    register.username = username;
    if (displayname) {
        register.display_name = displayname;
    }

    if (host === "" || port === "") {
        AlertBox(false, "You didn't specify a SIP Registrar or Port to use!")
        return false;
    }
    let sipServer = "sip:" + host + ":" + port;

    if (secret === "") {
        AlertBox(false, "Insert the username secret (e.g., mypassword)");
    }

    let password = secret

    register = {
        request: "register",
        username: username,
        sips: false,
    };

    register.authuser = extension;
    register.display_name = displayname;
    register["secret"] = password;
    register["proxy"] = sipServer;
    sipcall.send({"message": register});
    registered = true
    console.log("ACCOUNT REGISTERED: ", register)
}
const getSupportedDevices = origCallback => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = function (callback) {
            navigator.mediaDevices.enumerateDevices().then(callback);
        };
    }

    let MediaDevices = [];
    let isHTTPs = window.location.protocol === 'https:';
    let canEnumerate = false;

    if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
        canEnumerate = true;
    } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        canEnumerate = true;
    }

    let hasMicrophone = false;
    let hasSpeakers = false;
    let hasWebcam = false;
    let isMicrophoneAlreadyCaptured = false;
    let isWebcamAlreadyCaptured = false;

    function checkDeviceSupport(callback) {
        if (!canEnumerate) {
            return;
        }

        if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
            navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
        }

        if (!navigator.enumerateDevices && navigator.enumerateDevices) {
            navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
        }

        if (!navigator.enumerateDevices) {
            if (callback) {
                callback();
            }
            return;
        }

        MediaDevices = [];
        navigator.enumerateDevices(function (devices) {
            devices.forEach(function (_device) {
                let device = {};
                for (var d in _device) {
                    device[d] = _device[d];
                }

                if (device.kind === 'audio') {
                    device.kind = 'audioinput';
                }

                if (device.kind === 'video') {
                    device.kind = 'videoinput';
                }

                let skip;
                MediaDevices.forEach(function (d) {
                    if (d.id === device.id && d.kind === device.kind) {
                        skip = true;
                    }
                });

                if (skip) {
                    return;
                }

                if (!device.deviceId) {
                    device.deviceId = device.id;
                }

                if (!device.id) {
                    device.id = device.deviceId;
                }

                if (!device.label) {
                    device.label = 'Please invoke getUserMedia once.';
                    if (!isHTTPs) {
                        device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
                    }
                } else {
                    if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
                        isWebcamAlreadyCaptured = true;
                    }

                    if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
                        isMicrophoneAlreadyCaptured = true;
                    }
                }

                if (device.kind === 'audioinput') {
                    hasMicrophone = true;
                }

                if (device.kind === 'audiooutput') {
                    hasSpeakers = true;
                }

                if (device.kind === 'videoinput') {
                    hasWebcam = true;
                }

                // there is no 'videoouput' in the spec.
                MediaDevices.push(device);
            });

            if (callback) {
                callback();
            }
        });
    }

    // check for microphone/camera support!
    checkDeviceSupport(function () {
        supportedDevices = {
            audio: hasMicrophone,
            audioCap: isMicrophoneAlreadyCaptured,
            video: adapter.browserDetails.browser === 'chrome' ? hasWebcam : false,
            videoCap: adapter.browserDetails.browser === 'chrome' ? isWebcamAlreadyCaptured : false
        };
        Janus.log(supportedDevices);
        origCallback();
    });
};
const handleRemote = jsep => {
    sipcall.handleRemoteJsep({
        jsep: jsep,
        error: function () {
            const hangup = {
                "request": "hangup"
            };
            sipcall.send({
                "message": hangup
            });
            sipcall.hangup();
        }
    });
};
const documentTrigger=(msg,adds)=>{
    console.log("(:)-Trigger > ",msg,adds);
    if(msg==="incomingcall"){

    }
}

export {
    doHangup,
    doCall,
    answer,
    registerExtension,
    sendDtmf
}


