import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class myCalendar extends React.Component{
    constructor(props){
        super(props);
        this.state={
            targetDate:new Date()
        }
    }
    handleChangeDate = (date) => {
        this.setState({
            targetDate: date
        });
        const dateString  = this.convertDateFormat_YYYYMMDD(date);
        this.props.setDate(dateString);
    }
    convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }

    render(){
        return(
            <div style={this.props.style}>
            <DatePicker
                selected={this.state.targetDate}
                onChange={this.handleChangeDate}
                className="form-control"
            />
            </div>
        )
    }
}
export default myCalendar;
