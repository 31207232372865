import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    deleteEntryCRM_WithData,
    submitEntryCRM_WithData
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import TreeRecrusive from "../../component/TreeView/Recrusive";
import {uuid} from "uuidv4";
import uuidv4  from "uuidv4";

const listField = [
    {
        field: "treeNodeChild",
        label: "Node Name",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "topicTitle",
        label: "Topic Tile",
        options: [],
        type: "text",
        required: false,
        dataType: "string"
    },
    {
        field: "topicContent",
        label: "Topic Comment",
        options: [],
        type: "text",
        required: false,
        dataType: "string"
    },
];

const defaultTree=[
    /*{id:uuid(),name:"faculty",parent:"",topic:"",title:"",description:""},
    {id:uuid(),name:"computer science",parent:"faculty",topic:"",title:"",description:""},
    {id:uuid(),name:"information and design",parent:"faculty",topic:"",title:"",description:""},

    {id:uuid(),name:"IT",parent:"computer science",topic:"",title:"",description:""},
    {id:uuid(),name:"Software",parent:"IT",topic:"",title:"",description:""},
    {id:uuid(),name:"Networking",parent:"IT",topic:"",title:"",description:""},
    {id:uuid(),name:"Business Analysis",parent:"IT",topic:"",title:"",description:""},

    {id:uuid(),name:"TV",parent:"journalism",topic:"",title:"",description:""},
    {id:uuid(),name:"Media",parent:"journalism",topic:"",title:"",description:""},
    {id:uuid(),name:"graphic",parent:"Media",topic:"",title:"",description:""},
    {id:uuid(),name:"audio",parent:"Media",topic:"",title:"",description:""},*/

];

class FilterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            module:"support",
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            treeItems:defaultTree,
            treeItems2:[],
            treeNodeParent:"",
            treeNodeChild:"",
            showTreeNodeEntry:false,
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }

    submitNewNode=async ()=>{

        const newNode={
            parent:this.state.treeNodeParent,
            child:this.state.newEntry.treeNodeChild,
            detail:{
                TopicTitle:this.state.newEntry.topicTitle,
                TopicContent:this.state.newEntry.topicContent
            }
        }
        let tmp=this.state;
        tmp.treeItems.push(newNode);
        tmp.newEntry = newNode;
        this.setState(tmp);

        //todo uncomment after
        let hub={
            ...newNode,
            Org: GetUserToken().OrgCode,
            Module: this.state.module,
        };
        let endpoint = "/reservation/insert/solutions";
        let reloadFunction = [this.props.LoadTicketData,this.cancelAddNode];
        await submitEntryCRM_WithData(undefined, hub, endpoint, reloadFunction, this);

    }

    onRemoveNode=async (parent,child)=>{

        let hub={
            Org: GetUserToken().OrgCode,
            Module: this.state.module,
            Child:child,
            Parent:parent
        }

        console.log("onRemoveNode: XXX--> ",hub);
        return
        let endpoint = "/reservation/remove/solutions";
        let reloadFunction = [this.props.LoadTicketData,this.cancelAddNode];
        await deleteEntryCRM_WithData(undefined, hub, endpoint, reloadFunction, this);

    }

    componentDidMount() {
        //todo

    }
    openAddNode=(parent)=>{
        this.setState({
            treeNodeParent:parent,
            treeNodeChild:"",
            showTreeNodeEntry:true,
        })
    }
    cancelAddNode=()=>{
        this.setState({
            treeNodeParent:"",
            treeNodeChild:"",
            showTreeNodeEntry:false,
        })
    }

    renderEditFormFilter=()=>{
        if(!this.state.showTreeNodeEntry){
            return null
        }
        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Util " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitNewNode}
                onCancel={this.cancelAddNode}
            />
        )
    }
    testOpen=(parent)=>{
        alert("Test: "+parent);
        this.setState({
            treeNodeParent:parent,
            treeNodeChild:"",
            showTreeNodeEntry:true,
        })
    }


    getTreeItems=()=>{

        return this.props.enquiry.Filters||[];

    }

    render() {
        return (
            <Wallpaper {...this.props} >

                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        <h2>
                            List of tree
                            <button
                                onClick={()=>this.openAddNode("")}
                                style={{float:"right"}}
                            >Add Node</button>
                        </h2>


                        <TreeRecrusive
                            treeItems={this.getTreeItems()}
                            openNode={this.openAddNode}
                            testOpen={this.testOpen}
                            newNode={this.openAddNode}
                            onRemoveNode={this.onRemoveNode}
                            />

                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" >
                        {this.renderEditFormFilter()}
                    </GridItem>
                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(FilterPage);
