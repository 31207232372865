import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";

class ComPbxInbound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Inbounds: [],
            Queues:[],
            newInbound: {org: "", name: "", inboundnumber: "", queue: ""},
            isListInbound: true,
        }

        /*
        Org           string
	Name          string
	InboundNumber string
	Queue         string
        */
    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});

        await this.fetchQueue();
        await this.fetchInbound()
    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchInbound = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }
        console.log("fetchInbound--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxInbound", conds, (data) => {
            console.log("fetchInbound :> ", data);
            _this.setState({
                Inbounds: data,
            });
        });
    }
    fetchQueue = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }
        console.log("fetchQueue--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxQueue", conds, (data) => {
            console.log("fetchQueue :> ", data);
            _this.setState({
                Queues: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitInbound = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this inbound!" + this.state.newInbound.name)) {
            return
        }
        let _this = this;
        const state = this.state.newInbound;


        let hub = {
            org: state.org,
            name: state.name,
            inboundnumber:state.inboundnumber,
            queue: state.queue,
        };


        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/inbound/new";

        console.log("submitInbound send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitInbound response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new Inbound success!");
                let tmp = _this.state;
                tmp.newInbound = {org: "", name: "", inboundnumber: "", queue: ""};
                tmp.isListInbound = true;
                _this.setState(tmp);
                _this.fetchInbound();
            } else {
                alert("Error something went wrong!!")
            }

        });

    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getStateValue = (key) => {
        return this.state[key];
    }
    deleteEntry = async (row) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxInbound"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("deleteEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("deleteEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, trunk deleted success!");

                _this.fetchTrunk();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    requestNewInbound = () => {
        let newTrunk = this.state.newInbound;
        const user = GetUserToken();
        newTrunk.org = user.OrgCode;
        this.setState({
            isListInbound: false,
            newInbound:newTrunk
        })
    }

    renderNewInbound= () => {
        const state = this.state;
        console.log("renderNewInbound > ",state.isListInbound);
        if (state.isListInbound) {
            return null
        }
        let _this = this;

        const listField = ["org", "name"];
        const getValue = (myKey) => {
            return _this.state.newInbound[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp.newInbound[myKey] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <div className={""}>
                <p className={"alert alert-danger"}>New Range entry</p>
                <form onSubmit={this.submitInbound}>
                    {listField.map((item) => {
                        if(item==="org"){
                            const user=GetUserToken();
                            if(user.Role==="super"){
                                return (
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={"Enter " + item.toUpperCase() + " ..."}
                                            onChange={(e) => setValue(e, item)}
                                            value={this.getStateValue(item)}
                                            required={true}
                                        />
                                    </div>
                                )
                            }
                            return (
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={"Enter " + item.toUpperCase() + " ..."}
                                        onChange={(e) => setValue(e, item)}
                                        value={user.OrgCode}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter " + item.toUpperCase() + " ..."}
                                    onChange={(e) => setValue(e, item)}
                                    value={this.getStateValue(item)}
                                    required={true}
                                />
                            </div>
                        )
                    })}

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Inbound Number</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={"Enter inbound number ..."}
                            onChange={(e) => setValue(e, "inboundnumber")}
                            value={this.getStateValue("inboundnumber")}
                            required={true}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Queue</label>
                        <select
                            className="form-control"
                            onChange={(e) => setValue(e, "queue")}
                            value={this.getStateValue("queue")}
                            required={true}
                        >
                            <option value={""}>Select your queue</option>
                            {this.state.Queues.map((item,index)=>{
                                return(
                                    <option value={item.name}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <button className={"btn btn-danger pull-left"} type={"button"}
                                onClick={() => {
                                    this.setState({
                                        isListInbound: true,
                                    })
                                }}
                        >
                            Cancel
                        </button>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    renderInboundList = () => {

        const state = this.state;
        if (!state.isListInbound) {
            return null
        }

        let ls = this.state.Inbounds;

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Name</th>
                    <th>Inbound Number</th>
                    <th>Queue</th>
                    <th>Updated</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={index}>
                            <th>{row.org}</th>
                            <th>{row.name}</th>
                            <th>{row.inboundnumber}</th>
                            <th>{row.queue}</th>
                            <th>{row.date + " " + row.time}</th>
                            <th>

                                <a className={"btn btn-default"} onClick={() => this.deleteEntry(row)}>Delete</a>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Sip Trunk

                                <a onClick={() => this.requestNewInbound()} className={"btn btn-primary pull-right"}>New
                                    Inbound</a>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderInboundList()}
                        {this.renderNewInbound()}
                    </div>
                </div>


            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComPbxInbound)