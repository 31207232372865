import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class SettingDealerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Cdr: null,
            startDate: "",
            endDate: "",
        }

    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});



    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchCdr= async () => {
        this.setState({
            Cdr: null,
        });
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        let hub={};

        if (!this.isSupperUser()) {
            hub.org =  user.OrgCode;
        }else{
            hub.org =this.state.searchOrg;
        }
        // eslint-disable-next-line no-undef
        hub.startDate =moment(this.state.startDate).format("YYYY-MM-DD");
        hub.endDate =moment(this.state.endDate).format("YYYY-MM-DD");



        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/cdr/report";

        console.log("fetchCdr send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("fetchCdr response > ", dataIn);
            if(dataIn===null){
                return
            }
            if (typeof dataIn.RESULT !== "undefined") {
                let tmp = _this.state;
                tmp.Cdr =dataIn.RESULT;
                _this.setState(tmp);
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitSearch = async (e) => {
        e.preventDefault();

        if(this.state.startDate ===""){
            return alert("Please provide your start date!")
        }
        if(this.state.endDate ===""){
            return alert("Please provide your end date!")
        }

        if (!this.isSupperUser()) {
            if(this.state.searchOrg ===""){
                return alert("Please provide your organization!")
            }
        }

        this.fetchCdr();

    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }


    renderSearchForm= () => {
        const state = this.state;
        let _this = this;

        const getValue = (myKey) => {
            return _this.state[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp[myKey] = e.target.value;
            _this.setState(tmp);
        }

        let  user=GetUserToken();
        if(user ===null){
            user={};
            user.Role="guest"
        }

        return (
            <div className={"row"}>
                <form onSubmit={this.submitSearch}>
                    {user.Role==="super"&&(
                        <div className={"col col-lg-4"}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Organization</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter Organization ..."}
                                    onChange={(e) => setValue(e, "searchOrg")}
                                    value={getValue("searchOrg")}
                                    required={true}
                                />
                            </div>
                        </div>
                    )}

                    <div className={"col col-lg-3"}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Start Date</label>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                className="form-control"
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={"col col-lg-3"}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">End Date</label>
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                className="form-control"
                                required={true}
                            />
                        </div>
                    </div>

                    <div className={"col col-lg-2"}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">&nbsp;</label>
                            <br/>
                            <button className={"btn btn-primary pull-right"} type={"submit"}>
                                Search
                            </button>
                        </div>
                    </div>



                </form>
            </div>
        )
    }
    renderRangeList = () => {

        const state = this.state;
        if (state.Cdr ===null) {
            return null
        }
        let ls =[];

        if(typeof state.Cdr.Cdr !=="undefined"){
            ls = state.Cdr.Cdr
        }

        if (ls ===null) {
            return <div className={"alert alert-danger"}>Empty result</div>
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Extension</th>
                    <th>Customer</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Disposition</th>
                    <th>Duration</th>
                    <th>BillSec</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    return (
                        <tr key={index}>
                            <td>{row.Org}</td>
                            <td>{row.Extension}</td>
                            <td>{row.Dst}</td>
                            <td>{row.Start}</td>
                            <td>{row.End}</td>
                            <td>{row.Disposition}</td>
                            <td>{row.Duration}</td>
                            <td>{row.Billsec}</td>

                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    renderSummaryReport=()=>{
        let state = this.state;
        if(state.Cdr ===null){
            return null
        }

        const cdr = state.Cdr;

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Attempted</th>
                    <th>Answered</th>
                    <th>Duration/min</th>
                    <th>Billing/min</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th style={{color:"red"}}>{cdr.TotalAttempted}</th>
                    <th style={{color:"red"}}>{cdr.TotalAnswered}</th>
                    <th style={{color:"red"}}>{cdr.TotalDurationMinute.toFixed(0)}</th>
                    <th style={{color:"red"}}>{cdr.TotalBillMinute.toFixed(0)}</th>
                </tr>
                </tbody>
            </table>
        )

    }

    renderSummaryReportByDispostion=()=>{
        let state = this.state;
        if(state.Cdr ===null){
            return null
        }

        const cdr = state.Cdr;

        let ls = [];
        if(cdr.TotalByDisposition ===null){
            return null
        }

        for(let i in cdr.TotalByDisposition){
            const dispostion = i;
            const tot = cdr.TotalByDisposition[i];
            ls.push({
                disp:dispostion,
                tot:tot
            })
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Dispostion</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={index}>
                            <td>{row.disp}</td>
                            <td style={{color:"red",fontSize:18}}>{row.tot}</td>
                        </tr>
                        )

                })}
                </tbody>
            </table>
        )

    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>

                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Call Duration Report (CDR) Search
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderSearchForm()}
                    </div>
                </div>
                <div className="row container">
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                </div>

                    {this.state.Cdr !== null ?
                        <Tabs>
                            <Tab label="Summary">
                                <div className="row container">
                                    <div className="col-xs-12 alert alert-default">
                                        {this.renderSummaryReport()}
                                        <hr/>
                                    </div>
                                </div>
                            </Tab>

                            <Tab label="Disposition">
                                <div className="row container">
                                    <div className="col-xs-12 alert alert-default">
                                        {this.renderSummaryReportByDispostion()}
                                        <hr/>
                                    </div>
                                </div>
                            </Tab>

                            <Tab label="Detail">
                                <div className="row container">
                                    <div className="col-xs-12 alert alert-default">
                                        {this.renderRangeList()}
                                        <hr/>
                                    </div>
                                </div>
                            </Tab>


                        </Tabs>
                        :
                        <p>Waiting for search result.......</p>
                    }


                </MuiThemeProvider>

            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingDealerComponent)
