import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";

class SettingDealerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Trunks: [],
            newRange: {org: "", name: "", start: "", end: "", secret: ""},
            newSecretRange: {secret: ""},
            currentTrunk: null,
            isListRange: true,
            isNewTrunk: false,
            isNewSecretRange: false,
        }


    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});

        await this.fetchRange();

    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchRange = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }
        console.log("fetchTrunk--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxRange", conds, (data) => {
            console.log("fetchTrunk :> ", data);
            _this.setState({
                Trunks: data,
            });
        });
    }
    fetchAgents = async () => {
        let _this = this;
        let conds = [];
        this.fetchingAll("agent", conds, (data) => {
            console.log("fetchAgents :> ", data);
            let ls = [];
            let lsMap = {};
            for (let i in data) {
                const row = data[i];
                //console.log("+++++++++++>> ",row.dealer," == ",_this.state.ref);
                //if(row.dealer ===_this.state.ref){
                ls.push(row);
                lsMap[row.ref] = row;
                //}
            }
            _this.setState({
                Agents: ls,
                AgentsMap: lsMap
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitRange = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this range!" + this.state.newRange.name)) {
            return
        }
        let _this = this;
        const state = this.state.newRange;


        let hub = {
            org: state.org,
            name: state.name,
            start: parseFloat(state.start),
            end: parseFloat(state.end),
            secret: state.secret,
        };


        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/range/new";

        console.log("submitTrunk send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitTrunk response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new range success!");
                let tmp = _this.state;
                tmp.newRange = {org: "", name: "", start: "", end: "", secret: ""}
                tmp.isListRange = true;
                tmp.isNewTrunk = false;
                tmp.isNewSecretRange = false;
                _this.setState(tmp);
                _this.fetchRange();
            } else {
                alert("Error something went wrong!!")
            }

        });

    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getStateValue = (key) => {
        return this.state[key];
    }
    deleteEntry = async (row) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxRange"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("submitTrunk send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitTrunk response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, range deleted success!");

                _this.fetchRange();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    UpdateSecretRangeEntry = async () => {
        const row = this.state.currentTrunk;
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let fields = [
            {key: "secret", type: "string", val: this.state.newSecretRange.secret},
        ];

        let hub = {
            FieldName: "secret",
            fieldValue: this.state.newSecretRange.secret,
            conds: conds,
            Fields: fields,
            tablename: "PbxRange"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/update/entry";

        console.log("UpdateSecretRangeEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("UpdateSecretRangeEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, range secret updated success!");
                let tmp = _this.state;
                tmp.newSecretRange = {secret: ""};
                tmp.isListRange = true;
                tmp.isNewTrunk = false;
                tmp.isNewSecretRange = false;
                _this.setState(tmp);
                _this.fetchRange();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    requestNewRange = () => {
        this.setState({
            isListRange: false,
            isNewTrunk: true,
            isNewSecretRange: false,
        })
    }
    requestChangeRangePassword = (row) => {
        this.setState({
            currentTrunk: row,
            isListRange: false,
            isNewTrunk: false,
            isNewSecretRange: true,
        })
    }

    renderNewSecretRange = () => {
        const state = this.state;
        if (!state.isNewSecretRange) {
            return null
        }
        let _this = this;
        const listField = ["secret"];
        const getValue = (myKey) => {
            return _this.state.newSecretRange[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp.newSecretRange[myKey] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <div className={""}>
                <p className={"alert alert-danger"}>New Secret[{state.currentTrunk.org + " " + state.currentTrunk.name}]
                    old Secret: {state.currentTrunk.secret} </p>
                <form onSubmit={this.UpdateSecretRangeEntry}>
                    {listField.map((item) => {
                        return (
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter " + item.toUpperCase() + " ..."}
                                    onChange={(e) => setValue(e, item)}
                                    value={getValue(item)}
                                    required={true}
                                />
                            </div>
                        )
                    })}
                    <div className="form-group">
                        <button className={"btn btn-danger pull-left"} type={"button"}
                                onClick={() => {
                                    this.setState({
                                        isListRange: true,
                                        isNewTrunk: false,
                                        isNewSecretRange: false,
                                    })
                                }}
                        >
                            Cancel
                        </button>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    renderNewRange = () => {
        const state = this.state;
        if (!state.isNewTrunk) {
            return null
        }
        let _this = this;
        const listField = ["org", "name", "start", "end", "secret"];
        const getValue = (myKey) => {
            return _this.state.newRange[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp.newRange[myKey] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <div className={""}>
                <p className={"alert alert-danger"}>New Range entry</p>
                <form onSubmit={this.submitRange}>
                    {listField.map((item) => {
                        return (
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter " + item.toUpperCase() + " ..."}
                                    onChange={(e) => setValue(e, item)}
                                    value={this.getStateValue(item)}
                                    required={true}
                                />
                            </div>
                        )
                    })}
                    <div className="form-group">
                        <button className={"btn btn-danger pull-left"} type={"button"}
                                onClick={() => {
                                    this.setState({
                                        isListRange: true,
                                        isNewTrunk: false,
                                        isNewSecretRange: false,
                                    })
                                }}
                        >
                            Cancel
                        </button>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    renderRangeList = () => {

        const state = this.state;
        if (!state.isListRange) {
            return null
        }

        let ls = this.state.Trunks;

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Secret</th>
                    <th>Updated</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={index}>
                            <th>{row.org}</th>
                            <th>{row.name}</th>
                            <th>{row.start}</th>
                            <th>{row.end}</th>
                            <th>{row.secret}</th>
                            <th>{row.date + " " + row.time}</th>
                            <th>
                                <a className={"btn btn-default"} onClick={() => this.requestChangeRangePassword(row)}>Change
                                    Secret</a>
                                &nbsp;
                                &nbsp;
                                {this.isSupperUser() && (
                                    <a className={"btn btn-default"} onClick={() => this.deleteEntry(row)}>Delete</a>
                                )}

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Sip Range
                                {this.isSupperUser() && (
                                    <a onClick={() => this.requestNewRange()} className={"btn btn-primary pull-right"}>New
                                        Range</a>
                                )}
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderRangeList()}
                        {this.renderNewRange()}
                        {this.renderNewSecretRange()}
                    </div>
                </div>


                {/*<div className="row container">
                    <div className="col-xs-12">
                        <p className={"alert alert-info"} style={{fontSise: 16, fontWeight: "bold"}}>Sale</p>
                        <form onSubmit={this.submitRecharge}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Recharge phone number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter phone number..."
                                    onChange={(e)=>this.handleInput(e,"rechargeNumber")}
                                    value={this.getStateValue("rechargeNumber")}
                                />
                                <small id="emailHelp" className="form-text text-muted">
                                    Your number need to be in 10 digit e.g. 0729139504
                                </small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Recharge Amount</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Recharge Amount..."
                                    onChange={(e)=>this.handleInput(e,"rechargeAmount")}
                                    value={this.getStateValue("rechargeAmount")}
                                />
                                <small id="emailHelp" className="form-text text-muted">
                                    Your amount must be less then your balance
                                </small>
                            </div>
                            <div className="form-group">
                                <button className={"btn btn-primary pull-right"} type={"submit"}>
                                    Submit
                                </button>
                            </div>


                        </form>

                    </div>
                </div>*/}

            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingDealerComponent)