
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    addNewRecord:{
        float: "right"
    },
}));

export default ({name,label,options,handleInput,onClickButton,btnText,includeBtn}) =>{
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const handleChange = event => {
        setAge(event.target.value);
        handleInput(event);
    };
    let sizeFrame=10;
    if(!includeBtn){
        sizeFrame=12;
    }
    return (
        <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin}>
            <FormLabel component="legend" className={"legend"}>{label}</FormLabel>

            <FormControl className={classes.margin}>
                <Grid container sm={12} style={{marginTop: 35, minHeight: "100%",minWidth:"100%"}}>
                    <Grid item sm={sizeFrame}>
                        <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>
                        <NativeSelect
                            name={name}
                            id="demo-customized-select-native"
                            value={age}
                            onChange={handleChange}
                            input={<BootstrapInput />}
                            style={{minWidth:"100%"}}
                        >
                            <option value="" >Choose...</option>
                            {options.map((item) => {
                                return (
                                    <option value={item.key}>{item.val}</option>
                                )
                            })}

                        </NativeSelect>
                    </Grid>
                    {!sizeFrame?
                    <Grid item sm={2}>
                        <br/>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.addNewRecord}
                            onClick={()=>onClickButton()}
                        >
                            {btnText}
                        </Button>
                    </Grid>:null}
                </Grid>


            </FormControl>
        </FormControl>
    );
}
