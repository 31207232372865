

const SERVER_RESERVATION = "ServerReservation";
const SERVER_CRM="ServerCRM";
const CURRENT_DATABASE="reservation";

const DEFAULT_MODULES=[
    {key: "vehicle", val: "Vehicle"},
    {key: "driver", val: "Driver"},
    {key: "authorizer", val: "Authorizer"},
    {key: "customer", val: "Customer"},

];

export{
    SERVER_RESERVATION,
    SERVER_CRM,
    CURRENT_DATABASE,
    DEFAULT_MODULES
}
