import React, {Component} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/agent-dashboard.css';
import PageContentWallpaper from '../common/ef-pageContent';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import FlatButton from "material-ui/FlatButton";
//import Dialog from "material-ui/Dialog";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from "material-ui/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import loader from '../../asset/img/ajax_loader.gif';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import moment from "moment";





export default class ComLeadSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SearchData: [],
            LeadInfo: {},
            searchKey:"",
            leadRef:"",
            showLeadInfo:false
        }
    }

    async componentDidMount() {

    }

    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    submitSearchNew = async (e) => {
        e.preventDefault();
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.SearchKey = this.state.searchKey;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/search/new";
        console.log("submitSearch send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                console.log("submitSearch response > ", data);
                _this.setState({
                    SearchData: data
                })
            }
        });
    }
    submitSearchInfo = async (searchKey) => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.LeadRef =searchKey; // this.state.leadRef;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/search/info";
        console.log("submitSearchInfo send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                console.log("submitSearchInfo response > ", data);
                _this.setState({
                    LeadInfo: data,
                    leadRef: searchKey,
                    showLeadInfo:true,
                })
            }
        });
    }

    processLeadPress=()=>{
        const leadRef =this.state.LeadInfo.LeadRef;
        const keyStore="cclms-request-lead";
        window.localStorage.setItem(keyStore,leadRef);
        this.closeShowLeadInfoPopup();
        window.location.href="#lead-worker";
    }

    closeShowLeadInfoPopup=()=>{
        this.setState({
            LeadInfo: {},
            leadRef: "",
            showLeadInfo:false,
        })
    }

    buildDisplayLeadField=()=>{

        const leadStore=this.state.LeadInfo.LeadStore;
        const builders=(arrIn)=>{
            let st  = "";
            for(let i in arrIn){
                const row = arrIn[i];
                if(row.FieldValue !==""){
                    st=st + row.FieldValue+",";
                }
            }
            st = st.trim();
            return st
        }
        let str = builders(leadStore.Name);
        str = str +builders(leadStore.Contact);
        str = str +builders(leadStore.Identity);
        str = str +builders(leadStore.Email);
        str = str +builders(leadStore.Other);
        return str;
    }

    renderLeadTableDetail = () => {
        const lead = this.state.LeadInfo;
        const arrKey = ["Name", "Contact", "Email", "Identity", "Other"];
        let ls = [];
        if (lead !== null) {
            for (let x in arrKey) {
                const myKey = arrKey[x];

                let arr = lead.LeadStore[myKey];//
                for (let i in arr) {
                    const row = arr[i];
                    const arrAuthor = row.Author.split("--");
                    ls.push({
                        key: row.FieldName,
                        val: row.FieldValue,
                        update: row.Updated,
                        author: arrAuthor[0]
                    });
                }
            }
        } else {
            return (
                <p className={"alert alert-info"}>Empty list </p>
            )
        }

        let currentStatus ="uploaded";
        if(lead.LeadStatus.StatusName !==""){
            currentStatus=lead.LeadStatus.StatusName;
        }

        return (
            <div>
                {currentStatus==="uploaded"?
                    <h2 className={"alert alert-danger"}>Current Status: {currentStatus}</h2>:
                    <h2 className={"alert alert-success"}>
                        Current Status: {currentStatus}
                        <button
                            onClick={()=>this.processLeadPress()}
                        >Process</button>
                    </h2>
                }
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>

                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {
                        return (
                            <tr key={index}>
                                <th>{row.key}</th>
                                <td style={{color: "red", fontSize: 24}}>{row.val}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )


    }

    renderSearchResult=()=>{


        return(
            <table className={"table"} style={{width:"100%"}}>
                <thead>
                <tr>
                    <th>Data</th>
                    <td>Field</td>
                    <td>Batch</td>
                    <td>Updated</td>
                    <td>#</td>
                </tr>
                </thead>
                <tbody>
                {this.state.SearchData.map((row,x)=>{

                    return(
                        <tr key={x}>
                            <th>{row.Data}</th>
                            <td>{row.Field}</td>
                            <td>{row.BatchName}</td>
                            <td>{row.OrgDateTime}</td>
                            <td>
                                <a onClick={()=>this.submitSearchInfo(row.Ref)} className={"btn btn-default"}>View</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    renderShowLeadInfoPopup() {

        if (!this.state.showLeadInfo) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowLeadInfoPopup}
            />,
        ];



        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Info Lead Detail"}
                    actions={actions}
                    modal={false}
                    open={this.state.showLeadInfo}
                    onRequestClose={this.closeShowLeadInfoPopup}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className="col-lg-12">
                        {this.renderLeadTableDetail()}
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )

    }

    render() {

        const _this = this;
        const getNewFieldEntryValue = (key) => {
            return _this.state[key];
        }
        const setNewFieldEntryValue = (e, key) => {
            let tmp = _this.state;
            tmp[key] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>
                    <div className={"container"}>
                        <div className="row">
                            <div className="col-xs-12">
                                <h3>Search Lead</h3>
                            </div>
                        </div>
                        <div className="row alert alert-info">
                            <form  onSubmit={this.submitSearchNew}>

                                <div className="col-xs-10">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Key search</label>
                                        <input
                                            type={"text"}
                                            className="form-control"
                                            placeholder={"Type your key..."}
                                            required={true}
                                            value={getNewFieldEntryValue("searchKey")}
                                            onChange={(e) => setNewFieldEntryValue(e, "searchKey")}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-2">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">&nbsp;</label>
                                        <button type={"submit"} className="form-control">Search</button>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                {this.renderSearchResult()}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                {this.renderShowLeadInfoPopup()}
                            </div>
                        </div>



                    </div>

                </MuiThemeProvider>
            </PageContentWallpaper>
        )
    }
}