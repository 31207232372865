
import React from 'react';
import {GetUserToken, PostRequestHTTP} from "../../api/services";

export default class AddingModelFilter extends React.Component{
    constructor(props){
        super(props);
        this.state={
            List:[],
            Selected:null,
            field:""
        }
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount(){
        this.fetchData();
    }
    async fetchData(){
        const _this = this;
        let endpoint = this.props.Crum;

        const user = GetUserToken();

        let data = this.state;
        const hub = {
            [this.props.PrimaryKey]: user.OrgCode,
            ServiceName: "select",
            TableName: this.props.row.filterTable,
            Data: {},
            ParamUpdate: [],
            ParamConditions: []
        }

        if(typeof this.props.row.filterConditionOrgCode !=="undefined"){
            hub.ParamConditions.push({
                key:this.props.row.filterConditionOrgCode,
                val: user.OrgCode,
                type:"string"
            });

            console.log("filterConditionOrgCode > ",hub,this.props);
        }


        await PostRequestHTTP(hub, endpoint, function (data) {
            const res = data.RESULT;

            let ls=[];
            if(typeof _this.props.row.extraConditions !=="undefined"){
                for(let i in res){
                    const item =  res[i];
                    let boo=true;
                    for(let a in _this.props.row.extraConditions){
                        const row = _this.props.row.extraConditions[a];
                        if(item[row.key] !==row.val){
                            boo=false
                        }
                    }
                    if(boo){
                        ls.push(item);
                    }
                }
            }else{
                ls = res;
            }

            _this.setState({List: ls})

        });


    }

    handleInput=(e)=>{
        this.setState({Selected:e.target.value});
        this.props.handleInput(e,this.props.field);
    }
    getValue=()=>{
        return this.props.getValue(this.props.field);
    }

    renderOptions=()=>{
        return this.state.List.map((row)=>{
            const models = this.props.state.MyModel;
            const allData = this.props.state.EntryData;
            let dependencies = null;// this.props.row.dependency;

            if(typeof this.props.row.dependency !=="undefined"){
                dependencies = this.props.row.dependency;
                let boo = true;
                for(let a in dependencies){
                    let keyDependency = dependencies[a];
                    let compareValue = row[keyDependency];
                    for(let i in allData){
                        const val = allData[i];
                        if(i ===keyDependency){
                            console.log("key--ZZZZZZZ---> ",i," == ",keyDependency," > ",(i ===keyDependency));
                            console.log("val--ZZZZZZZ---> ",compareValue," !== ",val," > ",(compareValue !==val)," > ",row);
                            if(compareValue !==val){
                                boo=false;
                            }
                        }
                    }
                }

                if(!boo){
                    return null
                }
            }



            return(
                <option
                    value={row[this.props.row.filterKey]}
                >
                    {row[this.props.row.filterValue]}
                </option>
            )
        })
    }

    render(){
        return(
            <div className="form-group">
                <label>{this.props.row.desc}</label>
                <select
                    type={this.props.row.type}
                    className="form-control"
                    id={this.props.row.field}
                    aria-describedby={this.props.row.field + "Help"}
                    placeholder={this.props.row.placeholder}
                    required={this.props.row.required}
                    onChange={(e) => this.props.handleInput(e, this.props.row.field)}
                    value={this.getValue(this.props.row.field)}
                >
                    <option>--Select--</option>
                    {this.renderOptions()}
                </select>

            </div>
        )
    }
}