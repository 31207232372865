import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Badge from 'material-ui/Badge';

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

class ComPbxAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewAllocate: false,
            login: null,
            Trunks: [],
            Agents: [],
            Users:[],
            Ranges: [],
            newAgent: {
                Org: "",
                Username: "",
                Extension: 0,
                Agent:"",
                Fullname: "",
                Trunk: "",
                OutboundNumber: "",
                Name: "",
                Surname: "",
            },
        }


    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});

        await this.fetchTrunk();
        await this.fetchRange();
        await this.fetchAgent();
        await this.fetchLeadUsers();

    }

    isSupperUser = () => {
        let user = GetUserToken();
        if (user.Role === "super") {
            return true
        }
        return false
    }
    fetchLeadUsers=async()=>{

            let _this = this;
            let conds = [{
                key:"org",
                val:GetUserToken().OrgCode
            }];
            this.fetchingAll("worker", conds, (data) => {
                console.log("fetchAgents :> ", data);
                _this.setState({
                    Users: data,
                });
            });

    }
    fetchAgent = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        //if (!this.isSupperUser()) {
        conds.push({
            type: "string",
            key: "org",
            val: user.OrgCode
        })
        //}
        console.log("fetchAgent--->> ", conds, " -- ", this.isSupperUser())
        this.fetchingAll("PbxAgent", conds, (data) => {
            console.log("fetchQueue :> ", data);
            _this.setState({
                Agents: data,
            });
        });
    }
    fetchRange = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        //if (!this.isSupperUser()) {
        conds.push({
            type: "string",
            key: "org",
            val: user.OrgCode
        })
        //}
        console.log("fetchRange--->> ", conds, " -- ", this.isSupperUser())
        this.fetchingAll("PbxRange", conds, (data) => {
            console.log("fetchQueue :> ", data);
            _this.setState({
                Ranges: data,
            });
        });
    }
    fetchTrunk = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        //if (!this.isSupperUser()) {
        conds.push({
            type: "string",
            key: "org",
            val: user.OrgCode
        })
        //}
        console.log("fetchQueue--->> ", conds, " -- ", this.isSupperUser())
        this.fetchingAll("PbxTrunk", conds, (data) => {
            console.log("fetchQueue :> ", data);
            _this.setState({
                Queues: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }

    removeAgentExtension = async(row) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name+"-"+row.extension)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "Username", type: "string", val: row.username},
            {key: "extension", type: "float", val: row.extension},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxAgent"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("deleteEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("deleteEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, trunk deleted success!");

                _this.fetchAgent();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    deleteEntry = async (row) => {

    }

    renderExtensionAllocate = () => {

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Extension</th>
                    <th>Name</th>
                    <th>Trunk</th>
                    <th>Outbound</th>
                    <th>Updated</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {this.state.Agents.map((row) => {
                    return (
                        <tr>
                            <td>{row.extension}</td>
                            <td>{row.name}</td>
                            <td>{row.trunk}</td>
                            <td>{row.outboundnumber}</td>
                            <td>{row.orgdatetime}</td>
                            <td>
                                <a
                                    className={""}
                                    onClick={() => this.removeAgentExtension(row)}
                                    style={{cursor: "pointer"}}
                                >Remove</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>

            </table>
        )
        return
        return null
    }
    requestAddAgent = (range, extension) => {

    }
    renderExtensionFree = () => {

        let _this = this;

        const isInUsed = (extension) => {
            for (let i in _this.state.Agents) {
                const agent = _this.state.Agents[i];
                if (parseInt(agent.extension) === parseInt(extension)) {
                    return true
                }
            }
            return false;
        }

        const innerExtension = (range) => {
            let ls = [];
            const start = parseInt(range.start);
            const end = parseInt(range.end)
            for (let x = start; x <= end; x++) {
                if (!isInUsed(x)) {
                    ls.push(x)
                }

            }

            return (
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th colSpan={2}>From: <span style={{color: "red"}}>{start}</span> To: <span
                            style={{color: "red"}}>{end}</span></th>
                    </tr>

                    </thead>
                    <tbody>
                    {ls.map((item, index) => {
                        return (
                            <tr>
                                <td>{item}</td>
                                <td>
                                    <a className={"btn btn-default"}
                                    onClick={()=>this.openShowNewAllocate(item)}>ALLOCATE</a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            )
        }


        return this.state.Ranges.map((range) => {
            return innerExtension(range)
        })
    }
    submitNewAgent=async (e)=>{
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this agent!" + this.state.newAgent.name)) {
            return
        }
        let _this = this;
        const state = this.state.newAgent;


        let hub = {...state};


        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/agent/new";

        console.log("submitNewAgent send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitNewAgent response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new agent success!");
                let tmp = _this.state;
                tmp.showNewAllocate = false;
                tmp.newAgent = {
                    Org: "",
                    Extension: 0,
                    Username: "",
                    Agent:"",
                    Fullname: "",
                    Trunk: "",
                    OutboundNumber: "",
                    Name: "",
                    Surname: "",
                };
                _this.setState(tmp);
                _this.fetchAgent();
                _this.closeShowNewAllocate();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    closeShowNewAllocate = () => {
        this.setState({
            showNewAllocate: false,
        })
    }
    openShowNewAllocate = (extension) => {
        let user = GetUserToken
        let tmp = this.state;
        tmp.showNewAllocate = true;
        tmp.newAgent = {
            Org: user.OrgCode,
            Extension: extension,
            Username: "",
            Agent:"",
            Fullname: "",
            Trunk: "",
            OutboundNumber: "",
            Name: "",
            Surname: "",
        };

        this.setState(tmp);
    }
    renderPopupAllocateAgent = () => {

        if (!this.state.showNewAllocate) {
            return null
        }

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowNewAllocate}
            />,
        ];

        let _this = this;
        const getNewAgentValue=(key)=>{
            return _this.state.newAgent[key];
        }
        const setNewValue=(e,key)=>{
            const val = e.target.value;
            let tmp=this.state;
            tmp.newAgent[key] = val;
            _this.setState(tmp);
        }
        const setNewAgentValue=(e)=>{
            // const key = row.username+"--"+row.fullname+"--"+row.name+"--"+row.surname+"--"+row.role;
            const user = GetUserToken();
            const val = e.target.value;
            const arr =val.split("--");
            const username = arr[0];
            const fullname =arr[1];
            const name = arr[2];
            const surname = arr[3];
            const role =arr[4];

            let tmp=this.state;
            tmp.newAgent.Org = user.OrgCode;
            tmp.newAgent.Agent = val;
           // tmp.newAgent.Fullname = fullname;
            tmp.newAgent.Name = name;
            tmp.newAgent.Surname = surname;
            tmp.newAgent.Fullname =name+" "+surname;
            tmp.newAgent.Role = role;
            tmp.newAgent.Username = username;
            _this.setState(tmp);

        }

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"Allocation Extension "+this.state.newAgent.Extension}
                    actions={actions}
                    modal={false}
                    open={this.state.showNewAllocate}
                    onRequestClose={this.closeShowNewAllocate}
                    autoScrollBodyContent={true}
                >

                    <br/>

                    <div className={"row"}>
                    <div className="col-lg-12">
                        <form onSubmit={this.submitNewAgent}>
                        <div className="form-group" >
                            <label htmlFor="exampleInputEmail1">Agent</label>
                            <select
                                className="form-control"
                                required={true}
                                value={getNewAgentValue("Agent")}
                                onChange={(e)=>setNewAgentValue(e)}
                            >
                                <option value={""}>---Select---</option>
                                {this.state.Users.map((row)=>{
                                    if(row.username===""){
                                        return null
                                    }
                                    const key = row.username+"--"+row.fullname+"--"+row.name+"--"+row.surname+"--"+row.role;
                                    const display = row.name+" "+row.surname.toUpperCase()
                                    return(
                                        <option value={key}>{display}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="form-group" >
                            <label htmlFor="exampleInputEmail1">Trunk</label>
                            <select
                                className="form-control"
                                required={true}
                                value={getNewAgentValue("Trunk")}
                                onChange={(e)=>setNewValue(e,"Trunk")}
                            >
                                <option value={""}>---Select---</option>
                                {this.state.Queues.map((row)=>{

                                    let key = row.org+"_"+row.name;
                                    key = key.toUpperCase();
                                    return(
                                        <option value={key}>{key}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="form-group" >
                            <label>Outbound Number</label>
                            <input
                                className="form-control"
                                required={true}
                                value={getNewAgentValue("OutboundNumber")}
                                onChange={(e)=>setNewValue(e,"OutboundNumber")}
                                placeholder={"Type Outbound Number ..."}
                            />

                        </div>
                        <div className="form-group" >
                            <button type={"submit"} className={"btn btn-primary pull-right"}>Submit</button>
                        </div>
                        </form>



                    </div>
                    </div>


                </Dialog>

            </MuiThemeProvider>


        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={""}>
                                Sip Agent

                                <a onClick={() => this.requestNewQueue()} className={"btn btn-primary pull-right"}>New
                                    Agent</a>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-9">
                        <p className={"alert alert-info"}>Allocated Extension</p>
                        {this.renderExtensionAllocate()}
                    </div>
                    <div className="col-xs-3">
                        <p className={"alert alert-success"}>Free Extension</p>
                        {this.renderExtensionFree()}
                    </div>
                </div>

                {this.renderPopupAllocateAgent()}


            </PageContentWallpaper>
        )


    }

}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComPbxAgent)