import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    submitEntry
} from "../../api/fn";
import {GetUserToken, PostTo} from "../../api/fetching-data";
import DashBar from "../../component/common/dashboard/DashBar";
import NeedBar from "../../component/common/dashboard/NeedBar";
import Summary from "../../component/common/dashboard/Summary";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import Recrusive from "../../component/TreeView/Recrusive";
import {uuid} from "uuidv4";

const listField = [
    {
        field: "name",
        label: "Name",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "description",
        label: "Description",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
];

class SetupUtilPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }

    componentDidMount() {
        //todo
        this.buildTree();
    }

    fetchRoles = async () => {
        //todo
    }
    submitEntry = async (e) => {

        //todo delete after live
        let data = this.props.enquiry.Roles;
        data.push(this.state.newEntry);
        this.props.SaveRole(data);
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        console.log("submitEntry--->", this.state.newEntry);

        //todo uncomment after
        return
        let endpoint = "/ticket/insert/roles";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}];
        let reloadFunction = [this.fetchRoles];
        await submitEntry(e, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }
        let columns = [
            {id: 'name', label: 'Util Name', minWidth: 170, dataType: "string"},
            {id: 'description', label: 'Description', minWidth: 170, dataType: "string"},
        ];
        let data = {columns: columns, data: this.props.enquiry.Roles};
        return (
            <TableDataView
                {...data}
                onDelete={this.onDelete}
                tableTitle={"Util (Database)"}
                onAddRecord={this.onAddRecord}
            />
        )
    }

    sampleDataTree=()=>{
        return [
            {id:uuid(),name:"faculty",parent:"",topic:"",title:"",description:""},
            {id:uuid(),name:"computer science",parent:"faculty",topic:"",title:"",description:""},
            {id:uuid(),name:"information and design",parent:"faculty",topic:"",title:"",description:""},

            {id:uuid(),name:"IT",parent:"computer science",topic:"",title:"",description:""},
            {id:uuid(),name:"Software",parent:"IT",topic:"",title:"",description:""},
            {id:uuid(),name:"Networking",parent:"IT",topic:"",title:"",description:""},
            {id:uuid(),name:"Business Analysis",parent:"IT",topic:"",title:"",description:""},

            {id:uuid(),name:"TV",parent:"journalism",topic:"",title:"",description:""},
            {id:uuid(),name:"Media",parent:"journalism",topic:"",title:"",description:""},
            {id:uuid(),name:"graphic",parent:"Media",topic:"",title:"",description:""},
            {id:uuid(),name:"audio",parent:"Media",topic:"",title:"",description:""},

        ];
    }

    buildTree=()=>{
        const  getNestedChildren=(arr, parent) =>{
            var out = []
            for(var i in arr) {
                if(arr[i].parent === parent) {
                    var children = getNestedChildren(arr, arr[i].id)

                    if(children.length) {
                        arr[i].children = children
                    }
                    out.push(arr[i])
                }
            }
            return out
        }
        function getNestedChildren2(arr, parent) {
            var children = [];
            for(let i =0; i < arr.length; ++i) {
                if(arr[i].parent === parent) {
                    let grandChildren = getNestedChildren(arr,  arr[i].id)

                    if(grandChildren.length) {
                        arr[i].children = grandChildren;
                    }
                    children.push( arr[i]);
                }
            }
            return children;
        }
        const data=this.sampleDataTree();
        const out =getNestedChildren2(data,"faculty");
        console.log("buildTree &&&&&&&----> ",out)

    }


    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }
        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Util " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }

    renderDashboard = () => {
        let data = [
            {
                amount: "49",
                title: "GB",
                topTitle: "Used space",
                footerTitle: "Get more space",
                color: "warning",
                icon: "content_copy"
            },
            {
                amount: "$789,456",
                title: "Sale",
                topTitle: "Daily Sale",
                footerTitle: "Date 2020-01-04",
                color: "danger",
                icon: "info_outline"
            },
            {
                amount: "49",
                title: "GB",
                topTitle: "Used space",
                footerTitle: "Get more space",
                color: "warning",
                icon: "content_copy"
            },
            {
                amount: "$789,456",
                title: "Sale",
                topTitle: "Daily Sale",
                footerTitle: "Date 2020-01-04",
                color: "primary",
                icon: "info_outline"
            },
        ];

        return (
            <Fragment>

                <NeedBar data={data}/>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} alignItems="center">
                        <Summary/>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center">
                        <Summary/>
                    </GridItem>
                </GridContainer>
                <DashBar data={data}/>

            </Fragment>

        )
    }

    renderRecusive = () => {
        let data = [
            {
                id:uuid(),
                name: "Level 1 X",
                children: [
                    {
                        id:uuid(),
                        name: "Level 2 X",
                        children: [
                            {
                                id:uuid(),
                                name: "Level 3 X",
                                children: [
                                    {
                                        id:uuid(),
                                        name:"Item 1",
                                        children:[]
                                    },
                                    {
                                        id:uuid(),
                                        name:"Item 2",
                                        children:[]
                                    },
                                    {
                                        id:uuid(),
                                        name:"Item 3",
                                        children:[]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                children: [],
                name: "Level 1 Y"
            },
        ]

        return (
            <Recrusive data={data}/>
        )
    }

    render() {
        return (
            <Wallpaper {...this.props} >
                {this.renderRecusive()}
                {this.renderDashboard()}
                {this.renderListUtil()}
                {this.renderEditForm()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(SetupUtilPage);
