import {
    PBX_CHANGE_EXTENSION,
    PBX_CHANGE_AGENT,
    PBX_CALL_OUT_REQUEST,
    PBX_CALL_OUT_END,
    PBX_CALL_OUT_FORM,
    PBX_CALL_OUT_DO,
    PBX_CALL_OUT_CALLERID,
    PBX_CALL_INCOME_REQUEST,
    PBX_CALL_INCOME_ANSWER,
    PBX_CALL_INCOME_HANGUP,
    PBX_CHANGE_REGISTRY,
    PBX_CALLING_STATUS,
} from "../constants";
import moment from "moment";
import {doCall} from "../../component/webcall/JanusSipCall";

const initial = {
    Janus:[
        "ws://voip.easipath.com:8188",
        "http://voip.easipath.com:8088/janus"
    ],
    Extension: {
        username: "00000",
        secret: "00000",
        agentRef:"none@home.com",
        agentName:"nome"
    },
    OutCall: {
        dialing: false,
        phone: "",
        status: "none",
        startAt: "",
    },
    InCall:{
        dialing: false,
        answer:false,
        caller:"",
        server: "",
        callId: "",
        startAt: "",
        answerAt: "",
        hangupAt:"",
        status:"",
        hangupCode:"",
        hangupReason:"",
        callState:"",
    },
    Server:{
        host:"",
        port:5060
    },
    Registry:{
      status:"Not registered" ,
      time:moment().format("YYYY-MM-DD HH:mm:ss"),
    },
    Calling:{
        status:"Not registered" ,
        time:moment().format("YYYY-MM-DD HH:mm:ss"),
    },
    LastIncome:{},
    LastCallOut:{},
    HistoryIncome:[],
    HistoryOutcome:[],
}

const AlertBox=(act,message)=>{
    console.log("CALL OUT LOG > ",act,message)
}

const MyPbx = (state = initial, action) => {
    let newState = state;
    const data  = action.payload;
    switch (action.type) {
        case PBX_CALLING_STATUS:
            newState.Calling.status = data.status;
            newState.Calling.time = moment().format("YYYY-MM-DD HH:mm:ss");
            return Object.assign({}, newState);
        case PBX_CHANGE_REGISTRY:
            newState.Registry.status = data.status;
            newState.Registry.time = moment().format("YYYY-MM-DD HH:mm:ss");
            return Object.assign({}, newState);
        case PBX_CALL_OUT_CALLERID:
            newState.OutCall.phone = data.phone;
            return Object.assign({}, newState);
        case PBX_CALL_OUT_DO:
            doCall({
                to: newState.OutCall.phone,
                host: newState.Server.host,
                AlertBox: AlertBox,
                isVideoCall: false
            })
            return Object.assign({}, newState);
        case PBX_CHANGE_EXTENSION:
            newState.Extension.username = data.username;
            newState.Extension.secret = data.secret;
            newState.Server.host = data.server || newState.Server.host;
            return Object.assign({}, newState);
        case PBX_CHANGE_AGENT:
            newState.Extension.agentRef = data.agentRef;
            newState.Extension.agentName = data.agentName;
            return Object.assign({}, newState);
        case PBX_CALL_OUT_REQUEST:
            newState.OutCall.dialing = true;
            newState.OutCall.phone = data.phone;
            newState.OutCall.startAt=moment().format("YYYY-MM-DD HH:mm:ss");
            newState.OutCall.status="dialed";
            return Object.assign({}, newState);
        case PBX_CALL_OUT_FORM:
            newState.OutCall.dialing = true;
            return Object.assign({}, newState);
        case PBX_CALL_OUT_END:
            newState.OutCall.dialing = false;
            newState.OutCall.phone = "";
            newState.OutCall.startAt=moment().format("YYYY-MM-DD HH:mm:ss");
            newState.OutCall.status="hangup";
            return Object.assign({}, newState);

        case PBX_CALL_INCOME_REQUEST:
            newState.InCall.dialing = true;
            newState.InCall.answer = false;
            newState.InCall.caller = data.caller;
            newState.InCall.callId = data.callId;
            newState.InCall.server = data.server;
            newState.InCall.startAt=moment().format("YYYY-MM-DD HH:mm:ss");
            newState.InCall.status="ring";
            newState.InCall.callState = "ring";
            console.log("REDUX-PBX_CALL_INCOME_REQUEST > ",data);
            return Object.assign({}, newState);
        case PBX_CALL_INCOME_ANSWER:
            newState.InCall.answer = true;
            newState.InCall.answerAt=moment().format("YYYY-MM-DD HH:mm:ss");
            newState.InCall.status="answer";
            newState.InCall.callState = "answer";
            return Object.assign({}, newState);
        case PBX_CALL_INCOME_HANGUP:
            let callState = "complete";
            if(!newState.InCall.answer){
                callState="missed"
            }
            newState.InCall.hangupAt=moment().format("YYYY-MM-DD HH:mm:ss");
            newState.InCall.status="hangup";
            newState.InCall.callId = data.callId;
            newState.InCall.hangupCode = data.code;
            newState.InCall.hangupReason = data.reason;
            newState.InCall.callState = callState;

            newState.LastIncome = newState.InCall;
            newState.HistoryIncome.push( newState.InCall);
            newState.InCall = {
                dialing: false,
                answer:false,
                caller:"",
                server: "",
                callId: "",
                startAt: "",
                answerAt: "",
                hangupAt:"",
                status:"",
            }
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default MyPbx;
