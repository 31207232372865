import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText, sortData,
    submitEntryCRM
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import ComboboxOptions from "../../component/common/form-input-control/ComboxOption";



class UtilPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            module:"",
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }
    onCancel=()=>{
        this.setState({
            showEdit: false
        })
    }
    handleInput = async (e) => {
        console.log("onChangeModule :)-> ", e.target.name, " > ", e.target.value);
        let tmp = this.state;
        tmp[e.target.name] = e.target.value;
        this.setState(tmp);
        // await this.fetchAttributes();
    }
    componentDidMount() {
        //todo

    }

    fetchData = async () => {
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        this.props.LoadTicketData();
    }
    submitEntry = async (e) => {

        console.log("submitEntry--->", this.state.newEntry);

        let newEntry={};
        for(let key in this.state.newEntry){
            let val = this.state.newEntry[key];
            let datatype="string";
            for(let i in this.state.listField){
                const row =this.state.listField[i];
                if(row.field===key){
                    datatype = row.dataType
                }
            }
            if(datatype==="boolean"){
                val=val === "yes"
            }else if(datatype==="float" ||datatype==="number"){
                val=parseFloat(val)
            }
            newEntry[key]=val;
        }

        this.setState({newEntry:newEntry});

        //todo uncomment after
        let endpoint = "/reservation/insert/util";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode},{key:"module",val:this.state.module}];
        let reloadFunction = [this.fetchData];
        await submitEntryCRM(e, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }


        let columns = [
            {id: 'category', label: 'Category', minWidth: 170, dataType: "string"},
            {id: 'name', label: 'Name', minWidth: 170, dataType: "string"},
            {id: 'description', label: 'description.', minWidth: 170, dataType: "Color"},
            {id: 'color', label: 'Color.', minWidth: 170, dataType: "Color"},
            {id: 'position', label: 'position.', minWidth: 170, dataType: "float"},
        ];

        const colorado=(rowIn)=>{
            let o={};
            for(let i in rowIn){
                let val=rowIn[i];
                if(i ==="color"){
                    val=(
                        <span style={{backgroundColor:val}}>
                        {val}
                    </span>
                    )
                }
                o[i]=val;
            }
            return o;
        }

        let ls=[];
        console.log("renderListUtil ^^^> ",this.props.enquiry);
        const attrs = this.props.enquiry.Utils;
        for(let i in attrs){
            let row=attrs[i];
            row = colorado(row);
            if(row.module ===this.state.module){
                ls.push(row)
            }
        }
        ls= sortData("position",ls);

        let data = {columns: columns, data: ls};
        return (
            <TableDataView
                {...data}
                tableTitle={"Utils "+this.state.module+""}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
            />
        )
    }

    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }

        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form Utilities " + this.state.module}
                data={this.state.listField}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }

    renderModule=()=>{
        const roles=this.props.enquiry.Roles;
        let ls=[];
        ls.push({
            key:"request",
            val:"request".toLocaleUpperCase(),
        });
        ls.push({
            key:"service",
            val:"service".toLocaleUpperCase(),
        });
        ls.push({
            key:"product",
            val:"product".toLocaleUpperCase(),
        })
        for(let i in roles){
            const row=roles[i];
            ls.push({
                key:"people--"+row.name,
                val:"People -- "+row.name.toLocaleUpperCase(),
            })
        }
        return(
            <ComboboxOptions
                name={"module"}
                label={"Module"}
                options={ls}
                handleInput={this.handleInput}
                onClickButton={this.onChangeModule}
                btnText={"Change Module"}
                includeBtn={false}
            />
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} >

                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderModule()}
                    </GridItem>
                    {this.state.module !== ""?
                        <Fragment>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderListUtil()}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} alignItems="center" >
                                {this.renderEditForm()}
                            </GridItem>
                        </Fragment>:
                        <p style={{color: "red"}}>Please select your target module(Offering)</p>
                    }

                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(UtilPage);

const options = ["yes", "no"];
const optionsDataType = ["string", "float", "boolean"];
const optionsBoolean = ["yes", "no"];
const optionCategory=["status","priority","stage"];
const listField = [
    {
        field: "Category",
        label: "Category",
        options: optionCategory,
        type: "combobox",
        required: true,
        dataType: "string"
    },
    {
        field: "Name",
        label: "Name",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "Description",
        label: "Description",
        options: options,
        type: "text",
        required: false,
        dataType: "string"
    },
    {
        field: "Position",
        label: "Position",
        options: options,
        type: "float",
        required: true,
        dataType: "float"
    },
    {
        field: "Color",
        label: "Color Code HTML(e.g. #789654)",
        options: options,
        type: "text",
        required: false,
        dataType: "string"
    },

];
