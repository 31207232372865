import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    submitEntry
} from "../../api/fn";
import {GetUserToken} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import Stepper from "../../component/Ticket/NewTicket/Stepper";

const listField = [
    {
        field: "name",
        label: "Name",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "description",
        label: "Description",
        options: [],
        type: "text",
        required: true,
        dataType: "string"
    },
];


class NewTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            activeStep:0,
            step:0,
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }

    componentDidMount() {
        //todo

    }

    submitEntry = async (e) => {

        //todo delete after live
        let data = this.props.enquiry.Roles;
        data.push(this.state.newEntry);
        this.props.SaveRole(data);
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        console.log("submitEntry--->", this.state.newEntry);

        //todo uncomment after
        return
        let endpoint = "/ticket/insert/roles";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}];
        let reloadFunction = [this.fetchRoles];
        await submitEntry(e, othersField, endpoint, reloadFunction, this);
    }

    getSteps=()=> {
        return ['Originate', 'SLA', 'Content', 'Finish'];
    }
    isStepOptional=(step)=>{
        //return step === 1;
        return false
    }
    isStepFailed=(step)=>{
        return false;
        return step === 1;
    }
    isStepSkipped=(step)=>{
        return true
    }
    handleNext=()=>{
        let activeStep=this.state.activeStep;
        let limit = this.getSteps().length;
        let nextStep = activeStep +1;
        if(nextStep ===limit){
            return
        }
        this.setState({
            activeStep:nextStep
        })
    }

    handleBack=()=>{
        let activeStep=this.state.activeStep;
        let nextStep = activeStep - 1;
        if(nextStep <0 ){
            return
        }
        this.setState({
            activeStep:nextStep
        })
    }
    handleReset=()=>{
        this.setState({
            activeStep:0
        })
    }
    handleSkip=()=>{
        let activeStep=this.state.activeStep;
        let limit = this.getSteps().length;
        let nextStep = activeStep +1;
        if(nextStep ===limit){
            return
        }
        this.setState({
            activeStep:nextStep
        })
        /**
         * Please check the logic of which component is not skippable
         * TODO
         */
    }

    handelInput=(val,key)=>{
        let tmp =this.state;
        tmp.newEntry[key]=val.key;
        this.setState(tmp);
    }


    render() {
        return (
            <Wallpaper {...this.props} >
                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                    <h1>New Ticket</h1>
                        <Stepper
                            activeStep={this.state.activeStep}
                            getSteps={this.getSteps}
                            steps={this.getSteps()}
                            isStepOptional={this.isStepOptional}
                            isStepFailed={this.isStepFailed}
                            isStepSkipped={this.isStepSkipped}
                            handleNext={this.handleNext}
                            handleBack={this.handleBack}
                            handleReset={this.handleReset}
                            handleSkip={this.handleSkip}
                            roles={this.props.enquiry.Roles}
                            handelInput={this.handelInput}
                            state={this.state}
                            databases={this.props.enquiry.Peoples}
                        />

                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} alignItems="center" >

                    </GridItem>
                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(NewTicketPage);
