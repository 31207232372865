import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";

// core components
import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import Danger from "./Typography/Danger.js";
import Card from "./Card/Card.js";
import CardHeader from "./Card/CardHeader.js";
import CardIcon from "./Card/CardIcon.js";
import CardFooter from "./Card/CardFooter.js";

import styles from "../../../asset/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default ({data,handleInput})=>{
    const classes = useStyles();

    return(
        <GridContainer style={{backgroundColor:"#eeeeee",marginTo:25,marginBottom:25,borderRadius:5}}>
            {data.map((row)=>{

                return(
                    <GridItem xs={12} sm={6} md={3} >
                        <Card>
                            <CardHeader color={row.color} stats icon>
                                <CardIcon color={row.color}>
                                    <Icon>{row.icon}</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>{row.title}</p>
                                <h3 className={classes.cardTitle}>
                                    <span style={{
                                        fontWeight: "600",
                                        fontSize:20,
                                        color:"gray"
                                    }}>{row.amount} </span>
                                    <br/><small style={{fontWeight:"400"}}>{row.topTitle}</small>
                                </h3>
                            </CardHeader>

                            <CardFooter stats>
                                <div className={classes.stats}>
                                    {row.data.length > 0 ?
                                        <a onClick={() => handleInput(row)} style={{cursor:"pointer"}}>
                                            View Data
                                        </a> :
                                        <span>none</span>
                                    }
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}
        </GridContainer>
    )
}

/*
.disp-t {
    height: 100%;
    box-shadow: 0px 0px 25px 2px
#666;
border-radius: 5px;
background-color:
    #ffffff;
}
 */
