import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default ({row,onChangeRadio})=> {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');

    const handleChange = event => {
        setValue(event.target.value);
        onChangeRadio(row,event)
    };

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin}>
                <FormLabel component="legend" className={"legend"}>{row.label}</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    {row.options.map((item) => {
                        return (
                            <FormControlLabel value={item} control={<Radio />} label={item.toLocaleUpperCase()} />
                        )
                    })}
                </RadioGroup>
            </FormControl>

        </div>
    );
}
