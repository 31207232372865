import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


export default class CompPopupUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUpload: {
                type:"",
                name: "",
                base64string: "",
                filename: "",
            },
        }
    }



    downloadFile=(row)=>{
        var url = row.link;
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = row.filename; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }

    openDocLink=(row)=>{
        let link =row.link;
        console.log("Opening link pdf link request > ", link);
        document.title = "eSign Document";
        window.open(link, '_blank');
    }

    render(){
        const title = this.props.popTitle;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const vehicle=this.props.vehicle;
        const ls=this.props.fileList ||[];

        if(vehicle===null){
            return null
        }

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        const innerStyle={cursor:"pointer"};

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title +" Vehicle: "+vehicle.ref}
                    actions={actions}
                    modal={false}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >

                    <p className={"alert alert-info"}>Download</p>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>&nbsp;</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Uploaded</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ls.map((row,index)=>{
                            console.log("====?> ",row);
                            return(
                                <tr key={index}>
                                    <td style={{color:"blue"}}>
                                        <il className={"fa fa-download"} style={{fontSize: 18, color: "blue"}}>&nbsp;</il>
                                        <a onClick={()=>this.downloadFile(row)} style={innerStyle}>Download</a>
                                        &nbsp;| &nbsp;
                                        <il className={"fa fa-list"} style={{fontSize: 18, color: "blue"}}>&nbsp;</il>
                                        <a onClick={()=>this.openDocLink(row)} style={innerStyle}>Open</a>
                                    </td>
                                    <td><img src={row.link} width={65}  alt={row.name}/></td>
                                    <td>{row.name}</td>
                                    <td>{row.category}</td>
                                    <td>{row.orgdatetime}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>


                </Dialog>
            </MuiThemeProvider>
        )

    }
}
