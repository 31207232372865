import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";

export default class ComSettingPool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Batchs: [],
            Campaigns: [],
            Pools: [],
            selectedCampaign: "",
            selectedBatch: "",
            selectedPool:"",
        }
    }

    async componentDidMount() {
        await this.fetchBatchs();
        await this.fetchCampaigns();
        await this.fetchPoolInfo();
    }
    fetchPoolInfo = async () => {
        const user = GetUserToken();
        let _this = this;

        let hub={
            Org :user.OrgCode,
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/pool/info";

        console.log("fetchPoolInfo send > ",  hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchPoolInfo response > ",  dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    Pools:data
                })
            }
        });

    }
    fetchCampaigns = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            }
        ];
        const dbName = "cclms";
        await this.fetchingAll(dbName, "Campaign", conds, (data) => {
            console.log("fetchCampaigns :> ", data, " > ", conds);
            _this.setState({
                Campaigns: data
            });
        });
    }
    fetchBatchs = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            }
        ];
        const dbName = "cclms";
        await this.fetchingAll(dbName, "Batchs", conds, (data) => {
            console.log("process taken fetchBatchs :> ", data, " > ", conds);
            _this.setState({
                Batchs: data
            });
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds, required) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp);
            if (handleFunc !== null) {
                handleFunc();
            }

        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={required}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        //console.log(":)--> HandleInput > ",key," > ",e.target.value)
    }
    getValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return "";
        }
        return this.state[key];
    }
    submitUpload = async (e) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const user = GetUserToken();

        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.BatchName = this.state.selectedBatch;
        hub.CampaignName = this.state.selectedCampaign;
        hub.PoolName = this.state.selectedPool;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/lead/pool/new";

        console.log("submitUpload send > ",  hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUpload response > ",  dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                if(data ==="OK"){
                    alert("Thank you, success Pool created !");
                    _this.setState({
                        selectedBatch:"",
                        selectedCampaign:"",
                        selectedPool:"",
                    });
                    _this.fetchPoolInfo();
                }else{
                    alert("Error pool creation request please try again: "+data)
                }

            }
        });


    }
    renderList = () => {
        return (
            <div>
                <h3 className="alert alert-info">Pools</h3>
                <table className={"table"}>
                    <thead>
                    <th>Pool</th>
                    <th>Batch</th>
                    <th>Campaign</th>
                    <th>Total</th>
                    </thead>
                    <tbody>
                    {this.state.Pools.map((row, index) => {
                        console.log("$$$$$---> ", row);
                        /*

                         */
                        return (
                            <tr>
                                <td>{row.PoolName}</td>
                                <td>{row.BatchName}</td>
                                <td>{row.Campaign}</td>
                                <td>{row.Total}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

        )
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Upload New Batch</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <form onSubmit={this.submitUpload}>
                            {this.selectionBox("Batch", "Batchs", "name", "name", "selectedBatch", null, [], true)}
                            {this.selectionBox("Campaign", "Campaigns", "name", "name", "selectedCampaign", null, [], true)}

                            <div className="form-group">
                                <label>Pool Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    required={true}
                                    onChange={(e) => this.handleInput(e, "selectedPool")}
                                    value={this.getValue("selectedPool")}
                                />
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="form-control btn btn-danger"
                                >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        {this.renderList()}
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }

}