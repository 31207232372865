import React, {Fragment} from 'react';
import {
    makeStyles,
} from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    Paper,
} from '@material-ui/core';
import "../../../asset/css/fieldset-lengend.css";

import FormEditInputRadio from "./FormEditInputRadio";
import FormEditInputCheckbox from "./FormEditInputCheckbox";
import FormEditInputSelect from "./FormEditInputSelect";
import FormEditInputText from "./FormEditInputText";

const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor:"gray",
        borderRadius:3,
        borderWidth:1
    },
}));


export default function myForm ({formTitle, onChangeText, onChangeCheck, onChangeRadio,onCancel, onSubmit, formType,data,showTitle})  {
    const classes = innerStyles();

    if(typeof data ==="undefined"){
        return null
    }

    let canShowTitle=true;
    if(typeof showTitle !=="undefined"){
        canShowTitle=false;
    }

    return (
        <form onSubmit={onSubmit} className={classes.root} noValidate>
            {canShowTitle?
            <Typography variant="h3" component="h4" gutterBottom>
                <span style={{color: "lightblue"}}>{formTitle}</span>
            </Typography>:null}

            {formType === "online" ?
                <Fragment>
                    <Grid component={Paper} sm={12} md={12}>
                        {data.map((row, index) => {
                            if (row.type === "text"||row.type === "float") {
                                return <FormEditInputText row={row} styleIn={{}} onChangeText={onChangeText} />;
                            }
                            if (row.type === "option") {
                                return <FormEditInputCheckbox row={row} onChangeCheck={onChangeCheck} />
                            }
                            if (row.type === "radio" ||row.type === "boolean" ) {
                                return <FormEditInputRadio row={row} onChangeRadio={onChangeRadio}/>
                            }
                            if (row.type === "combobox") {
                                return <FormEditInputSelect row={row} onChangeText={onChangeText}/>
                            }
                            return <FormEditInputText row={row} />
                        })}
                        <Button onClick={()=>onCancel()}  type={"button"} variant="contained" color="secondary" className={classes.margin}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary" className={classes.margin}>
                            Submit
                        </Button>
                    </Grid>
                </Fragment> :
                <Fragment>
                    <Grid component={Paper} sm={12} md={12}>
                        {data.map((row, index) => {

                            return (
                                <Grid component={"P"} sm={12} md={12}>
                                    {row.type === "text" && (
                                        <FormEditInputText row={row} styleIn={{minWidth: "98%"}} onChangeText={onChangeText}/>
                                    )}
                                    {row.type === "float" && (
                                        <FormEditInputText row={row} styleIn={{minWidth: "98%"}} onChangeText={onChangeText}/>
                                    )}
                                    {row.type === "option" && (
                                        <FormEditInputCheckbox row={row} styleIn={{minWidth: "98%"}} onChangeCheck={onChangeCheck}/>
                                    )}
                                    {row.type === "radio" && (
                                        <FormEditInputRadio row={row} styleIn={{minWidth: "98%"}} onChangeRadio={onChangeRadio}/>
                                    )}
                                    {row.type === "boolean" && (
                                        <FormEditInputRadio row={row} styleIn={{minWidth: "98%"}} onChangeRadio={onChangeRadio}/>
                                    )}
                                    {row.type === "combobox" && (
                                        <FormEditInputSelect row={row} styleIn={{minWidth: "98%"}} onChangeText={onChangeText}/>
                                    )}
                                </Grid>
                            )
                        })}

                        {canShowTitle?
                        <Grid component={Paper} sm={12} md={12} style={{float: "right"}}>
                            <Button onClick={()=>onCancel()} type={"button"} variant="contained" color="secondary" className={classes.margin}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary" className={classes.margin}>
                                Submit
                            </Button>
                        </Grid>:null}
                    </Grid>
                </Fragment>
            }

        </form>
    );
}
