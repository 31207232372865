import React, {Component, Fragment} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderListPrivate,
    renderForm, removeEntry
} from "../generic/databases-function";
import {GetUserToken} from "../../api/services";
import eConfig from "../../config";
import moment from "moment";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import Wallpaper from "../common/ef-wallper";
import MainComponent from "./com-field-locations";

const options = ["yes", "no"];
const optionsCountry = ["usa", "sa", "congo", "DRC"];

const getCurrentDate = () => {
    return moment().format("YYYY-MM-DD")
}

class ComFieldReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: getCurrentDate(),
            endDate: getCurrentDate(),
            Reports: null,
            isLoading: false,
            selectedCustomer: null
        }
    }

    async componentDidMount() {
        await this.submitEntry(null)
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    fetchApi = async (url, hub, method, callback) => {
        console.log("$$$$$----> ", url);
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((response) => {
                callback(response, null);
            }).catch((e) => {
            callback(null, e, null);
        });
    }

    submitEntry = async (e) => {
        if (e !== null) {
            e.preventDefault();
            if (!window.confirm("Are you sure that you want to search report?")) {
                return
            }
        }


        const {startDate, endDate} = this.state;

        let hub = {
            startDate: startDate,
            endDate: endDate,
            Org: GetUserToken().OrgCode
        };

        let _this = this;

        this.setState({isLoading: true});

        let backend = eConfig.otpBackend;
        let endpoint = "/api/customer/structure/report";
        let url = eConfig.serverBackendOtp + "/" + backend + endpoint;

        await this.fetchApi(url, hub, "POST", async (dataIn, err) => {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    let tmp = _this.state;
                    tmp.isLoading = false;
                    tmp.Reports = dataIn.RESULT;
                    _this.setState(tmp);
                }
            }

        });

    }

    viewCustomerDetail = (data) => {
        this.setState({selectedCustomer: data})
    }

    renderReport = () => {
        const {Reports, isLoading, startDate, selectedCustomer} = this.state;
        if (isLoading) {
            return (
                <p className={"alert alert-danger"}>Please waiting, search target report .....</p>
            )
        }

        if (Reports === null) {
            return null
        }

        let ls = [];
        console.log("rowPeriod &&&&--> ", Reports);
        for (let i in Reports.BreakdownDateAllPeriod) {
            let rowPeriod = Reports.BreakdownDateAllPeriod[i];
            console.log("rowPeriod &&&&--> ", i, "===", startDate, " > ", i === startDate)
            if (i === startDate) {
                ;
                for (let x in rowPeriod.Customers) {
                    let row = rowPeriod.Customers[x];
                    ls.push(row)
                }
            }
        }

        let lsDetail = [];

        if (selectedCustomer !== null) {
            for (let i in selectedCustomer.Data) {
                const key = i;
                const val = selectedCustomer.Data[i];
                lsDetail.push({
                    key: key,
                    val: val
                })
            }
        }

        return (
            <Fragment>
                <div className={"col col-lg-6"}>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Campaign</th>
                            <th>Location</th>
                            <th>DateTime</th>
                            <th>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ls.map((row, index) => {
                            return (
                                <tr>
                                    <td>{row.CustomerName}</td>
                                    <td>{row.Campaign}</td>
                                    <td>{row.LocationName}</td>
                                    <td>{row.OrgDateTime}</td>
                                    <td>
                                        <a className={"btn btn-default"}
                                           onClick={() => this.viewCustomerDetail(row)}
                                        >
                                            View Detail</a>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className={"col col-lg-6"}>
                    <div className="card">
                        <div className="card-header">
                            Selected Customer Detail
                        </div>
                        <div className="card-body">
                            {selectedCustomer !== null && (
                                <Fragment>
                                    <h4>Customer Detail: <span
                                        style={{color: "red"}}>{selectedCustomer.CustomerName}</span></h4>
                                    <p>Agent Ref: <span style={{color: "red"}}>{selectedCustomer.Ref}</span></p>
                                    <ul className="list-group list-group-flush">
                                        {lsDetail.map((row, index) => {
                                            return (
                                                <li className={"list-group-item"}>
                                                    <b>{row.key}: </b> {row.val}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }


    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Report

                            </h3>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col col-lg-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <form className="form-inline" onSubmit={this.submitEntry}>
                                        <div className="form-group mx-sm-3 mb-2">
                                            <label htmlFor="inputPassword2">Start Date</label>
                                            <input type="date" className="form-control" id="inputPassword2"
                                                   placeholder="Start Date"
                                                   value={this.state.startDate}
                                                   name={"startDate"}
                                                   onChange={(e) => this.handleInput(e)}
                                            />
                                        </div>
                                        &nbsp;
                                        <div className="form-group mx-sm-3 mb-2">
                                            <label htmlFor="inputPassword2">End Date</label>
                                            <input type="date" className="form-control" id="inputPassword2"
                                                   placeholder="End Date"
                                                   value={this.state.endDate}
                                                   name={"endDate"}
                                                   onChange={(e) => this.handleInput(e)}
                                            />
                                        </div>
                                        <div className="form-group mx-sm-3 mb-2">
                                            <label htmlFor="inputPassword2">&nbsp;</label>
                                            <br/>
                                            &nbsp;
                                            <button type="submit" className="btn btn-primary mb-2">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col col-lg-12"}>
                            <hr/>

                            {this.renderReport()}

                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConFieldLocations extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ComFieldReport {...this.props}/>

            </Wallpaper>
        );
    }
})


