import React, {Component} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/agent-dashboard.css';
import PageContentWallpaper from '../common/ef-pageContent';

import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';

import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import FlatButton from "material-ui/FlatButton";
//import Dialog from "material-ui/Dialog";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from "material-ui/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import loader from '../../asset/img/ajax_loader.gif';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import moment from "moment";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}


export default class ComFlowCustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomersAll: [],
            Customers: [],

        }
    }

    async componentDidMount() {
        await this.fetchCustomersAll();
        await this.loadCustomers();

    }

    fetchCustomersAll = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;

        const dbName = "cclms";
        const table = "flowCustomer";
        const conds = [
            {key: "org", val: user.OrgCode, type: "string"}
        ];

        this.fetchingAll(dbName, table, conds, function (data) {
            console.log("fetchCustomersAll response > ", data);
            _this.setState({
                CustomersAll: data
            })
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadCustomers = async () => {
        const user = GetUserToken();
        let _this = this;
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Username = user.Username;
        hub.Role = user.Role;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/flow/customer/list";
        console.log("loadCustomers send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                console.log("loadCustomers response > ", data);
                _this.setState({
                    Customers: data
                })
            }
        });
    }

    renderCustomerList=()=>{

        let ls=[];
        ls=this.state.Customers;

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Ref</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Process</th>
                    <th>Step</th>
                    <th>Date</th>

                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr>
                            <td style={{width:200}}>
                                <a className={"btn btn-default"}>Act.</a> &nbsp;
                                <a className={"btn btn-default"}>Step</a> &nbsp;
                                <a className={"btn btn-default"}>Stat.</a> &nbsp;
                            </td>
                            <td>{row.Identify}</td>
                            <td>{row.Name}</td>
                            <td>{row.Phone}</td>
                            <td>{row.Email}</td>
                            <td>{row.ProcessName}</td>
                            <td>{row.CurrentStep}</td>
                            <td>{row.OrgDateTime}</td>

                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <MuiThemeProvider>
                    <div className={"container"}>
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className={"alert alert-info"}>Customers</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                {this.renderCustomerList()}
                            </div>
                        </div>
                    </div>
                </MuiThemeProvider>
            </PageContentWallpaper>
        )
    }

}
