import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";

class SettingDealerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            Trunks: [],
            newTrunk: {org: "", name: "", start: "", end: "", secret: ""},
            currentTrunk: null,
            isListRange: true,
            isNewTrunk: false,
        }


    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({login: user});

        await this.fetchTrunk();

    }

    isSupperUser = () => {
        let user = GetUserToken();
        if(user.Role === "super") {
            return true
        }
        return false
    }
    fetchTrunk = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [];
        if (!this.isSupperUser()) {
            conds.push({
                type: "string",
                key: "org",
                val: user.OrgCode
            })
        }
        console.log("fetchTrunk--->> ",conds," -- ",this.isSupperUser())
        this.fetchingAll("PbxTrunk", conds, (data) => {
            console.log("fetchTrunk :> ", data);
            _this.setState({
                Trunks: data,
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {
        const user = GetUserToken();
        let hub = {
            Fields: [],
            DbName: "cclms",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            }
        });
    }
    submitTrunk = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this range!" + this.state.newTrunk.name)) {
            return
        }
        let _this = this;
        const state = this.state.newTrunk;


        let hub = {
            org: state.org,
            name: state.name,
            host:state.host,
            username: state.username,
            secret: state.secret,
        };


        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/trunk/new";

        console.log("submitTrunk send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("submitTrunk response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you,new range success!");
                let tmp = _this.state;
                tmp.newRange = {org: "", name: "", start: "", end: "", secret: ""}
                tmp.isListRange = true;
                tmp.isNewTrunk = false;
                tmp.isNewSecretRange = false;
                _this.setState(tmp);
                _this.fetchTrunk();
            } else {
                alert("Error something went wrong!!")
            }

        });

    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getStateValue = (key) => {
        return this.state[key];
    }
    deleteEntry = async (row) => {
        let _this = this;
        if (!window.confirm("are you sure to delete " + row.org + "-" + row.name)) {
            return null
        }

        let conds = [
            {key: "org", type: "string", val: row.org},
            {key: "name", type: "string", val: row.name},
        ];
        let hub = {
            conds: conds,
            tablename: "PbxTrunk"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/pbx/delete/entry";

        console.log("deleteEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn) {
            console.log("deleteEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you, trunk deleted success!");

                _this.fetchTrunk();
            } else {
                alert("Error something went wrong!!")
            }

        });
    }
    requestNewTrunk = () => {
        let newTrunk = this.state.newTrunk;
        const user = GetUserToken();
        newTrunk.org = user.OrgCode;
        this.setState({
            isListRange: false,
            isNewTrunk: true,
            isNewSecretRange: false,
            newTrunk:newTrunk
        })
    }

    renderNewTrunk = () => {
        const state = this.state;
        if (!state.isNewTrunk) {
            return null
        }
        let _this = this;

        const listField = ["org", "name", "host", "username", "secret"];
        const getValue = (myKey) => {
            return _this.state.newTrunk[myKey]
        }
        const setValue = (e, myKey) => {
            let tmp = _this.state;
            tmp.newTrunk[myKey] = e.target.value;
            _this.setState(tmp);
        }

        return (
            <div className={""}>
                <p className={"alert alert-danger"}>New Range entry</p>
                <form onSubmit={this.submitTrunk}>
                    {listField.map((item) => {
                        if(item==="org"){
                            const user=GetUserToken();
                            if(user.Role==="super"){
                                return (
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={"Enter " + item.toUpperCase() + " ..."}
                                            onChange={(e) => setValue(e, item)}
                                            value={this.getStateValue(item)}
                                            required={true}
                                        />
                                    </div>
                                )
                            }
                            return (
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={"Enter " + item.toUpperCase() + " ..."}
                                        onChange={(e) => setValue(e, item)}
                                        value={user.OrgCode}
                                        required={true}
                                        readOnly={true}
                                    />
                                </div>
                            )
                        }
                        return (
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">{item.toUpperCase()}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"Enter " + item.toUpperCase() + " ..."}
                                    onChange={(e) => setValue(e, item)}
                                    value={this.getStateValue(item)}
                                    required={true}
                                />
                            </div>
                        )
                    })}
                    <div className="form-group">
                        <button className={"btn btn-danger pull-left"} type={"button"}
                                onClick={() => {
                                    this.setState({
                                        isListRange: true,
                                        isNewTrunk: false,
                                        isNewSecretRange: false,
                                    })
                                }}
                        >
                            Cancel
                        </button>
                        <button className={"btn btn-primary pull-right"} type={"submit"}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    renderRangeList = () => {

        const state = this.state;
        if (!state.isListRange) {
            return null
        }

        let ls = this.state.Trunks;

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Org</th>
                    <th>Name</th>
                    <th>Host</th>
                    <th>Username</th>
                    <th>Secret</th>
                    <th>Updated</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={index}>
                            <th>{row.org}</th>
                            <th>{row.name}</th>
                            <th>{row.host}</th>
                            <th>{row.username}</th>
                            <th>{row.secret}</th>
                            <th>{row.date + " " + row.time}</th>
                            <th>

                                <a className={"btn btn-default"} onClick={() => this.deleteEntry(row)}>Delete</a>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {

        return (


            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 className={"alert alert-info"}>
                                Sip Trunk

                                <a onClick={() => this.requestNewTrunk()} className={"btn btn-primary pull-right"}>New
                                    Trunk</a>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderRangeList()}
                        {this.renderNewTrunk()}
                    </div>
                </div>


                {/*<div className="row container">
                    <div className="col-xs-12">
                        <p className={"alert alert-info"} style={{fontSise: 16, fontWeight: "bold"}}>Sale</p>
                        <form onSubmit={this.submitRecharge}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Recharge phone number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter phone number..."
                                    onChange={(e)=>this.handleInput(e,"rechargeNumber")}
                                    value={this.getStateValue("rechargeNumber")}
                                />
                                <small id="emailHelp" className="form-text text-muted">
                                    Your number need to be in 10 digit e.g. 0729139504
                                </small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Recharge Amount</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Recharge Amount..."
                                    onChange={(e)=>this.handleInput(e,"rechargeAmount")}
                                    value={this.getStateValue("rechargeAmount")}
                                />
                                <small id="emailHelp" className="form-text text-muted">
                                    Your amount must be less then your balance
                                </small>
                            </div>
                            <div className="form-group">
                                <button className={"btn btn-primary pull-right"} type={"submit"}>
                                    Submit
                                </button>
                            </div>


                        </form>

                    </div>
                </div>*/}

            </PageContentWallpaper>
        )


    }

}

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel,
    login: state.login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingDealerComponent)