import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";

export default class UploadNewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Batchs:[]
        }
    }

    async componentDidMount() {
        await this.fetchUploaded();
    }
    fetchUploaded = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            }
        ];
        const dbName = "cclms";
        await this.fetchingAll(dbName, "uploadcsv", conds, (data) => {
            console.log("process taken fetchBatchs :> ", data, " > ", conds);
            _this.setState({
                Batchs: data
            });
        });
    }
    fetchingAll = async (dbName, table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: dbName,
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    openDocLink=(link)=>{
        console.log("Opening link pdf link request > ", link);
        document.title = "eSign Document";
        window.open(link, '_blank');
    }
    renderList=()=>{

        return(
            <div>
                <h3 className="alert alert-info">Batch</h3>
                <table className={"table"}>
                    <thead>
                    <th>BatchName</th>
                    <th>FileName</th>
                    <th>Total</th>
                    <th>Date & Time</th>
                    <th>UniqueKey</th>
                    <th>Header</th>
                    <th>#</th>
                    </thead>
                    <tbody>
                    {this.state.Batchs.map((row,index)=>{
                        console.log("$$$$$---> ",row);
                        return(
                            <tr>
                                <td>{row.batchname}</td>
                                <td>{row.filename}</td>
                                <td>{row.totalrecord}</td>
                                <td>{row.orgdatetime}</td>
                                <td>{row.colunique}</td>
                                <td>{JSON.stringify(row.colcategory)}</td>
                                <td>
                                    <button
                                        className="btn btn-default"
                                        onClick={()=>this.openDocLink(row.link)}
                                    >download</button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

        )
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Upload History</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        {this.renderList()}
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }

}