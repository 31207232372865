
const
    SAVE_ROLE="SAVE_ROLE",
    SAVE_UTIL="SAVE_UTIL"
;

export{
    SAVE_ROLE,
    SAVE_UTIL
}
