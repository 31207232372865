import React from "react";
import {GetUserToken, PostTo} from "./fetching-data";
import {SERVER_RESERVATION,SERVER_CRM} from "../configures/constants";

const submitEntry = async (e,others,endpoint,arrFnReload,_this) => {
    if (typeof e !== "undefined") {
        e.preventDefault();
    }
    if(!window.confirm("Are you sure to submit?")){
        return null
    }
    let hub={};
    const entry=_this.state.newEntry;
    for(let i in _this.state.listField){
        const row=_this.state.listField[i];
        hub[row.field] = entry[row.field];
    }
    for(let i in others){
        const row=others[i];
        hub[row.key]=row.val;
    }
    _this.setState({isLoading: true});
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("submitEntry fn api response > ", hub,dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                for(let i in arrFnReload){
                    const myFunction =arrFnReload[i];
                    await myFunction()
                }
            }
        }
        _this.setState({isLoading: false});
    });

}
const submitEntryCRM = async (e,others,endpoint,arrFnReload,_this) => {
    if (typeof e !== "undefined") {
        e.preventDefault();
    }
    if(!window.confirm("Are you sure to submit?")){
        return null
    }
    let hub={};
    const entry=_this.state.newEntry;
    for(let i in _this.state.listField){
        const row=_this.state.listField[i];
        hub[row.field] = entry[row.field];
    }
    for(let i in others){
        const row=others[i];
        hub[row.key]=row.val;
    }
    _this.setState({isLoading: true});
    await PostTo(SERVER_CRM, hub, endpoint, async (dataIn) => {
        console.log("submitEntry fn api response > ", hub,dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                for(let i in arrFnReload){
                    const myFunction =arrFnReload[i];
                    await myFunction()
                }
            }
        }
        _this.setState({isLoading: false});
    });

}
const submitEntryCRM_WithData = async (e,hub,endpoint,arrFnReload,_this) => {
    if (typeof e !== "undefined") {
        e.preventDefault();
    }
    if(!window.confirm("Are you sure to submit?")){
        return null
    }
    _this.setState({isLoading: true});
    await PostTo(SERVER_CRM, hub, endpoint, async (dataIn) => {
        console.log("submitEntryCRM_WithData fn api response > ", hub,dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                for(let i in arrFnReload){
                    const myFunction =arrFnReload[i];
                    await myFunction()
                }
            }
        }
        _this.setState({isLoading: false});
    });

}
const deleteEntryCRM_WithData = async (e,hub,endpoint,arrFnReload,_this) => {
    if (typeof e !== "undefined") {
        e.preventDefault();
    }
    if(!window.confirm("Are you sure to delete this entry?")){
        return null
    }
    _this.setState({isLoading: true});
    await PostTo(SERVER_CRM, hub, endpoint, async (dataIn) => {
        console.log("deleteEntryCRM_WithData fn api response > ", hub,dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                for(let i in arrFnReload){
                    const myFunction =arrFnReload[i];
                    await myFunction()
                }
            }
        }
        _this.setState({isLoading: false});
    });

}

const onHandleInputFormEditText = (row, e,_this) => {
    let tmp = _this.state;
    tmp.newEntry = Object.assign({}, tmp.newEntry);
    if (typeof tmp.newEntry[row.field] === "undefined") {
        tmp.newEntry[row.field] = {}
    }
    tmp.newEntry[row.field] = e.target.value;
    _this.setState(tmp);
}
const onHandleInputFormEditRadio = (row, e,_this) => {
    let tmp = _this.state;
    if (typeof tmp.newEntry[row.field] === "undefined") {
        tmp.newEntry[row.field] = {}
    }
    tmp.newEntry[row.field] = e.target.value;
    _this.setState(tmp);
}
const onHandleInputFormEditCheck = (row, inObject,_this) => {
    let tmp = _this.state;
    if (typeof tmp.newEntry[row.field] === "undefined") {
        tmp.newEntry[row.field] = {}
    }
    tmp.newEntry[row.field][inObject.key] = inObject.val;
    _this.setState(tmp);
}

const RemoveFromGlobalEntry=async (DbName,CqlTable,conds,callback)=>{
    let hub = {
        Fields: [],
        DbName: DbName,
        TableName: CqlTable,
        Conditions: conds,
    }
    let endpoint = "/global-service/remove";
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("RemoveFromGlobalEntry response > ", dataIn,hub);
        callback(dataIn);
    });

}
const RemoveEntry = async (newEntry,entity,callback) => {
    let hub = newEntry;
    let endpoint = "/reservation/remove/"+entity;
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("submitUploadEntry response > ", dataIn);
        callback(dataIn);
    });
}
const SubmitEntry = async (newEntry,entity,callback) => {
    let hub = newEntry;
    let endpoint = "/reservation/insert/"+entity;
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("submitUploadEntry response > ", dataIn);
        callback(dataIn);
    });
}
const ListEntry = async (newEntry,entity,callback) => {
    let hub = newEntry;
    let endpoint = "/reservation/list/"+entity;
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("ListEntry response > ", dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            } else {
                callback([]);
            }
        }
    });
}
const FetchData = async (hub,endpoint,callback) => {
    //let hub = newEntry;
    //let endpoint = "/reservation/list/"+entity;
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("FetchData response > ", dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            } else {
                callback([]);
            }
        }
    });
}
const GetEntityRecords=async (dbname,table,callback)=>{
    /*const dbname="reservation";
    const table=entity;*/
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll(this,dbname, table, conds, async (data) => {
        callback(data);
    });
}
const getUnitList=async (callback)=>{
    const dbname="reservation";
    const table="unit";
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll(this,dbname, table, conds, async (data) => {
        let str ={};
        for(let i in data){
            const row=data[i];
            str[row.name]=row.description;
        }
        callback(str);
    });
}
const fetchingAll = async (_this, DbName, table, conds, callback) => {
    let hub = {
        Fields: [],
        DbName: DbName,
        TableName: table,
        Conditions: conds,
    }
    let endpoint = "/global-service/list";
    console.log("fetchingAll send > ", table, hub);
    await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
        console.log("fetchingAll response > ", table, dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                callback(data);
            } else {
                callback([]);
            }
        }

    });
}
const cleanInputUsername = (inData) => {
    if(typeof inData ==="undefined"){
        inData ="";
    }
    let data = inData.replace(" ", "");
    data = data.toLocaleLowerCase();
    return data;
}
const findMe=(myString,myKey)=>{
    // Sample string
    myString = myString.toLocaleLowerCase();
    myKey=myKey.toLocaleLowerCase();
    let str = myString;
    let index = str.indexOf(myKey);
    if(index !== -1){
        return true;
    } else{
        return false;
    }
}
const findKeyAttribute=(attr,data)=>{
    let my="";
    for(let i in data){
        const row = data[i];
        if(typeof row[attr]!=="undefined"){
            if(row[attr]){
                my=row.fieldname;
            }
        }
    }
    return my
}
const FindImages = (ref, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref && row.type === "profile") {

            return row.link;
        }
    }

    return "https://radiosol.cl/wp-content/uploads/2014/10/default-img-400x240.gif"
}
const FindImagesAny = (ref, Files,module) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref &&
            row.tableref === module &&
            row.type === "profile") {

            return row.link;
        }
    }
    return "https://res.cloudinary.com/gocar/image/upload//f_auto,q_auto/v1562835896/assets/blank_car.png"
}
const FindData = (rowIn, cat, Attributes) => {
    const keyList = [];
    for (let i in Attributes) {
        const row = Attributes[i];
        keyList.push({
            field: row.fieldname,
            label: row.fieldname.toLocaleUpperCase(),
            category: row.section
        });
    }
    let ls = [];
    for (let i in keyList) {
        const row = keyList[i];
        if (row.category === cat) {
            const data = rowIn;
            if (row.field.toLocaleLowerCase() !== "mileage") {
                let val = "";
                if (typeof data !== "undefined") {
                    if (typeof data[row.field] !== "undefined") {
                        val = data[row.field];
                        ls.push({val: val, field: row.field})
                    }
                }
            }
        }
    }
    return ls.map((item) => {
        return (
            <span><span style={{color: "red"}}>{item.field}</span>: <span
                style={{color: "gray"}}>{item.val} &nbsp;</span></span>
        )
    })
}
const sortData = (sortKey, data) => {
    const keyOne = sortKey;
    if(data ===null){
        return [];
    }
    data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
    return data;
}
const extraDataFromCol=(data,cols)=>{
    let out=[];
    let fields={};
    for(let i in cols){
        const row=cols[i];
        fields[row.id]=true;
    }
    for(let i in data){
        const row=data[i];
        let o={};
        for(let key in row){
            let val = row[key];
            /*let ans = Array.isArray(val);
            if(!ans && (typeof val !== "boolean")){
                val = val.replace("<nil>","");
            }*/

            if(typeof fields[key]!=="undefined"){
                o[key]=val
            }
        }
        out.push(o)
    }
    console.log("extraDataFromCol > ",out);
    return out;
}
const attachImageProfile = (files, dataIn) => {
    let ls = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        const link = FindImages(row.ref, files)
        row.link = link;
        ls.push(row)
    }
    return ls;
}
const buildTree=(data)=>{
    const  getNestedChildren=(arr, parent) =>{
        var out = []
        for(var i in arr) {
            if(arr[i].parent === parent) {
                var children = getNestedChildren(arr, arr[i].id)

                if(children.length) {
                    arr[i].children = children
                }
                out.push(arr[i])
            }
        }
        return out
    }
    function getNestedChildren2(arr, parent) {
        var children = [];
        for(let i =0; i < arr.length; ++i) {
            if(arr[i].parent === parent) {
                let grandChildren = getNestedChildren(arr,  arr[i].id)

                if(grandChildren.length) {
                    arr[i].children = grandChildren;
                }else{
                    arr[i].children=[]
                }
                children.push( arr[i]);
            }
        }
        return children;
    }
    //const data=this.sampleDataTree();
    const out =getNestedChildren2(data,"");
    console.log("buildTree &&&&&&&----> ",out)
    return out;

}
export {deleteEntryCRM_WithData,submitEntryCRM_WithData,buildTree,attachImageProfile,submitEntryCRM,FetchData,submitEntry,extraDataFromCol,onHandleInputFormEditText,onHandleInputFormEditRadio,onHandleInputFormEditCheck,
    ListEntry,RemoveEntry,RemoveFromGlobalEntry,SubmitEntry,GetEntityRecords,cleanInputUsername,findMe,sortData,findKeyAttribute,FindImages,FindImagesAny,FindData,getUnitList};
