import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderListPrivate,
    renderForm, removeEntry
} from "../generic/databases-function";
import {GetUserToken} from "../../api/services";
import eConfig from "../../config";

const options = ["yes", "no"];
const optionsCountry = ["usa", "sa", "congo", "DRC"];

export default class ComFieldLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Subscribers: [],
            SubscriberStructure: [],
            Campaigns: [],
            newEntry: {},
            showList: true,
            cqlTable: "Locations",
            cqlTableRef: "Locations",
            selectedCampaign: "",
            selectedChannel: "",
            listField: []

        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, "otp", "Subscription", "Subscribers");
        await fetchUtilData(this, "otp", "SubscriberStructure", "SubscriberStructure");
        await fetchUtilData(this, "otp", "Campaign", "Campaigns");

    }



    fetchApi = async (url, hub, method, callback) => {
        console.log("$$$$$----> ", url);
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((response) => {
                callback(response, null);
            }).catch((e) => {
            callback(null, e, null);
        });
    }

    submitEntry = async (e, table, addData, _this) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        let ref = "";
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
            }
            //let check if there is unique field
            if(typeof row.unique !=="undefined"){
                if (row.unique) {
                    ref = entry[row.field];
                }
            }
        }
        //todo let add our default variable
        const names = this.findAttributeVal("Name", entry) + " " +
            this.findAttributeVal("Surname", entry);
        const campaign = entry["selectedCampaign"];
        const channel = entry["selectedChannel"];
        delete entry["selectedCampaign"];
        delete entry["selectedChannel"];

        let hub = {
            Org: GetUserToken().OrgCode,
            Ref: ref,
            AppName: "cclms",
            Campaign: campaign,
            Names: names,
            Data: entry,
            DataAttributes: this.state.SubscriberStructure,
            Channel: channel,
            Profile:{},
        };

        let backend = eConfig.otpBackend;
        let endpoint = "/api/agent/subscribe/new";
        let url = eConfig.serverBackendOtp + "/" + backend + endpoint;

        await this.fetchApi(url, hub, "POST", async (dataIn, err) => {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showList = true;
                    _this.setState(tmp);
                    await _this.loadInitialData();
                }
            }

        });

    }
    findAttributeVal = (inAttribute, data,attributes) => {
        let lsStructure = this.state.SubscriberStructure;
        if(typeof attributes !=="undefined"){
            lsStructure = attributes;
        }
        let valOut = "";
        let outField = "";
        for (let i in lsStructure) {
            const row = lsStructure[i];
            const key = inAttribute.toLocaleLowerCase();
            const fieldStore = row[key];
            if (fieldStore) {
                outField = row.fieldname;
            }
        }
        if (typeof data[outField] !== "undefined") {
            valOut = data[outField];
        }
        return valOut;
    }

    makeCampaignBox = () => {
        let options = [];
        for (let i in this.state.Campaigns) {
            let row = this.state.Campaigns[i];
            options.push(row.name);
        }
        return {
            field: "selectedCampaign",
            label: "Campaign",
            options: options,
            type: "option",
            required: true,
            unique: false,
        };
    }
    makeChannelBox = () => {
        let options = ["email", "sms"];
        return {
            field: "selectedChannel",
            label: "OTP Channel",
            options: options,
            type: "option",
            required: true,
            unique: false,
        };
    }
    renderList = () => {
        const keyList = [
            {
                field: "names",
                label: "Name"
            },
            {
                field: "ref",
                label: "Identify"
            },
            {
                field: "channel",
                label: "Channel"
            }
        ];
        const deleteKey = ["org", "ref"];
        const deleteTable = this.state.cqlTable;
        const sortKey = "";
        return renderListPrivate(this, "Subscribers", keyList, deleteKey, deleteTable, "showList", removeEntry, sortKey)
    }
    renderForm = () => {
        let lsField = [];
        for (let i in this.state.SubscriberStructure) {
            const row = this.state.SubscriberStructure[i];
            lsField.push({
                ...row,
                field: row.fieldname,
                label: row.fieldname,
                options: [],
                type: row.datetype,
                required: row.required
            })
        }
        lsField.push(this.makeCampaignBox());
        lsField.push(this.makeChannelBox());

        console.log("renderForm &&&&---> ", lsField)

        return renderForm(lsField, "showList", this.state.cqlTable, this, this.submitEntry)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Subscribe Agent

                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Record</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }


}
