import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: "100%",
    },
});

function createData(name, calories, fat, carbs, protein) {
    return {name, calories, fat, carbs, protein};
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
];

export default ({data,includeHeader}) => {
    if (typeof data === "undefined") {
        return null
    }
    if (data.length === 0) {
        return null
    }
    const classes = useStyles();
    let cols = [];
    let firstRow = data[0];
    for (let i in firstRow) {
        cols.push(i);
    }
    let canPutHeader=false;
    if(typeof includeHeader !=="undefined"){
        canPutHeader = includeHeader
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
                <caption>{/*A barbone structure table example with a caption*/}</caption>
                <TableHead>
                    {canPutHeader?
                    <TableRow>
                        {cols.map((item, index) => {
                            if(index===0){
                                return (
                                    <TableCell key={index} align="left" style={{color:"blue"}}>
                                        {item.toLocaleUpperCase()}
                                    </TableCell>
                                )
                            }
                            return (
                                <TableCell key={index} align="right" style={{color:"blue"}}>
                                    {item.toLocaleUpperCase()}
                                </TableCell>
                            )
                        })}
                    </TableRow>:null}
                </TableHead>
                <TableBody>
                    {data.map((row, index) => {
                        return (
                            <TableRow key={"dd-"+index}>
                                {cols.map((item, index2) => {
                                    if (index2 === 0) {
                                        return (
                                            <TableCell component="th" scope="row">
                                                {row[item]}
                                            </TableCell>
                                        )
                                    }
                                    return (
                                        <TableCell align="right">{row[item]}</TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
