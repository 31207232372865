import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Icon from "@material-ui/core/Icon";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        maxWidth: "100%",
        minHeight: 250
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        backgroundColor: "#eeeeee",
        marginTop: 15,
        marginBottom: 15,
        display: 'flex',
        maxWidth: "100%",
        minHeight: 120

    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    }, wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    }
}));

export default ({data}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (

        <CardContent className={classes.content}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={5} alignItems="center"
                          style={{backgroundColor: "#eeeeee", minHeight: 100}}>

                    <div
                        className={classes.media + " " + classes.wrapIcon}
                        style={{
                            minWidth: "110%",
                            minHeight: "100%",
                            backgroundColor: data.color,
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Icon
                            style={{
                                color: "white",
                                fontSize: 58,
                            }}
                        >{data.icon/*"info_outline"*/}}</Icon>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} style={{backgroundColor: "white"}}>
                    <Typography component="h5" variant="h5" color="textSecondary">
                        {data.title}
                    </Typography>
                    <br/>
                    <Button
                        variant="outlined"
                        color="default"
                        style={{
                            maxHeight: 20,
                            fontSize: 12,
                            textSizeSmall: 11,
                            color:data.color

                        }}>
                        {data.btnText}
                    </Button>


                </GridItem>
            </GridContainer>
        </CardContent>


    );
}
