import React, {Component, Fragment} from "react";
import Wallpaper from "../../component/common/page/wallpaper";
import LoadingProcessing from "../../component/common/page/LoadingProcessing";
import FormEdit from "../../component/common/form-input-control/FormEdit";
import TableDataView from "../../component/common/tables/TableDataView";
import Connector from "../../reducers/connect/reducer-connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    attachImageProfile,
    submitEntryCRM
} from "../../api/fn";
import {GetUserToken, PostTo} from "../../api/fetching-data";
import GridItem from "../../component/common/dashboard/Grid/GridItem";
import GridContainer from "../../component/common/dashboard/Grid/GridContainer";
import ComboboxOptions from "../../component/common/form-input-control/ComboxOption";
import PopupMe from "../../component/Ticket/PopupMe";
import UploadDocument from "../../component/databases/UploadDocument";
import ChooseCategoryModal from "../../component/databases/popup-choose-file-category";
import {SERVER_RESERVATION, SERVER_CRM, CURRENT_DATABASE} from "../../configures/constants";
import PopupDownloadFile from "../../component/databases/popup-download-file";
import ModelSimple from "../../component/common/popup-modal/ModalView";


class DatabaseOfferingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            module: "",
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null,
            showUpload: false,
            selectedRecord: null,
            showChooseCategory: false,
            fileCategory: "",
            fileName: "",
            fileBase64:"",

            showFilterPopup: false,
            filterData: [],
            fitlerTitle: "--",
            filterField: "",
            showEditFormType:false,
            BaseFilter: [],
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    componentDidMount() {
        //todo

    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }
    onCancel = () => {
        this.setState({
            showEdit: false
        })
    }
    handleInput = async (e) => {
        console.log("onChangeModule :)-> ", e.target.name, " > ", e.target.value);
        let tmp = this.state;
        tmp[e.target.name] = e.target.value;
        this.setState(tmp);
    }

    saveProfileFile = async (info) => {
        if (typeof info === "undefined") {
            return
        }
        const user = GetUserToken();
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Module = info.module;
        hub.Ref = info.ref;
        hub.Category = info.category;
        hub.Type = info.category;
        hub.Name = info.category;// this.state.newEntry.name;
        hub.Username = user.Username;
        hub.Filename = info.filename;
        hub.Base64String = info.base64string;
        const _this = this;
        let endpoint = "/global/upload/new";
        this.setState({isLoading: true});
        await PostTo(SERVER_CRM, hub, endpoint, async (dataIn) => {
            console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    await _this.fetchData();
                    _this.setState({
                        isLoading: false,
                    });
                    _this.cancelChooseFileCategory();
                }
            }
        })
    }
    fetchData = async () => {
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        this.props.LoadTicketData();
    }
    buildEntry = (inEntry) => {
        const _this = this;
        let o = {};
        const findKeyObject = (myKey) => {
            const listFields = _this.getListField();
            for (let i in listFields) {
                const row = listFields[i];
                if (row.field === myKey) {
                    return row
                }
            }
            return {}
        }
        for (let i in inEntry) {
            const key = i;
            let val = inEntry[i];
            const row = findKeyObject(key);
            if (row.dataType === "float") {
                val = parseFloat(val)
            }
            if (row.dataType === "boolean") {
                val = val === "yes"
            }
            o[key] = val;
        }
        return o;
    }
    submitEntry = async (e) => {
        let newEntry = this.buildEntry(this.state.newEntry);
        let listField = this.getListField();
        console.log("submitEntry--->", this.state.newEntry, newEntry, listField);

        this.setState({newEntry: newEntry, listField: listField});

        //todo uncomment after
        let endpoint = "/reservation/insert/database";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}, {key: "module", val: this.state.module}];
        let reloadFunction = [this.fetchData];
        await submitEntryCRM(e, othersField, endpoint, reloadFunction, this);
    }
    submitEntryFilter = async (e) => {
        e.preventDefault();
        if (this.state.module === "") {
            return alert("!Can't submit this form please select target module before")
        }
        if (!window.confirm("Are you sure that you want to submit this attribute entry?")) {
            return
        }
        console.log("submitEntry > ", this.state.newEntry," > ",this.state.Databases);
        let record = null;
        for(let i in this.state.Databases){
            let row=this.state.Databases[i];
            if(row.ref ===this.state.selectedRecord.ref){
                delete row.params;
                delete row.link;

                record = row;
            }
        }

        let newEntry ={};// record.filters;
        /*if(newEntry===null){
            newEntry={}
        }*/
        for(let i in this.state.newEntry){
            const key=i;
            const val=this.state.newEntry[i];
            newEntry[key]=val;
        }
        record.filters = newEntry;


        let hub = {
            Org :record.org,
            TableRef :record.tableref,
            Ref :record.ref,
            Field :"filters",
            Data :newEntry,
            DbName :CURRENT_DATABASE,
            TableName :"GeneralTableData"
        };
        /* hub.Org = GetUserToken().OrgCode;
         hub.CqlTable = "GeneralTableData";
         hub.InData = [];
         hub.InData.push(record);*/


        const _this = this;
        let endpoint = "/generic/data/update";
        this.setState({isLoading: true});
        await PostTo(SERVER_RESERVATION, hub, endpoint, async (dataIn) => {
            console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    const res = dataIn.RESULT;
                    if (res.HasError) {
                        let errMsg = "";
                        for (let i in res.Errors) {
                            const rowError = res.Errors[i];
                            errMsg = errMsg + "\nError: " + rowError.Error + " -> Row #:" + rowError.RowNumber
                                + ", Field Name: " + rowError.KeyName + "; Field Value: " + rowError.KeyValue
                        }
                        alert("Fail to insert, Total error: " + res.Errors.length + errMsg)
                    } else {
                        alert("Thank you Record submitted!");
                        let tmp = _this.state;
                        tmp.newEntry = true;
                        tmp.showEditForm = false;
                        _this.setState(tmp);
                        await _this.fetchDatabases();
                        await _this.fetchAttributes();
                    }
                }
            }
            _this.setState({isLoading: false});
        });

    }
    onDelete = async (rowIn) => {
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }

        /**
         * todo delete late
         */
        let ls = [];
        for (let i in this.props.enquiry.Roles) {
            const row = this.props.enquiry.Roles[i];
            console.log("onDelete -> ", rowIn.name, "==", row.name, " > ", (row.name !== row.name));
            if (rowIn.name !== row.name) {
                ls.push(row)
            }
        }
        this.props.SaveRole(ls);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    closeDetail = () => {
        this.setState({
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null
        });
    }
    openDetail = (record) => {
        let row = record;
        let info = {};
        info.title = row.names;
        info.descriptionText = row.module;
        info.descObject = row.data;
        info.image = row.link;
        info.row = row;
        this.setState({
            showViewDetail: true,
            selectViewData: info,
            selectViewTitle: ""
        });
    }
    openUpload = (row) => {
        this.setState({
            showChooseCategory: true,
            showUpload: false,
            selectedRecord: row,
        })
    }
    handleCloseUpload = () => {
        this.setState({
            showUpload: false,
            selectedRecord: null,
        })
    }
    handleInputFilterParams = (key, val) => {
        this.setState({
            [key]: val
        });
    }

    getListFieldFilter=()=>{
        let ls = [];
        for (let i in this.state.BaseFilter) {
            const row = this.state.BaseFilter[i];
            let options =[];
            for(let a in row.items){
                options.push(a)
            }
            let my = {
                field: row.name,
                label: row.name,
                options: options,
                type: "combobox",
                required: false,
                dataType: "string"
            }
            ls.push(my);
        }
        return ls;
    }
    handleInputFileChooseCategory = async (key, e) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        console.log("handleInputChooseCategory > ", key, " > ", e.target.value)
    }
    closeChooseFileCategory = (record) => {
        if (this.state.fileCategory === "") {
            return alert("Sorry can't continue,please choose your file category!")
        }
        if (this.state.fileCategory !== "profile") {
            if (this.state.fileName === "") {
                return alert("Please provide the file name before continue!")
            }
        }
        this.setState({
            showChooseCategory: false,
            showUpload: true,
            selectedRecord: record,
            fileName: "",
            fileBase64:"",
        });
        //this.handleOpenUpload()

    }
    cancelChooseFileCategory = () => {
        this.setState({
            showChooseCategory: false,
            showUpload: false,
            selectedRecord: null,
            fileCategory: "",
            fileName: "",
            fileBase64:"",
        });
    }
    renderFilterForm=()=>{

        return(
            <FormEdit
                formType={"outline"}
                formTitle={"Form Filter " + this.state.module}
                data={this.getListFieldFilter()}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntryFilter}
                onCancel={this.onCancel}
            />
        )
    }
    renderList = () => {
        if (this.state.showEdit) {
            return null
        }

        let columns = [
            {id: 'link', label: 'Icon', minWidth: 170, dataType: "string"},
            {id: 'names', label: 'Name', minWidth: 170, dataType: "string"},
        ];

        let ls = [];
        const attrs = this.props.enquiry.Peoples;
        for (let i in attrs) {
            const row = attrs[i];
            if (row.module === this.state.module) {
                ls.push(row)
            }
        }

        ls = attachImageProfile(this.props.enquiry.Files, ls);

        let data = {columns: columns, data: ls};
        return (
            <TableDataView
                {...data}
                tableTitle={"Service & Product Database"}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
                openDetail={this.openDetail}
                openUpload={this.openUpload}
                closeDownload={this.closeDownload}
                openDownload={this.openDownload}
                handleInputFilterParams={this.handleInputFilterParams}
            />
        )
    }
    getListField = () => {
        let ls = [];
        for (let i in this.props.enquiry.AttributePeople) {

            const row = this.props.enquiry.AttributePeople[i];
            const org = GetUserToken().OrgCode;
            const module = this.state.module;
            if (row.org === org && row.module === module) {//let make sure that were are in the correct filter
                let type = "text";
                let options = [];
                if (row.datatype === "string") {
                    type = "text";
                }
                if (row.datatype === "boolean") {
                    type = "radio";
                    options = [true, false];
                }
                let my = {
                    field: row.fieldname,
                    label: row.fieldname,
                    options: options,
                    type: type,
                    required: row.required,
                    dataType: row.datatype
                }
                ls.push(my);
            }
        }
        return ls;
    }
    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }

        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Database Form edit [" + this.state.module + "]"}
                data={this.getListField()}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }
    renderModule = () => {
        const roles = this.props.enquiry.Roles;
        let ls = [{
            key: "service",
            val: "service".toLocaleUpperCase(),
        },{
            key: "product",
            val: "product".toLocaleUpperCase(),
        }];
        /*for (let i in roles) {
            const row = roles[i];
            ls.push({
                key: row.name,
                val: row.name.toLocaleUpperCase(),
            })
        }*/
        return (
            <ComboboxOptions
                name={"module"}
                label={"Module"}
                options={ls}
                handleInput={this.handleInput}
                onClickButton={this.onChangeModule}
                btnText={"Change Module"}
                includeBtn={false}
            />
        )
    }
    renderViewDetail = () => {
        if (!this.state.showViewDetail) {
            return null
        }
        let info = this.state.selectViewData;
        return (
            <PopupMe
                {...info}
                closeModal={this.closeDetail}
                visible={this.state.showViewDetail}
            />
        )
    }
    renderChooseFileCategory = () => {
        if (!this.state.showChooseCategory) {
            return null
        }
        let record = this.state.selectedRecord;

        return (
            <ChooseCategoryModal
                status={this.state.showChooseCategory}
                record={record}
                onChangeCategory={this.handleInputFileChooseCategory}
                handleClose={this.closeChooseFileCategory}
                onCancel={this.cancelChooseFileCategory}
            />
        )
    }
    openDownload=(row)=>{
        let ls=[];
        for(let i in this.props.enquiry.Files){
            const o =this.props.enquiry.Files[i];
            if(o.ref ===row.ref){
                ls.push(o);
            }
        }
        this.setState({
            showDownloadFile:true,
            downloadFileList:ls,
            selectedRecord: row,
        })

    }
    closeDownload=()=>{
        this.setState({
            showDownloadFile:false,
            downloadFileList:[],
        })
    }

    openFilterForm = (row) => {
        this.setState({
            selectedRecord: row,
            showEditForm: true,
            showEditFormType: true,
        })
    }
    closeFilterPopup = () => {
        this.setState({
            showFilterPopup: false,
            filterData: [],
            fitlerTitle: "--",
        })
    }

    render() {
        return (
            <Wallpaper {...this.props} >

                <GridContainer style={{marginTop: 50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        {this.renderModule()}
                    </GridItem>
                    {this.state.module !== "" ?
                        <Fragment>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderList()}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderEditForm()}
                            </GridItem>
                        </Fragment> :
                        <p style={{color: "red",marginLeft:10}}>Please select your target module(people)</p>
                    }

                </GridContainer>

                {this.renderViewDetail()}
                {this.renderChooseFileCategory()}


                <UploadDocument
                    open={this.state.showUpload}
                    handleClose={this.handleCloseUpload}
                    selectedRecord={this.state.selectedRecord}
                    uploadCategory={this.state.fileCategory}
                    handleSave={this.saveProfileFile}
                    module={this.state.module}
                    filesLimit={1}
                    submitFile={this.saveProfileFile}
                />
                <PopupDownloadFile
                    popTitle={"Download File"}
                    _this={this}
                    popStatus={this.state.showDownloadFile}
                    popFnClose={this.closeDownload}
                    vehicle={this.state.selectedRecord}
                    fileList={this.state.downloadFileList}
                    tableRef={"vehicle"}
                />
                <ModelSimple
                    data={this.state.filterData}
                    open={this.state.showFilterPopup}
                    entity={this.state.module}
                    title={this.state.fitlerTitle}
                    onClose={() => this.closeFilterPopup()}
                    includeHeader={true}
                />

                <LoadingProcessing open={this.state.isLoading}/>

            </Wallpaper>
        )
    }
}

export default Connector(DatabaseOfferingPage);

const options = ["yes", "no"];
const optionsDataType = ["string", "float", "boolean"];
const optionsBoolean = ["yes", "no"];
const listField = [
    {
        field: "FieldName",
        label: "Field Name",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "Section",
        label: "Display Section",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "Position",
        label: "Position",
        options: options,
        type: "float",
        required: true,
        dataType: "float"
    },
    {
        field: "DataType",
        label: "DataType",
        options: optionsDataType,
        type: "radio",
        required: true,
        dataType: "string"
    },
    {
        field: "Username",
        label: "Username",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Password",
        label: "Password",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Unique",
        label: "Unique",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Mandatory",
        label: "Mandatory",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Name",
        label: "Display 1",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Surname",
        label: "Display 2",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Required",
        label: "Required",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Phone",
        label: "Phone",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Email",
        label: "Email",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },

];
