import React, { Component } from 'react';
import Wallpaper from '../component/common/ef-wallper';
import MainComponent from '../component/fields/com-field-agent-description';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../component/common/redux-dispatch-to-props";



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConFieldAgentDescription extends Component {
    constructor(props){
        super(props)
    }
    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true} >
                <MainComponent {...this.props}/>

            </Wallpaper>
        );
    }
})
