import {SAVE_ATTIBUTE_PEOPLE, SAVE_ROLE, SAVE_UTIL,LOAD_TICKET_DATA,TICKET_DATA_RECEIVED} from "../constants";
import {GetUserToken} from "../../api/fetching-data";
import {FetchData} from "../../api/fn";
import {SaveRole} from "../action/action-enquiry";

const initial = {
    Roles: [
        /*{name: "support", description: "Support"},
        {name: "admin", description: "Admin"},
        {name: "customer", description: "Customer"},*/
    ],
    ChartRole: [],
    Actions: [],
    AttributePeople: [
        {
            "Module": "support",
            "FieldName": "First name",
            "Section": "names",
            "Position": "1",
            "DataType": "string",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "support",
            "FieldName": "Second Name",
            "Section": "names",
            "DataType": "string",
            "Position": "2",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "support",
            "FieldName": "Email",
            "Section": "contact",
            "Position": "3",
            "DataType": "string",
            "Username": "yes",
            "Password": "no",
            "Unique": "yes",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "no",
            "Email": "yes"
        },
        {
            "Module": "support",
            "FieldName": "Cellphone Number",
            "Section": "names",
            "Position": "4",
            "DataType": "string",
            "Username": "no",
            "Password": "yes",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "yes",
            "Email": "no"
        },

        {
            "Module": "customer",
            "FieldName": "First name",
            "Section": "names",
            "Position": "1",
            "DataType": "string",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "customer",
            "FieldName": "Second Name",
            "Section": "names",
            "DataType": "string",
            "Position": "2",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "customer",
            "FieldName": "Email",
            "Section": "contact",
            "Position": "3",
            "DataType": "string",
            "Username": "yes",
            "Password": "no",
            "Unique": "yes",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "no",
            "Email": "yes"
        },
        {
            "Module": "customer",
            "FieldName": "Cellphone Number",
            "Section": "names",
            "Position": "4",
            "DataType": "string",
            "Username": "no",
            "Password": "yes",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "yes",
            "Email": "no"
        },

        {
            "Module": "admin",
            "FieldName": "First name",
            "Section": "names",
            "Position": "1",
            "DataType": "string",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "admin",
            "FieldName": "Second Name",
            "Section": "names",
            "DataType": "string",
            "Position": "2",
            "Username": "no",
            "Password": "no",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "Module": "admin",
            "FieldName": "Email",
            "Section": "contact",
            "Position": "3",
            "DataType": "string",
            "Username": "yes",
            "Password": "no",
            "Unique": "yes",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "no",
            "Email": "yes"
        },
        {
            "Module": "admin",
            "FieldName": "Cellphone Number",
            "Section": "names",
            "Position": "4",
            "DataType": "string",
            "Username": "no",
            "Password": "yes",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "yes",
            "Email": "no"
        },
    ],
    AttributeService: [
        {
            "FieldName": "Company Name",
            "Position": "1",
            "DataType": "string",
            "Unique": "yes",
            "Mandatory": "yes",
            "Name": "yes",
            "Required": "yes",
            "Surname": "no",
            "Phone": "no",
            "Email": "no"
        },
        {
            "FieldName": "Contact Person",
            "Position": "2",
            "DataType": "string",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {

            "FieldName": "Email",
            "Position": "3",
            "DataType": "string",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Email": "yes",
            "Phone": "no"
        },
        {
            "module": "",
            "FieldName": "Contact Phone",
            "Position": "1",
            "DataType": "string",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "no",
            "Required": "yes",
            "Phone": "yes",
            "Email": "no"
        }
    ],
    AttributeProduct: [
        {

            "FieldName": "Name",
            "Position": "1",
            "DataType": "string",
            "Unique": "yes",
            "Mandatory": "yes",
            "Name": "yes",
            "Surname": "no",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {

            "FieldName": "Module",
            "Position": "2",
            "DataType": "string",
            "Unique": "no",
            "Mandatory": "yes",
            "Name": "no",
            "Surname": "yes",
            "Required": "yes",
            "Phone": "no",
            "Email": "no"
        },
        {
            "FieldName": "Description",
            "Position": "3",
            "DataType": "string",
            "Mandatory": "no",
            "Unique": "no",
            "Name": "no",
            "Surname": "no",
            "Required": "no",
            "Phone": "no",
            "Email": "no"
        }
    ],
    Peoples: [],
    /*Step*/
    Categories: [],
    Priorities: [],
    Stages: [],
    Status: [],
    /* Services */
    Services: [],
    ServiceModel: [],
    ServiceChart: [],
    ServiceDetail: [],
    ServiceCost: [],
    ServiceCategory: [],
    ServiceFAQ: [],
    ServiceSolutions: [{
        Service: "Easicare",
        Cram: {
            HasChild: true,
            Children: [
                {
                    name: "Faculty",
                    HasChild: true,
                    Children: [
                        {
                            name: "Computer System",
                            HasChild: true,
                            Children: [
                                {
                                    name: "IT",
                                    HasChild: false,
                                    Children: [],
                                    Description: "You can move the data from the existing server to new server. Please follow the steps mentioned below: 1. Stop ManageEngine ServiceDesk Plus service2. Kindly Upgrade the SD+ if needed. Take the link below to check if you are in the latest ",
                                    Views: 8,
                                    CreatedOn: "Jan 14, 1970 07:16",
                                    CreateBy: "admistrator"
                                },
                                {
                                    name: "Networking",
                                    HasChild: false,
                                    Children: [],
                                    Description: "Modify the index.html page as per your needs and the save the file under the same location. 3. Keep the FORM Submit and Action options as they are.4. Edit the Web.xml file present under",
                                    Views: 8,
                                    CreatedOn: "May 22, 2007 05:19",
                                    CreateBy: "support"
                                },
                            ],

                        }
                    ],
                }
            ]
        },

    }],
    Requests: [
        {
            id: 135,
            subject: "Test 01",
            author: "Djane Nongo",
            assignto: "Unssigned",
            dueby: "---",
            status: "Open",
            created: "Dec 2,2019 03:07",
            service: "Pensure",
            site: "---",
            behalf: "---"
        },
        {
            id: 134,
            subject: "Please provide the service for our new client",
            author: "Kuminga",
            assignto: "Cathrine",
            dueby: "Nov 17,2016 11:12",
            status: "Open",
            created: "Dec 16,2019 03:07",
            service: "Pensure",
            site: "JHB",
            behalf: "---"
        },
        {
            id: 18,
            subject: "Unable to fetch mails",
            author: "Guest",
            assignto: "Unssigned",
            dueby: "---",
            status: "Open",
            created: "Dec 2,2019 03:07",
            service: "Easicare",
            site: "---",
            behalf: "---"
        },
    ],
    Files:[],
    Filters:[],
    Utils:[],
};


const enquiry = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case TICKET_DATA_RECEIVED:
            console.log("REQ-2: TICKET_DATA_RECEIVED :",action);
            const data=action.data;
            if(typeof data ==="undefined"){
                return Object.assign({}, newState);
            }
            newState.Roles=data.Roles;
            newState.AttributePeople =data.Attributes;
            newState.Peoples =data.Databases;
            newState.Files = data.Files;
            newState.Filters = data.Filters;
            newState.Utils =data.Util;
            return Object.assign({}, newState);
        case SAVE_ROLE:
            newState.Roles = action.payload;
            return Object.assign({}, newState);
        case SAVE_ATTIBUTE_PEOPLE:
            newState.AttributePeople = action.payload;
            return Object.assign({}, newState);
        case SAVE_UTIL:
            newState.Roles = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}





export default enquiry;




