import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import React, {Component} from "react";
import Wallpaper from "../common/ef-wallper";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// eslint-disable-next-line no-unused-vars
import {EditorState, ContentState} from 'draft-js';
// eslint-disable-next-line no-unused-vars
import htmlToDraft from 'html-to-draftjs';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';


import 'suneditor/dist/css/suneditor.min.css'
import suneditor from 'suneditor'
import plugins from 'suneditor/src/plugins';
//https://github.com/JiHong88/SunEditor
// https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs

// all plugins
const initEditor = suneditor.init({
    plugins: plugins,
    height: 200,
    buttonList: [
        ['undo', 'redo',
            'font', 'fontSize', 'formatBlock',
            'paragraphStyle',
            'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
            'fontColor', 'hiliteColor', 'textStyle',
            'removeFormat',
            'outdent', 'indent',
            'align', 'horizontalRule', 'list', 'lineHeight',
            'table', 'link', 'image', 'video',
            'fullScreen', 'showBlocks', 'codeView',
            'preview', 'print', 'save', 'template']
    ]
});

/*
['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        '/', // Line break
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video'],
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        ['save', 'template']
 */


let editorMe = null;

class MyPdf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            newTemplateBody: "",
        };
    }

    componentDidMount() {

        editorMe =   initEditor.create('sample', {
            // The value of the option argument put in the "create" function call takes precedence
        });

    }

    handleModelChange = (e) => {
        console.log("handleModelChange   > ", e);
        let tmp = this.state;
        tmp.newTemplateBody = e;
        this.setState(tmp);
    }

    getContent=()=>{
        let myContent = editorMe.getContents({onlyContents: Boolean});
        console.log("(:)---> getContent > ",myContent)
    }
    setContents=()=>{
        editorMe.setContents(htmlString);
    }
    disabledContent=()=>{
        // Disable the suneditor
        editorMe.disabled();
    }
    saveContents=()=>{
        editorMe.save();
    }
    setContents=()=>{
        editorMe.setContents(htmlString);
    }
    destroyContents=()=>{
        editorMe.destroy();
    }



    _exportPdf = () => {

        this.setContents();
        this.getContent();
        this.disabledContent();
        this.saveContents();
    }

    render() {

        /*const editorImageSample = suneditor.create('lineBreak', {
            showPathLabel: false,
            charCounter: true,
            maxCharCount: 720,
            width: 'auto',
            maxWidth: '700px',
            height: 'auto',
            minHeight: '100px',
            maxHeight: '250px',
            buttonList: [
                ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                '/' // Line break
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
            ],
            callBackSave: function (contents) {
                console.log(contents);
            }
        });
   */


        return (
            <div>

                <div id="capture">
                    <p>Hello in my life</p>
                    <span>How can hellp you</span>
                    <button onClick={() => this._exportPdf()}>Create</button>
                </div>
                <hr/>
                <textarea id="sample" style={{minWidth: "100%"}}>Hi</textarea>


                <editor/>
                <hr/>


            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConClickToCall extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <MyPdf {...this.props}/>

            </Wallpaper>
        );
    }
})

const htmlString = `<p lang="en-US">
\t<br>
</p>

<p align="center" lang="en-US">
\t<br>
</p>

<p align="center" lang="en-US"><strong>Service Level Agreement (SLA)</strong></p>

<p align="center" lang="en-US"><strong>for&nbsp;</strong><em><strong>Customer</strong></em></p>

<p align="center" lang="en-US"><strong>by</strong></p>

<p align="center" lang="en-US">EASIPATH SOLUTIONS</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Effective Date:</strong></p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<table cellpadding="7" cellspacing="0" width="620">
\t<tbody>
\t\t<tr valign="top">
\t\t\t<td width="232">

\t\t\t\t<p lang="en-US"><strong>Document Owner:</strong></p>
\t\t\t</td>
\t\t\t<td width="358">

\t\t\t\t<p lang="en-US">EASIPATH &nbsp;SOLUTIONS</p>
\t\t\t</td>
\t\t</tr>
\t</tbody>
</table>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Version</strong></p>

<p lang="en-US">
\t<br>
</p>

<table cellpadding="7" cellspacing="0" width="620">
\t<thead>
\t\t<tr valign="top">
\t\t\t<th width="64">

\t\t\t\t<p lang="en-US">Version</p>
\t\t\t</th>
\t\t\t<th width="88">

\t\t\t\t<p lang="en-US">Date</p>
\t\t\t</th>
\t\t\t<th width="280">

\t\t\t\t<p lang="en-US">Description</p>
\t\t\t</th>
\t\t\t<th width="130">

\t\t\t\t<p lang="en-US">Author</p>
\t\t\t</th>
\t\t</tr>
\t</thead>
\t<tbody>
\t\t<tr valign="top">
\t\t\t<td width="64">

\t\t\t\t<p lang="en-US">1.0</p>
\t\t\t</td>
\t\t\t<td width="88">

\t\t\t\t<p lang="en-US">0</p>
\t\t\t</td>
\t\t\t<td width="280">

\t\t\t\t<p lang="en-US">Service Level Agreement</p>
\t\t\t</td>
\t\t\t<td width="130">

\t\t\t\t<p lang="en-US">Willy Ciama</p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="64">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="88">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="280">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="130">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="64">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="88">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="280">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="130">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t</tr>
\t</tbody>
</table>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Approval</strong></p>

<p lang="en-US"><em>(By signing below, all Approvers agree to all terms and conditions outlined in this Agreement.)</em></p>

<p lang="en-US">
\t<br>
</p>

<table cellpadding="7" cellspacing="0" width="621">
\t<thead>
\t\t<tr valign="top">
\t\t\t<th height="8" width="112">

\t\t\t\t<p lang="en-US">Approvers</p>
\t\t\t</th>
\t\t\t<th width="106">

\t\t\t\t<p lang="en-US">Role</p>
\t\t\t</th>
\t\t\t<th width="147">

\t\t\t\t<p lang="en-US">Signed</p>
\t\t\t</th>
\t\t\t<th width="198">

\t\t\t\t<p lang="en-US">Approval Date</p>
\t\t\t</th>
\t\t</tr>
\t</thead>
\t<tbody>
\t\t<tr valign="top">
\t\t\t<td height="2" width="112">

\t\t\t\t<p lang="en-US">EasiPath Solutions</p>
\t\t\t</td>
\t\t\t<td width="106">

\t\t\t\t<p lang="en-US">Service Provider</p>
\t\t\t</td>
\t\t\t<td width="147">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="198">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td height="1" width="112">

\t\t\t\t<p lang="en-US">....</p>
\t\t\t</td>
\t\t\t<td width="106">

\t\t\t\t<p lang="en-US">Customer</p>
\t\t\t</td>
\t\t\t<td width="147">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t\t<td width="198">

\t\t\t\t<p lang="en-US">
\t\t\t\t\t<br>&nbsp;</p>
\t\t\t</td>
\t\t</tr>
\t</tbody>
</table>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Table of Contents</strong></p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><a href="#__RefHeading___Toc138132808"><span lang="en-ZA">1.</span><span lang="en-ZA">Agreement Overview3</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132809"><span lang="en-ZA">2.</span><span lang="en-ZA">Goals &amp; Objectives3</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132810"><span lang="en-ZA">3.</span><span lang="en-ZA">Stakeholders3</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132811"><span lang="en-ZA">4.</span><span lang="en-ZA">Periodic Review4</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132812"><span lang="en-ZA">5.</span><span lang="en-ZA">Service Agreement4</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132813"><span lang="en-ZA">5.1.</span><span lang="en-ZA">Service Scope4</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132814"><span lang="en-ZA">5.2.</span><span lang="en-ZA">Customer Requirements5</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132815"><span lang="en-ZA">5.3.</span><span lang="en-ZA">Service Provider Requirements5</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132816"><span lang="en-ZA">5.4.</span><span lang="en-ZA">Service Assumptions5</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132817"><span lang="en-ZA">6.</span><span lang="en-ZA">Service Management6</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132818"><span lang="en-ZA">6.1.</span><span lang="en-ZA">Service Availability6</span></a></p>

<p lang="en-US"><a href="#__RefHeading___Toc138132819"><span lang="en-ZA">6.2.</span><span lang="en-ZA">Service Requests6</span></a></p>

<h1 lang="en-GB">
\t<br>
\t<br>
</h1>

<h1 lang="en-US">1Agreement Overview</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">This Agreement represents aService Level Agreement (&ldquo;SLA&rdquo; or &ldquo;Agreement&rdquo;) between<em>Easipath Solutions</em>. and .......for the provisioning of VoIP telephone inbound line and &nbsp;VoIP system support via our cloud based system.</p>

<p align="justify" lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">This Agreement remains valid until superseded by a revised agreement mutually endorsed by the stakeholders.</p>

<p align="justify" lang="en-US">This Agreement outlines the parameters of all Products and services covered as they are mutually understood by the primary stakeholders. This Agreement does not supersede current processes and procedures unless explicitly stated herein.</p>

<h1 lang="en-US">2Goals &amp; Objectives</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The<strong>purpose</strong>of this Agreement is to ensure that the proper elements and commitments are in place to provide consistent service support and delivery to the Customer(s) by the Service Provider(s).&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The<strong>goal</strong>of this Agreement is to obtain mutual agreement for the service provision between the Service Provider(s) and Customer(s).</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">The<strong>objectives</strong>of this Agreement are to:</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h1 lang="en-US">3Stakeholders</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The following Service Provider(s) and Customer(s) will be used as the basis of the Agreement and represent the <strong>primary</strong><strong>stakeholders</strong>associated with this SLA:</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Service Provider(s):</strong>Easipath Solutions. (&ldquo;Provider&rdquo;)</p>

<p lang="en-US"><strong>Customer(s):</strong>....(&ldquo;Customer&rdquo;)</p>

<h1 lang="en-US">
\t<br>
\t<br>
</h1>

<h1 lang="en-US">4Periodic Review</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">This Agreement is valid from the <strong>Effective Date</strong>outlined herein and is valid until further notice. This Agreement should be reviewed at a minimum once per fiscal year; however, in lieu of a review during any period specified, the current Agreement will remain in effect.&nbsp;</p>

<p align="justify" lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The <strong>Business Relationship Manager</strong>(&ldquo;Document Owner&rdquo;) is responsible for facilitating regular reviews of this document. Contents of this document may be amended as required, provided mutual agreement is obtained from the primary stakeholders and communicated to all affected parties. The Document Owner will incorporate all subsequent revisions and obtain mutual agreements / approvals as required.&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US"><strong>Business Relationship Manager:</strong>Willy Ciama</p>

<p lang="en-US"><strong>Review Period:&nbsp;</strong>Yearly (12 months)</p>

<p lang="en-US"><strong>Previous Review Date:</strong> N/A</p>

<p lang="en-US"><strong>Next Review Date</strong><strong>:</strong>...</p>

<p lang="en-US">
\t<br>
</p>

<h1 lang="en-US">5Service Agreement</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The following detailed service parameters are the responsibility of the Service Provider in the ongoing support of this Agreement.&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">5.1 Service Scope</h2>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">The following Services are covered by this Agreement;&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">5.2VoIP Telephone line&nbsp;</h2>

<h2 lang="en-US">5.3Support services via our cloud based system</h2>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">5.4 Customer Requirements</h2>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US"><strong>Customer</strong>responsibilities and/or requirements in support of this Agreement include:&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">5.5 &nbsp;Service Provider Requirements</h2>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US"><strong>Service Provider</strong>responsibilities and/or requirements in support of this Agreement include:&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">5.6 Service Assumptions</h2>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">Assumptions related to in-scope services and/or components include:</p>

<p lang="en-US">
\t<br>
</p>

<h1 lang="en-US">6Service Management</h1>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">Effective support of in-scope services is a result of maintaining consistent service levels. The following sections provide relevant details on service availability, monitoring of in-scope services and related components.</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">6.1 Service Availability</h2>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">Coverage parameters specific to the service(s) covered in this Agreement are as follows:</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<h2 lang="en-US">6.2&nbsp;Service Requests</h2>

<p lang="en-US">
\t<br>
</p>

<p align="justify" lang="en-US">In support of services outlined in this Agreement, the Service Provider will respond to service related incidents and/or requests submitted by the Customer within the following time frames in case of unforeseen situation:&nbsp;</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">
\t<br>
</p>

<p lang="en-US">Remote assistance will be provided in-line with the above timescales dependent on the priority of the issue request.</p>

<p>
\t<br>
</p>

<p>
\t<br>
</p>
`;
